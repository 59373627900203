import React from 'react';
import { ReactComponent as Done } from 'src/astromix/assets/images/done.svg';
import { List, Item, Span } from './styled';
import './ProcessingScreenLoader.scss';

export interface LoaderListProps {
  list: string[];
}

const LoaderList = ({ list }: LoaderListProps) => (
  <List>
    {list.map((item, idx) => (
      <Item key={item}>
        <Done className={`img-${idx}`} title="done" />
        <Span>{item}</Span>
      </Item>
    ))}
  </List>
);

export default LoaderList;
