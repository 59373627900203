import React from 'react';
import Modal from 'src/common/components/Modal';
import { ReactComponent as CrossIcon } from 'src/astromix/assets/images/cross.svg';
import styles from './PaymentFailedDialog.module.scss';
import PaymentFailedDialogContent from './PaymentFailedDialogContent';

export type PaymentFailedDialogProps = {
  isOpen?: boolean;
  onClose?: () => void;
  onTryAgain?: () => void;
}

const PaymentFailedDialog = ({ isOpen = false, onClose = () => {}, onTryAgain }: PaymentFailedDialogProps) => (
  <Modal open={isOpen} onClose={onClose}>
    <div className={styles.wrapper}>
      <div role="button" onClick={onClose} className={styles.controlWrapper}>
        <CrossIcon className={styles.control} />
      </div>
      <PaymentFailedDialogContent onTryAgain={onTryAgain} />
    </div>
  </Modal>
);

export default PaymentFailedDialog;
