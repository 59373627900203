import ButtonComponent from 'src/astromix/components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 708px;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.secondary};
  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 18px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 10px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 20px;
  }

  &:before {
    content: '';
    display: block;
    padding-top: 48.841%;

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: 45.34%;
    }
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Button = styled(ButtonComponent)`
  margin: auto auto 30px;
`;
