import { useEffect } from 'react';
import { useLastLocation } from 'react-router-last-location';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { BASE_ROUTES } from 'src/astromix/constants';

export function usePaymentTerms() {
  const { analyticsService } = useAstromixServices();

  const lastLocation = useLastLocation();

  useEffect(() => {
    analyticsService.policyPageOpened(BASE_ROUTES.PAYMENT_TERMS, lastLocation?.pathname || '');
  }, [analyticsService]);
}
