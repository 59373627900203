import React from 'react';
import ImagedPageSection, {
  DPIScales,
  ImagedPageSectionHolder,
} from 'src/common/components/ImagedPageSection';
import styled from 'styled-components';

import webpXl from 'src/astromix/assets/images/extended-landing/feature/feature-xl.webp';
import webpXl2x
  from 'src/astromix/assets/images/extended-landing/feature/feature-xl-2x.webp';
import pngXl from 'src/astromix/assets/images/extended-landing/feature/feature-xl.png';
import pngXl2x
  from 'src/astromix/assets/images/extended-landing/feature/feature-xl-2x.png';
import webpMd from 'src/astromix/assets/images/extended-landing/feature/feature-md.webp';
import webpMd2x
  from 'src/astromix/assets/images/extended-landing/feature/feature-md-2x.webp';
import pngMd from 'src/astromix/assets/images/extended-landing/feature/feature-md.png';
import pngMd2x
  from 'src/astromix/assets/images/extended-landing/feature/feature-md-2x.png';
import webpXs from 'src/astromix/assets/images/extended-landing/feature/feature-xs.webp';
import webpXs2x
  from 'src/astromix/assets/images/extended-landing/feature/feature-xs-2x.webp';
import pngXs from 'src/astromix/assets/images/extended-landing/feature/feature-xs.png';
import pngXs2x
  from 'src/astromix/assets/images/extended-landing/feature/feature-xs-2x.png';
import { Button } from './styled';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

const sectionHeight = 1707;
const sectionWidth = 360;
const sectionTabletHeight = 2025;
const sectionTabletWidth = 768;
const sectionDesktopHeight = 2025;
const sectionDesktopWidth = 1440;
const buttonTopOffset = 1638;
const buttonTopOffsetTablet = 1945;
const buttonTopOffsetDesktop = 1945;

const aspectRatio = buttonTopOffset / sectionHeight;
const tabletAspectRatio = buttonTopOffsetTablet / sectionTabletHeight;
const desktopAspectRatio = buttonTopOffsetDesktop / sectionDesktopHeight;

const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionTabletHeight} / ${sectionTabletWidth} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionDesktopHeight} / ${sectionDesktopWidth} * 100%);
    }
  }
`;

export type SectionFeatureProps = {
  onGetReport: () => void;
}

const SectionFeature = ({ onGetReport }: SectionFeatureProps) => (
  <ImagedSection
    originalUrl={sectionData.originalUrl}
    sources={sectionData.sources}
  >
    <Button
      aspectRatio={aspectRatio}
      tabletAspectRatio={tabletAspectRatio}
      desktopAspectRatio={desktopAspectRatio}
      onClick={onGetReport}
    >
      Get full report
    </Button>
  </ImagedSection>
);

export default SectionFeature;
