import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';
import { ReactComponent as Gift } from 'src/astromix/assets/images/gift.svg';
import Button from 'src/common/components/Button';
import PriceBlock, {
  PriceHeader, PriceMain, StyledPrice, HeaderDiscountTextWrapper, HeaderDiscount, HeaderDiscountText,
} from 'src/astromix/components/PriceBlock';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

export const StyledGift = styled(Gift)`
  width: 151px;
  height: 151px;
  padding-bottom: 20px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: initial;
    height: initial;
    padding-top: 10px;
    padding-bottom: 30px;
  }
`;

export const GiftSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledPriceBlock = styled(PriceBlock)`
  border: none;
  width: 160px;
  padding: 4px;
  background: ${(props) => props.theme.gradients.primary};
  margin-left: 18px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 257px;
    margin-left: 50px;
  }

  ${PriceHeader} {
    border: none;
    margin-top: -4px;
    margin-left: -4px;
    margin-right: -4px;
    color: ${(props) => props.theme.colors.secondary};
    background: ${(props) => props.theme.gradients.primary};
    padding-top: 13px;
    padding-bottom: 7px;

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: 18px;
      padding-bottom: 18px;
    }

    ${HeaderDiscountTextWrapper} {
      /* padding: 1px; */
    }

    ${HeaderDiscount} {
      padding: 1px;
    }

    ${HeaderDiscountText} {
      background: ${(props) => props.theme.gradients.primary};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  ${PriceMain} {
    background: #fff;
  }

  ${StyledPrice} {
    font-weight: bold;
    font-size: 48px;
    line-height: 65px;

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: 64px;
      line-height: 87px;
    }
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-bottom: 30px;
  }
`;

export const ShortDescription = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.inactive};
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 18px;
    line-height: 140%;
    color: ${(props) => props.theme.colors.active};
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ReportDescription = styled.p`
  margin: 30px auto;
  max-width: 526px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.active};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 10px;
    line-height: 140%;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Link = styled(RLink)`
  text-decoration: underline;
  font-weight: bold;
`;
