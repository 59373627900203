import styled from 'styled-components';
import PriceBlock, {
  PriceHeader,
  PriceMain,
  StyledPrice,
  OldPrice,
  HeaderDiscount,
  HeaderDiscountTextWrapper,
  HeaderDiscountText,
} from 'src/astromix/components/PriceBlock';
import Title from 'src/astromix/components/Title';
import Button from 'src/common/components/Button';
import ImagedPageInscription from 'src/common/components/ImagedPageSectionInscription';
import { ReactComponent as PaymentCards } from 'src/astromix/assets/images/paymentCards.svg';

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 50px;
  }
`;

export const Skip = styled.p`
  cursor: pointer;
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: underline;
  width: 310px;
  font-size: 14px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
  }
`;

export const StyledButton = styled(Button)`
  margin: 50px auto 23px;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledPriceBlock = styled(PriceBlock)`
  border: none;
  width: 160px;
  padding: 4px;
  background: linear-gradient(#3e1d76, #56ccf2);
  border-top: none;
  width: 100%;
  max-width: 330px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    max-width: 453px;
  }

  ${PriceHeader}{
    padding: 0;
    border-bottom: none;
  }

  ${PriceMain} {
    background: #161648;
    border-radius: 16px;
  }

  ${StyledPrice} {
    font-weight: bold;
    font-size: 48px;
    line-height: 65px;
    color: ${(props) => props.theme.colors.secondary};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: 64px;
      line-height: 87px;
    }
  }

  ${OldPrice} {
    text-decoration: line-through;
    font-size: 36px;
    line-height: 49px;
    margin-right: 60px;
  }

  ${HeaderDiscount}{
    right: 47px;

    @media ${(props) => props.theme.breakpoints.tablet} {
      right: 102px;
    }
  }

  ${HeaderDiscountText}{
    font-size: 16px;
    background: #E4832D;
    color: ${(props) => props.theme.colors.secondary};
  }

  ${HeaderDiscountTextWrapper}{
    background: #E4832D;
  }
`;

export const StyledTitle = styled(Title)`
  margin-top: 30px;
  margin-bottom: 40px;
  background: linear-gradient(160deg, #3e1d76, #56ccf2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SectionInscription = styled(ImagedPageInscription)`
  position: relative;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;

  & > span {
    color: ${(props) => props.theme.colors.secondary};
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 60px;
    margin-bottom: 60px;
  }
`;

export const StyledPaymentCards = styled(PaymentCards)`
  width: 100%;
  max-width: 708px;
`;
