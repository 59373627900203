import styled from 'styled-components';
import { ColorVariants } from 'src/common/theme/types';

export const TitleText = styled.h2<{ variant?: ColorVariants }>`
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  text-align: center;
  color: ${(props) => (props.variant === 'secondary' && props.theme.colors.secondary)
    || props.theme.colors.primary};
  @media ${(props) => props.theme.breakpoints.tablet} {
    max-width: 708px;
    font-size: 32px;
    line-height: 44px;
  }
`;
