import React from 'react';
import { Provider } from 'react-redux';
import { AstromixServicesContext } from 'src/astromix/services/context';
import { useApp } from 'src/astromix/useApp';
import { ServicesContext } from 'src/common/services/context';
import { Container, Content, Wrapper } from './styled';

interface MainProps {
  children: React.ReactNode;
}

const MainContainer: React.FC<MainProps> = ({ children }) => {
  const {
    isAppLoaded, services, store, astromixServices,
  } = useApp();

  if (!isAppLoaded || !services || !store || !astromixServices) {
    return <>Loading</>;
  }

  return (
    <ServicesContext.Provider value={services}>
      <AstromixServicesContext.Provider value={astromixServices}>
        <Provider store={store}>
          <Container>
            <Content>
              <Wrapper>{children}</Wrapper>
            </Content>
          </Container>
        </Provider>
      </AstromixServicesContext.Provider>
    </ServicesContext.Provider>
  );
};

export default MainContainer;
