import styled from 'styled-components';
import { ReactComponent as Burger } from 'src/astromix/assets/images/burger.svg';
import { ReactComponent as Close } from 'src/astromix/assets/images/cross.svg';

import { NavLink as Link } from 'react-router-dom';
import { ColorVariants } from 'src/common/theme/types';

export const BurgerIcon = styled(Burger)<{ variant?: ColorVariants }>`
  position: absolute;
  cursor: pointer;
  right: 0;
  color: ${(props) => (props.variant === 'secondary' && props.theme.colors.secondary)
    || props.theme.colors.primary};
`;

export const CloseIcon = styled(Close)`
  margin-bottom: 20px;
  cursor: pointer;
`;

export const Container = styled.div`
  width: 312px;
  padding: 20px;
`;

export const NavLink = styled(Link)`
  margin-bottom: 10px;
  display: block;
`;
