import { v4 as uuidv4 } from 'uuid';
import { UserDetectionStrategy } from './UserDetectionStrategy';

export class LocalStorageIdentifier implements UserDetectionStrategy {
  private identifier: string = '';

  constructor() {
    this.getIndentifierFromLocalStorage();
  }

  getIdentifier() {
    if (!this.identifier) this.createIdentifier();
    return this.identifier;
  }

  private createIdentifier() {
    this.identifier = uuidv4();
    localStorage.setItem('identifier', this.identifier);
  }

  private getIndentifierFromLocalStorage() {
    try {
      this.identifier = localStorage.getItem('identifier') || '';
    } catch (err) {
      console.error(err);
    } finally {
      if (!this.identifier) this.createIdentifier();
    }
  }
}
