import React, { useCallback, useState } from 'react';
import Header from 'src/astromix/components/Header';
import PaymentDialog from 'src/common/components/PaymentDialog';
import { LoadingStatuses, PaymentOrderDescription, PaymentType } from 'src/common/types';
import SectionMain from './SectionMain';
import SectionFeedback from './SectionFeedback';
import { useAdditionalDiscount } from './useAdditionalDiscount';

const AdditionalDiscount = () => {
  const {
    price,
    cardPaymentProcessing,
    paypalPaymentProcessing,
    paymentRetried,
    paymentFailed,
    loadingStatus,
    getPaymentUrls,
    paymentSucceed,
    getReport,
  } = useAdditionalDiscount();

  const [isPaymentDialogVisible, setIsPaymentDialogVisible] = useState(false);

  const onGetReport = () => {
    getReport();
    setIsPaymentDialogVisible(true);
  };

  const onPaymentClose = () => setIsPaymentDialogVisible(false);

  const onPaymentSuccess = useCallback(
    (orderData: PaymentOrderDescription, paymentType: PaymentType) => {
      setIsPaymentDialogVisible(false);
      paymentSucceed(orderData, paymentType);
    },
    [paymentSucceed],
  );

  const isLoading = loadingStatus === LoadingStatuses.PENDING;

  return (
    <>
      <Header buttonBack={false} />
      <SectionMain onGetReport={onGetReport} price={price} isLoading={isLoading} />
      <SectionFeedback onGetReport={onGetReport} />

      <PaymentDialog
        onPaymentRetried={paymentRetried}
        onPaymentFailed={paymentFailed}
        onPaypalPaymentProcessing={paypalPaymentProcessing}
        onCardPaymentProcessing={cardPaymentProcessing}
        isOpen={isPaymentDialogVisible}
        onClose={onPaymentClose}
        price={price}
        getPaymentUrls={getPaymentUrls}
        onPaymentSuccess={onPaymentSuccess}
      />
    </>
  );
};

export default AdditionalDiscount;
