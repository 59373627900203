import styled from 'styled-components';
import MoneyBack from 'src/astromix/components/MoneyBack';
import Button from 'src/common/components/Button';
import ImagedPageInscription from 'src/common/components/ImagedPageSectionInscription';

export const StyledButton = styled(Button)`
  margin: 0 auto;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const MoneyBackGuarantee = styled(MoneyBack)`
  margin: 50px auto;
`;

export const SectionInscription = styled(ImagedPageInscription)`
  position: relative;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 50px;
    margin-bottom: 60px;
  }
`;

export const Address = styled.span`
  color: ${(props) => props.theme.colors.inactive};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  font-size: 10px;
  line-height: 140%;
  width: 212px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 12px;
    width: auto;
  }
`;
