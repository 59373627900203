import React from 'react';
import { PriceTypes } from 'src/astromix/components/Price';
import { ReactComponent as Guarantee } from 'src/astromix/assets/images/guarantee.svg';

import {
  InfoWrapper,
  Skip,
  StyledButton,
  StyledPriceBlock,
  StyledTitle,
  SectionInscription,
  StyledPaymentCards,
} from './styled';

export type SectionInfoProps = {
  onGetReport: () => void;
  onHandleSkipClick: () => void;
  isLoading?: boolean;
  price: number;
}

const SectionInfo = ({
  onGetReport,
  onHandleSkipClick,
  isLoading,
  price,
}: SectionInfoProps) => (
  <InfoWrapper>
    <StyledTitle>ONE TIME OFFER</StyledTitle>
    <StyledPriceBlock
      discount="-85% OFF"
      price={`$${price}`}
      oldPrice="$40"
      oldPriceType={PriceTypes.DISCOUNT}
    />
    <StyledButton
      loading={isLoading}
      onClick={onGetReport}
      variant="success"
    >
      { isLoading ? 'Processing' : 'Get my tarot guide' }
    </StyledButton>
    <Skip role="button" onClick={onHandleSkipClick}>
      Skip my special offer
    </Skip>
    <SectionInscription icon={<Guarantee />}>
      Guaranteed security payments
    </SectionInscription>
    <StyledPaymentCards />
  </InfoWrapper>
);

export default SectionInfo;
