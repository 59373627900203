import { config } from 'src/astromix/config';
import { AmplitudeService, AnalyticsService, ConversionsAPIService } from './analytics';

export * from './analytics/AnalyticsService';

export interface AstromixServices {
  analyticsService: AnalyticsService;
}

export const initAstromixServices = async (): Promise<AstromixServices> => {
  const amplitudeService = new AmplitudeService(config.amplitudeApiKey);
  const conversionsAPIService = new ConversionsAPIService();
  const analyticsService = new AnalyticsService(amplitudeService, conversionsAPIService);

  return {
    analyticsService,
  };
};
