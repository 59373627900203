import React from 'react';
import { config } from 'src/astromix/config';
import {
  PeopleAskWrapper,
  AccordionTitle,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  AccordionWrapper,
  DropdownIcon,
  StyledQuestionMark,
  Link,
  AccordionDetailsWrapper,
} from './styled';

export type SectionFeedbackProps = {
  onGetReport: () => void;
};

const SectionFeedback = () => (
  <PeopleAskWrapper>
    <AccordionTitle>People often ask</AccordionTitle>
    <AccordionWrapper>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<DropdownIcon />}>
          <StyledQuestionMark />
          How will I receive my personalised horoscope & compatibility report?
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsWrapper>
            You’ll receive your report in PDF format via email that you specified during the
            onboarding quiz. If you experience any difficulties with getting access to your report,
            please contact us at
            {' '}
            <Link href={config.supportCenter}>Support service</Link>
            .
          </AccordionDetailsWrapper>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<DropdownIcon />}>
          <StyledQuestionMark />
          How will I receive my personalised horoscope & compatibility report?
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsWrapper>
            The report will become available once our chief astrologer composes and approves your
            personalized advice. The report will arrive to your inbox during one hour.
          </AccordionDetailsWrapper>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<DropdownIcon />}>
          <StyledQuestionMark />
          How will I get access to the chat?
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsWrapper>
            Once you complete your order, you will be redirected to the chat page where you will be
            able to start chat with an advisor you were matched with.
          </AccordionDetailsWrapper>
        </StyledAccordionDetails>
      </StyledAccordion>
    </AccordionWrapper>
  </PeopleAskWrapper>
);

export default SectionFeedback;
