import React from 'react';
import { ColorVariants } from 'src/common/theme/types';
import { GuaranteeInscriptionStyled, LinkStyled } from './styled';

export interface GuaranteeInscriptionProps {
  variant?: ColorVariants;
  className?: string;
  to?: string;
  children: string;
}

const GuaranteeInscription = ({
  variant = 'primary',
  className,
  children,
  to,
}: GuaranteeInscriptionProps) => {
  if (to) {
    return <LinkStyled to={to} className={className} variant={variant}>{children}</LinkStyled>;
  }

  return (
    <GuaranteeInscriptionStyled className={className} variant={variant}>
      {children}
    </GuaranteeInscriptionStyled>
  );
};

export default GuaranteeInscription;
