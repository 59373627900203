import { LoadingStatuses, PurchaseProductName } from 'src/common/types';
import { useEffect, useState } from 'react';
import { ProductDTO } from 'src/common/services';
import { useLogger } from 'src/common/hooks/useLogger';
import { UnknownError } from 'src/common/errors';
import useServices from './useServices';

export function useProductDescription(productName: PurchaseProductName) {
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.PENDING);
  const [error, setError] = useState('');
  const [product, setProduct] = useState<ProductDTO | null>(null);

  const { purchaseService } = useServices();
  const logger = useLogger();

  useEffect(() => {
    const fetchProduct = async () => {
      if (!productName) return;

      try {
        setLoadingStatus(LoadingStatuses.PENDING);
        const res = await purchaseService.getProduct(productName);
        setProduct(res);
        setLoadingStatus(LoadingStatuses.FULFILLED);
      } catch (err) {
        setLoadingStatus(LoadingStatuses.FAILED);
        if (err instanceof Error) {
          logger.error(err);
          setError(err.message);
        } else {
          logger.error(new UnknownError(err));
          setError('Sorry, something went wrong');
        }
      }
    };

    fetchProduct();
  }, [purchaseService, logger, productName]);

  return {
    loadingStatus,
    error,
    product,
  };
}
