import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { LastLocationProvider } from 'react-router-last-location';
import MainContainer from 'src/astromix/components/MainContainer';
import { BASE_ROUTES } from 'src/astromix/constants/routes';
import CookiePolicy from 'src/astromix/pages/CookiePolicy';
import PaymentTerms from 'src/astromix/pages/PaymentTerms';
import PrivacyPolicy from 'src/astromix/pages/PrivacyPolicy';
import Terms from 'src/astromix/pages/Terms';
import Faq from 'src/astromix/pages/Faq';
import ContactUs from 'src/astromix/pages/ContactUs';
import ThemeContext from 'src/common/contexts/ThemeContext';
import { SplitSystemProvider } from 'src/splitSystem/contexts/SplitSystemProvider';
import Astromix from 'src/astromix/sites/Astromix/Astromix';
import Astromix_1weekVS1month from 'src/astromix/sites/1weekVS1month';
import { splitSystem } from 'src/astromix/experiments';

const Container = styled.div`
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const App = () => (
  <Suspense fallback={<div />}>
    <ThemeContext>
      <SplitSystemProvider splitSystem={splitSystem}>
        <LastLocationProvider>
          <Container>
            <MainContainer>
              <Switch>
                <Route exact path={BASE_ROUTES.COOKIE_POLICY} component={CookiePolicy} />
                <Route exact path={BASE_ROUTES.TERMS} component={Terms} />
                <Route exact path={BASE_ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
                <Route exact path={BASE_ROUTES.FAQ} component={Faq} />
                <Route exact path={BASE_ROUTES.CONTACT_US} component={ContactUs} />
                <Route exact path={BASE_ROUTES.PAYMENT_TERMS} component={PaymentTerms} />
                <Route path={BASE_ROUTES.ASTROMIX_1WEEK_1MONTH} component={Astromix_1weekVS1month} />
                <Route path={BASE_ROUTES.ASTROMIX} component={Astromix} />
              </Switch>
            </MainContainer>
          </Container>
        </LastLocationProvider>
      </SplitSystemProvider>
    </ThemeContext>
  </Suspense>
);

export default App;
