import React, { useContext } from 'react';
import { ExperimentContext } from '../Experiment';

interface VariantProps {
  name: string;
  children: React.ReactNode;
}

export const Variant = ({ children, name }: VariantProps) => {
  const experiment = useContext(ExperimentContext);

  if (!experiment || name !== experiment.group) return null;

  return <>{children}</>;
};
