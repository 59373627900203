import styled from 'styled-components';
import Accordion, { AccordionSummary, AccordionDetails } from 'src/common/components/Accordion';
import Title from 'src/astromix/components/Title';
import { ReactComponent as QuestionMark } from 'src/astromix/assets/images/question-mark.svg';
import { ReactComponent as Dropdown } from 'src/astromix/assets/images/dropdown.svg';

export const AccordionTitle = styled(Title)`
  margin: 0 auto 17px;
  color: ${(props) => props.theme.colors.secondary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin: 0 auto 50px;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
    border-bottom: ${(props) => `1px solid ${props.theme.colors.secondary} !important`};
    font-size: 14px;

    & > .accordion-summary-content {
      margin: 0 !important;
      padding: 15px 0;

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding: 20px 14px;
    }
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    border-bottom: ${(props) => `2px solid ${props.theme.colors.secondary} !important`};
    font-size: 16px;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  border-bottom: ${(props) => `1px solid ${props.theme.colors.secondary}`};
  padding: 10px 15px !important;
  font-size: 14px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    border-bottom: ${(props) => `2px solid ${props.theme.colors.secondary}`};
    padding: 20px 30px !important;
    font-size: 16px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
  border-radius: 20px 20px 0 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  color: ${(props) => `${props.theme.colors.secondary} !important`};

  &:last-child {
    border-radius: 0 0 20px 20px !important;
    
    ${StyledAccordionSummary} {
      border-bottom: none !important;
    }

    ${StyledAccordionSummary}[aria-expanded='true'] {
      border-bottom: ${(props) => `1px solid ${props.theme.colors.secondary} !important`};

      @media ${(props) => props.theme.breakpoints.tablet} {
        border-bottom: ${(props) => `2px solid ${props.theme.colors.secondary} !important`};
      }
    }

    ${StyledAccordionDetails} {
      border-bottom: none !important;
    }
  }
`;

export const AccordionWrapper = styled.div`
  width: 100%;
  max-width: 708px;
  border: ${(props) => `1px solid ${props.theme.colors.secondary}`};
  border-radius: 20px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    border: ${(props) => `2px solid ${props.theme.colors.secondary}`};
  }
`;

export const DropdownIcon = styled(Dropdown)`
  transform: rotate(-180deg);
  fill: ${(props) => `${props.theme.colors.secondary}`};
`;

export const StyledQuestionMark = styled(QuestionMark)`
  min-width: 24px;
  margin-right: 10px;
  fill: ${(props) => `${props.theme.colors.secondary}`};
`;

export const Link = styled.a`
  text-decoration: underline;
  font-weight: bold;
`;

export const AccordionDetailsWrapper = styled.p``;
