import React from 'react';
import LoaderList from 'src/astromix/components/Quiz/ProcessingLoader/LoaderList';
import ProcessingLoader from 'src/astromix/components/Quiz/ProcessingLoader';
import { Background } from 'src/astromix/components/Quiz/styled';
import Header from 'src/astromix/components/Header';
import { useQuizStep } from 'src/common/contexts/QuizContext';
import { ProcessingStepData } from 'src/astromix/types';
import useLoader from './useLoader';

export type LoaderProps = { id: string }

const Loader = ({ id }: LoaderProps) => {
  const { next, stepData } = useQuizStep(id);
  const {
    content, analytic,
  } = stepData as ProcessingStepData;

  useLoader(analytic);

  const onLoadingFinished = () => {
    next();
  };

  return (
    <>
      <Header buttonBack={false} variant="secondary" />
      <ProcessingLoader onFinished={onLoadingFinished} />
      <LoaderList list={content.list} />
      <Background />
    </>
  );
};

export default Loader;
