import styled from 'styled-components';
import ImagedPageSection, { ImagedPageSectionHolder } from 'src/common/components/ImagedPageSection';

export const sectionHeight = 1910;
export const sectionWidth = 360;
export const sectionTabletWidth = 768;
export const sectionTabletHeight = 1408;
export const sectionDesktopHeight = 1408;
export const sectionDesktopWidth = 1440;

export const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionTabletHeight} / ${sectionTabletWidth} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionDesktopHeight} / ${sectionDesktopWidth} * 100%);
    }
  }
`;

export const imagedSectionBlockHeight = 420;

export const ImagedPartnerZodiac = styled(ImagedPageSection)`
  margin-bottom: 30px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 0;
  }

  > ${ImagedPageSectionHolder} {
    padding-top: calc(${imagedSectionBlockHeight} / ${sectionWidth} * 100%);
  }
`;

export const ImagedZodiac = styled(ImagedPartnerZodiac)`
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-right: 30px;
  }
`;

export const sectionBlockHeight = 370;
export const tabletSectionBlockHeight = 500;

export const ImagedZodiacBlock = styled.div`
  position: absolute;
  width: 100%;
  max-width: 92%;
  left: 50%;
  transform: translateX(-50%);
  top: calc(${sectionBlockHeight} / ${sectionHeight} * 100%);
  display: flex;
  z-index: 1;
  flex-direction: column;
  @media ${(props) => props.theme.breakpoints.tablet} {
    flex-direction: row;
    top: calc(${tabletSectionBlockHeight} / ${sectionHeight} * 100%);
  }
  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 49%;
  }
`;
