/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { PriceTimerContainer, PriceTimerTime } from './styled';

interface PriceResolvedTimerProps {
  startTime: number;
  children: React.ReactNode;
  className?: string;
  element?: () => React.ReactNode;
  setIsTimeUp?: (state: boolean) => void;
}

const PriceResolvedTimer = ({
  startTime,
  children,
  className,
  setIsTimeUp,
  element,
}: PriceResolvedTimerProps) => {
  const [timerValue, setTimerValue] = useState(startTime);

  function convertToTimeFormat(time: number) {
    const minutes = Math.floor(time / (1000 * 60)).toString();
    let seconds = ((time % (1000 * 60)) / 1000).toString();
    if (seconds.length === 1) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (timerValue) {
      timer = setTimeout(() => {
        setTimerValue(timerValue - 1000);
      }, 1000);
    } else if (setIsTimeUp) setIsTimeUp(true);

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timerValue]);

  return (
    <PriceTimerContainer className={className}>
      {children}
      <PriceTimerTime>{convertToTimeFormat(timerValue)}</PriceTimerTime>
      {element && element()}
    </PriceTimerContainer>
  );
};

export default PriceResolvedTimer;
