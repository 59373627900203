import React from 'react';
import Input from 'src/astromix/components/Input';
import Title from 'src/astromix/components/Title';
import {
  AutocompleteContainer, Button, Content, InputBlock, Text,
} from './styled';
import { ContainerMain, Image } from '../styled';
import PlaceList from './PlaceList';

export interface CitiesList {
  geometry: {
    coordinates: number[];
  };
  properties: {
    id: string;
    country: string;
    name: string;
    region: string;
  };
}

interface PlaceContainerProps {
  image: string;
  title: string;
  text: string;
  citiesList: Array<CitiesList>;
  location: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelected: (coordinates: string[], location: string) => void;
  handleInputClick: () => void;
  hasVisibleContent: boolean;
  handleCancelClick: () => void;
}

const PlaceContainer = ({
  image,
  text,
  title,
  citiesList,
  handleInputChange,
  location,
  onSelected,
  handleInputClick,
  hasVisibleContent,
  handleCancelClick,
}: PlaceContainerProps) => (
  <ContainerMain>
    <Content>
      {hasVisibleContent && (
        <>
          <Image src={image} alt="image" width={203} height={203} />
          <Title>{title}</Title>
          <Text>{text}</Text>
        </>
      )}
      <AutocompleteContainer>
        <InputBlock>
          <Input
            placeholder="Enter city of birth"
            onChange={handleInputChange}
            value={location}
            onClick={handleInputClick}
          />
          {!hasVisibleContent && <Button onClick={handleCancelClick}>Cancel</Button>}
        </InputBlock>
        <PlaceList onSelected={onSelected} citiesList={citiesList} />
      </AutocompleteContainer>
    </Content>
  </ContainerMain>
);

export default PlaceContainer;
