import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
  z-index: 5;
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 50px;
  }
`;

export const Block = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: -3px;
  left: 0;
  z-index: 4;
`;

export const Value = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: ${(props) => props.theme.colors.secondary};
  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 32px;
  }
`;
