import { PurchaseProductName } from 'src/common/types';

interface IPlansTypes {
  [keyof: string]: string;
}

export const PlansTypes: IPlansTypes = {
  [PurchaseProductName.ASTROMIX_7D_TRIAL_3M_9999]: '$99.99 per 3 months',
  [PurchaseProductName.ASTROMIX_7D_TRIAL_1M_4999]: '$49.99 per month',
  [PurchaseProductName.ASTROMIX_7D_TRIAL_1W_2000]: '$20 per week',
};
