import { createReducer } from '@reduxjs/toolkit';
import { setUserInformation, signedUpByToken, userSignedUp } from './index';

const initialState = {
  gender: '',
  date: '',
  zodiac: '',
  place: [],
  location: '',
  time: '12:00:00',
  partnerGender: '',
  partnerDate: '',
  partnerZodiac: '',
  partnerPlace: [],
  partnerLocation: '',
  partnerTime: '12:00:00',
  email: '',
  id: '',
};

export const userProfile = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserInformation, (state, action) => ({
      ...state,
      [action.payload.name]: action.payload.value,
    }))
    .addCase(userSignedUp, (state, action) => ({
      ...state,
      email: action.payload.email,
      id: action.payload.id,
    }))
    .addCase(signedUpByToken, (state, action) => ({
      ...state,
      id: action.payload.id,
    }));
});
