import amplitude from 'amplitude-js';
import { PurchaseProductName } from 'src/common/types';
import { QUIZ_ANALYTIC_EVENTS } from './events';

export class AmplitudeService {
  private instance: amplitude.AmplitudeClient;

  private userId: string

  constructor(apiKey: string) {
    this.instance = amplitude.getInstance();
    this.instance.init(apiKey);
    this.userId = '';
  }

  setUserProperties(userData: { [key: string]: unknown }) {
    this.instance.setUserProperties(userData);
  }

  launch() {
    if (!localStorage.getItem('launched')) {
      localStorage.setItem('launched', 'true');
      this.instance.logEvent('astromix_launch_first_time');
    } else if (!sessionStorage.getItem('launched')) {
      sessionStorage.setItem('launched', 'true');
      this.instance.logEvent('astromix_launch');
    }
  }

  trackQuizEvent(quizEvent: QUIZ_ANALYTIC_EVENTS, eventProperties: Record<string, any> = {}) {
    this.instance.logEvent(quizEvent, eventProperties);
  }

  setCurrentQuizName(quizName: string) {
    this.setUserProperties({
      quiz: quizName,
    });
  }

  setUserId(userId: string) {
    this.instance.setUserId(userId);
    this.userId = userId;
  }

  extendedLpRedirect(userId: string) {
    this.setUserProperties({
      landing_page_id: 'extended-landing',
      user_id: userId,
    });
    this.instance.logEvent('flow_landing_page_redirect');
  }

  extendedLpOpened(userId: string) {
    this.setUserProperties({
      landing_page_id: 'extended-landing',
      user_id: userId,
    });
    this.instance.logEvent('lp_extended_open');
  }

  setUserExperimentGroupName(experimentName: string, groupName: string) {
    this.setUserProperties({
      flow: experimentName,
      group_name: groupName,
    });
  }

  extendedLpGetReport(userId: string) {
    this.setUserProperties({
      landing_page_id: 'extended-landing',
      user_id: userId,
    });
    this.instance.logEvent('lp_extended_get_report_click');
  }

  policyPageOpened(pageName: string, contextName: string, userId: string) {
    this.instance.logEvent('astromix_policies_page_open', {
      page_name: pageName,
    });

    this.setUserProperties({
      user_id: userId,
      context: contextName,
    });
  }

  faqPageOpened(pageName: string, contextName: string, userId: string) {
    this.instance.logEvent('astromix_faq_page_open', {
      page_name: pageName,
    });

    this.setUserProperties({
      user_id: userId,
      context: contextName,
    });
  }

  contactUsPageOpened(pageName: string, contextName: string, userId: string) {
    this.instance.logEvent('astromix_contact_page_open', {
      page_name: pageName,
    });

    this.setUserProperties({
      user_id: userId,
      context: contextName,
    });
  }

  billingLpOpened(userId: string) {
    this.setUserProperties({
      landing_page_id: 'billing',
      user_id: userId,
    });
    this.instance.logEvent('lp_billing_open');
  }

  billingLpGetReport(userId: string) {
    this.setUserProperties({
      landing_page_id: 'billing',
      user_id: userId,
    });
    this.instance.logEvent('lp_billing_get_click');
  }

  billingLpAdditionalDiscountPopup(userId: string) {
    this.setUserProperties({
      landing_page_id: 'billing',
      user_id: userId,
    });
    this.instance.logEvent('lp_billing_extra_popup');
  }

  billingLpGetAdditionalDiscount(userId: string) {
    this.setUserProperties({
      landing_page_id: 'billing',
      user_id: userId,
    });
    this.instance.logEvent('lp_billing_extra_click');
  }

  additionalDiscountLpOpened(userId: string) {
    this.setUserProperties({
      landing_page_id: 'additional-discount',
      user_id: userId,
    });
    this.instance.logEvent('lp_addition_discount_open');
  }

  additionalDiscountLpGetReport(userId: string, productId: PurchaseProductName) {
    this.setUserProperties({
      landing_page_id: 'additional-discount',
      user_id: userId,
    });
    this.instance.logEvent('lp_addition_discount_get_click', {
      product_id: productId,
    });
  }

  paymentCardChosen(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.instance.logEvent('payment_card_chosen');
  }

  paymentPaypalChosen(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.instance.logEvent('payment_paypal_chosen');
  }

  paymentError(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.instance.logEvent('payment_error');
  }

  paymentRetried(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.instance.logEvent('payment_retry');
  }

  paymentSuccess({ productId }: { productId: string }) {
    this.setUserProperties({
      product_id: productId,
    });
    this.instance.logEvent('payment_success', {
      product_id: productId,
    });
  }

  numerologyLpOpened(userId: string, pageOrder: number) {
    this.setUserProperties({
      landing_page_id: 'numerology',
      user_id: userId,
    });
    this.instance.logEvent('lp_upsell_screen_open', {
      page_num: pageOrder,
      product_id: PurchaseProductName.NUMEROLOGY_CALCULATOR,
    });
  }

  numerologyLpGetReport(userId: string, pageOrder: number) {
    this.setUserProperties({
      landing_page_id: 'numerology',
      user_id: userId,
    });
    this.instance.logEvent('lp_upsell_get_report_click', {
      page_num: pageOrder,
      product_id: PurchaseProductName.NUMEROLOGY_CALCULATOR,
    });
  }

  numerologyLpSkip(userId: string, pageOrder: number) {
    this.setUserProperties({
      landing_page_id: 'numerology',
      user_id: userId,
    });
    this.instance.logEvent('lp_upsell_skip_click', {
      page_num: pageOrder,
      product_id: PurchaseProductName.NUMEROLOGY_CALCULATOR,
    });
  }

  tarotLpOpened(userId: string, pageOrder: number) {
    this.setUserProperties({
      landing_page_id: 'tarot',
      user_id: userId,
    });
    this.instance.logEvent('lp_upsell_screen_open', {
      page_num: pageOrder,
      product_id: PurchaseProductName.TAROT_SPREAD_FOR_2022,
    });
  }

  tarotLpGetReport(pageOrder: number) {
    this.instance.logEvent('lp_upsell_get_report_click', {
      page_num: pageOrder,
      product_id: PurchaseProductName.TAROT_SPREAD_FOR_2022,
    });
  }

  tarotLpSkip(pageOrder: number) {
    this.instance.logEvent('lp_upsell_skip_click', {
      page_num: pageOrder,
      product_id: PurchaseProductName.TAROT_SPREAD_FOR_2022,
    });
  }

  emailMarketingLpOpened(userId: string, pageOrder: number) {
    this.setUserProperties({
      landing_page_id: `lp_abandoned_cart_${pageOrder}`,
      user_id: userId,
    });
    this.instance.logEvent('lp_abandoned_cart_open');
  }

  emailMarketingLpGetProduct(userId: string, productName: PurchaseProductName, pageOrder: number) {
    this.setUserProperties({
      landing_page_id: `lp_abandoned_cart_${pageOrder}`,
      user_id: userId,
    });
    this.instance.logEvent('lp_abandoned_cart_click', {
      product_id: productName,
    });
  }
}
