export interface Config {
  geocodeUrl: string,
  geocodeApiKey: string,
  apiUrl: string,
  apiPrefix: string,
  mainAppUrl: string,
  amplitudeApiKey: string,
  supportCenter: string,
  dateOfTerms: string,
  mail: string,
  settings: string,
}

const stageConfig: Config = {
  geocodeUrl: 'https://api.geocode.earth/v1/autocomplete',
  geocodeApiKey: 'ge-167dccfa75019f47',
  apiUrl: 'https://stage-api-asknebula.obrio.net/api/',
  apiPrefix: 'v1',
  mainAppUrl: 'https://stage-asknebula.obrio.net',
  amplitudeApiKey: 'ab26211aef21d7fed890f6360e92c753',
  supportCenter: 'http://answers-365.com',
  dateOfTerms: '13 September 2021',
  mail: 'hello@astro-mix.co/',
  settings: 'https://asknebula.com/profile/settings',
};

const productionConfig: Config = {
  geocodeUrl: 'https://api.geocode.earth/v1/autocomplete',
  geocodeApiKey: 'ge-167dccfa75019f47',
  apiUrl: 'https://api.astro-mix.co/api/',
  apiPrefix: 'v1',
  mainAppUrl: 'https://asknebula.com/',
  amplitudeApiKey: 'f2084a6356b59f421a6de5092d799a21',
  supportCenter: 'http://answers-365.com',
  dateOfTerms: '13 September 2021',
  mail: 'hello@astro-mix.co/',
  settings: 'https://asknebula.com/profile/settings',
};

export const config = process.env.REACT_APP_ENV === 'production'
  ? productionConfig
  : stageConfig;
