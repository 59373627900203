import React from 'react';
import Header from 'src/astromix/components/Header';
import { useSelector } from 'react-redux';
import { getBirthdate, getPartnerBirthdate } from 'src/astromix/store/userProfile';
import { useGetZodiacImage } from 'src/common/hooks/useGetZodiacImage';
import { LANDINGS, BASE_ROUTES } from 'src/astromix/constants';
import { Redirect } from 'react-router-dom';
import { ZodiacService } from 'src/common/services';
import SectionIntro from './SectionIntro';
import SectionInfo from './SectionInfo';
import SectionFeature from './SectionFeature';
import { useExtendedLanding } from '../useExtendedLanding';

const ExtendedLanding = () => {
  const { getReport } = useExtendedLanding(BASE_ROUTES.ASTROMIX);

  const birthdate = useSelector(getBirthdate);
  const partnerBirthdate = useSelector(getPartnerBirthdate);

  const zodiacSign = ZodiacService.getZodiacSignByDate(birthdate);
  const partnerZodiacSign = ZodiacService.getZodiacSignByDate(partnerBirthdate);

  const { zodiacImage, zodiacPartnerImage } = useGetZodiacImage(zodiacSign, partnerZodiacSign);

  if (!birthdate || !partnerBirthdate) {
    return <Redirect to={LANDINGS.NUMEROLOGY} />;
  }

  return (
    <>
      <Header buttonBack={false} />
      <SectionIntro userZodiac={zodiacImage} partnerZodiac={zodiacPartnerImage} />
      <SectionInfo onGetReport={getReport} />
      <SectionFeature onGetReport={getReport} />
    </>
  );
};

export default ExtendedLanding;
