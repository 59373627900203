import styled from 'styled-components';
import ImagedPageSection, { ImagedPageSectionHolder } from 'src/common/components/ImagedPageSection';
import Button from 'src/common/components/Button';
import PriceBlock, { PriceHeader, PriceMain, StyledPrice } from 'src/astromix/components/PriceBlock';
import Title from 'src/astromix/components/Title';
import ImagedPageInscription from 'src/common/components/ImagedPageSectionInscription';
import { ReactComponent as PaymentCards } from 'src/astromix/assets/images/paymentCards.svg';

export const sectionWidth = 1366;
export const sectionWidthTablet = 768;
export const sectionWidthMobile = 360;
export const sectionHeight = 849;
export const sectionHeightTablet = 849;
export const sectionHeightMobile = 594;

export const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeightMobile} / ${sectionWidthMobile} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionHeightTablet} / ${sectionWidthTablet} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);
    }
  }
`;

export const Skip = styled.p`
  cursor: pointer;
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: underline;
  width: 310px;
  font-size: 14px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 50px;
  }
`;

export const StyledButton = styled(Button)`
  margin: 50px auto 23px;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledPriceBlock = styled(PriceBlock)`
  border: none;
  width: 160px;
  padding: 4px;
  background: linear-gradient(270.69deg, #975BDD -30.16%, #FC9F33 153.85%);
  border-top: none;
  width: 100%;
  max-width: 330px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    max-width: 453px;
  }

  ${PriceHeader} {
    border: none;
    margin-top: -4px;
    margin-left: -4px;
    margin-right: -4px;
    color: ${(props) => props.theme.colors.secondary};
    background: linear-gradient(89.69deg, #975BDD -30.16%, #FC9F33 153.85%);
    padding-top: 13px;
    padding-bottom: 7px;

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }

  ${PriceMain} {
    background: #4C81BD;

    @media ${(props) => props.theme.breakpoints.tablet} {
      background: #4A75B4;
    }
  }

  ${StyledPrice} {
    font-weight: bold;
    font-size: 48px;
    line-height: 65px;
    color: ${(props) => props.theme.colors.secondary};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: 64px;
      line-height: 87px;
    }
  }
`;

export const StyledTitle = styled(Title)`
  margin-top: 40px;
  margin-bottom: 30px;
`;

export const SectionInscription = styled(ImagedPageInscription)`
  position: relative;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 50px;
    margin-bottom: 60px;
  }
`;

export const StyledPaymentCards = styled(PaymentCards)`
  width: 100%;
  max-width: 708px;
`;
