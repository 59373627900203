import React from 'react';
import { config } from 'src/astromix/config';
import {
  AccordionTitle,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  AccordionWrapper,
  DropdownIcon,
  StyledQuestionMark,
  Link,
  AccordionDetailsWrapper,
} from './styled';

export type FAQProps = {
  onGetReport: () => void;
};

const FAQ = () => (
  <>
    <AccordionTitle>FAQ</AccordionTitle>
    <AccordionWrapper>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<DropdownIcon />}>
          <StyledQuestionMark />
          What happens after I place the order?
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsWrapper>
            You’ll receive your guide via email that you specified earlier. If you experience any
            difficulties with getting access to your readings, please contact us at
            {' '}
            <Link href={config.supportCenter}>Support center</Link>
            .
          </AccordionDetailsWrapper>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<DropdownIcon />}>
          <StyledQuestionMark />
          When will I receive my guide?
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsWrapper>
            Your guide will become available along with your compatibility report and will be
            delivered through email.
          </AccordionDetailsWrapper>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<DropdownIcon />}>
          <StyledQuestionMark />
          How long will I have access to my guide for?
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsWrapper>
            There is no expiration date when it comes to access to your guide. It will always be
            available to you.
          </AccordionDetailsWrapper>
        </StyledAccordionDetails>
      </StyledAccordion>
    </AccordionWrapper>
  </>
);

export default FAQ;
