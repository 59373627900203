import styled from 'styled-components';
import Accordion, { AccordionSummary, AccordionDetails } from 'src/common/components/Accordion';
import Title from 'src/astromix/components/Title';
import { ReactComponent as Dropdown } from 'src/astromix/assets/images/dropdown.svg';
import { ReactComponent as QuestionIcon }
  from 'src/astromix/assets/images/additional-discount/1weekVS1month/question_mark.svg';

export const AccordionTitle = styled(Title)`
  margin: 40px auto 17px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin: 50px auto;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
    border-bottom: 2px solid #6A3AA2 !important;
    font-size: 18px;
    line-height: 21px;
    background: -webkit-linear-gradient(180deg, #141333, #202261, #543C97, #6939A2);;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media ${(props) => props.theme.breakpoints.tablet} {
      background: transparent;
      -webkit-background-clip: border-box;
      -webkit-text-fill-color: ${(props) => props.theme.colors.primary};
      font-weight: 600;
      font-size: 20px;
      line-height: 140%;
    }

    & > .accordion-summary-content {
      margin: 0 !important;
      padding: 15px 0;


    @media ${(props) => props.theme.breakpoints.tablet} {
      padding: 20px 14px;
    }
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    border-bottom: 2px solid #6A3AA2 !important;
    font-size: 16px;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  border-bottom: 2px solid #6A3AA2;
  padding: 10px 15px !important;
  font-size: 14px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    border-bottom: 2px solid #6A3AA2;
    padding: 20px 30px !important;
    font-size: 16px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
  border-radius: 20px 20px 0 0 !important;
  margin: 0 !important;

  &:last-child {
    border-radius: 0 0 20px 20px !important;
    
    ${StyledAccordionSummary} {
      border-bottom: none !important;
    }

    ${StyledAccordionSummary}[aria-expanded='true'] {
      border-bottom: 2px solid #6A3AA2 !important;

      @media ${(props) => props.theme.breakpoints.tablet} {
        border-bottom: 2px solid #6A3AA2 !important;
      }
    }

    ${StyledAccordionDetails} {
      border-bottom: none !important;
    }
  }
`;

export const AccordionWrapper = styled.div`
  width: 100%;
  max-width: 708px;
  border: 2px solid #6A3AA2;
  border-radius: 20px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    border: 2px solid #6A3AA2;
  }
`;

export const DropdownIcon = styled(Dropdown)`
  transform: rotate(-180deg);
`;

export const StyledQuestionMark = styled(QuestionIcon)`
  min-width: 24px;
  margin-right: 10px;
`;

export const Link = styled.a`
  text-decoration: underline;
  font-weight: bold;
`;

export const AccordionDetailsWrapper = styled.p`
`;

export const PeopleAskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
