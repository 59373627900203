import React from 'react';
import { PriceTypes } from 'src/astromix/components/Price';
import {
  PriceWrapper,
  PriceHeader,
  PriceTitle,
  PriceMain,
  StyledPrice,
  OldPrice,
  HeaderDiscount,
  HeaderDiscountText,
  HeaderDiscountTextWrapper,
} from './styled';

export type PriceBlockProps = {
  className?: string;
  headerContent?: string;
  titleContent?: string;
  isHeaderExist?: boolean;
  price: string | number;
  type?: Partial<PriceTypes>;
  discount?: string;
  oldPrice?: string;
  oldPriceType?: Partial<PriceTypes>;
};

const PriceBlock = ({
  className,
  titleContent,
  isHeaderExist = true,
  price,
  type,
  discount,
  oldPrice,
  oldPriceType,
}: PriceBlockProps) => {
  const choosePriceView = (oldPriceValue?: string) => {
    if (oldPriceValue) {
      return (
        <>
          <OldPrice type={oldPriceType}>{oldPriceValue}</OldPrice>
          <StyledPrice type={type}>{price}</StyledPrice>
        </>
      );
    }

    return <StyledPrice type={type}>{price}</StyledPrice>;
  };

  return (
    <PriceWrapper className={className}>
      {isHeaderExist && (
        <PriceHeader>
          {!!discount && (
            <HeaderDiscount>
              <HeaderDiscountTextWrapper>
                <HeaderDiscountText>{discount}</HeaderDiscountText>
              </HeaderDiscountTextWrapper>
            </HeaderDiscount>
          )}
          {!!titleContent && <PriceTitle>{titleContent}</PriceTitle>}
        </PriceHeader>
      )}
      <PriceMain>
        {choosePriceView(oldPrice)}
      </PriceMain>
    </PriceWrapper>
  );
};

export default PriceBlock;
