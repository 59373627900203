/* eslint-disable class-methods-use-this */
import qs from 'qs';
import { PurchaseProductName } from 'src/common/types';
import { AmplitudeService } from './AmplitudeService';
import { QUIZ_ANALYTIC_EVENTS } from './events';
import { CAPIAttributionParameters, ConversionsAPIService } from './ConversionsAPIService';

export interface UtmTags {
  utm_source?: string,
  utm_medium?: string,
  utm_campaign?: string,
  utm_content?: string,
  utm_term?: string,
}
export type AttributionParameters = CAPIAttributionParameters;

export class AnalyticsService {
  private amplitudeService: AmplitudeService;

  private conversionsAPIService: ConversionsAPIService;

  private utmTags: UtmTags;

  private userId: string

  constructor(amplitudeService: AmplitudeService, conversionsAPIService: ConversionsAPIService) {
    this.utmTags = this.loadUtmTags();
    this.saveUtmTagsToStorage(this.utmTags);

    this.userId = this.loadUserIdFromStorage();

    this.amplitudeService = amplitudeService;
    this.conversionsAPIService = conversionsAPIService;

    if (this.utmTags.utm_source) {
      this.amplitudeService.setUserProperties({
        media_source: this.utmTags.utm_source,
      });
    }
  }

  getAttributionParameters(): AttributionParameters {
    return {
      ...this.conversionsAPIService.getAttributionParameters(),
    };
  }

  trackQuizEvent(quizEvent: QUIZ_ANALYTIC_EVENTS, eventProperties: Record<string, any> = {}) {
    this.amplitudeService.trackQuizEvent(quizEvent, eventProperties);
  }

  setCurrentQuizName(quizName: string) {
    this.amplitudeService.setCurrentQuizName(quizName);
  }

  setUserId(userId: string) {
    this.amplitudeService.setUserId(userId);
    this.userId = userId;
  }

  policyPageOpened(pageName: string, contextName: string) {
    this.amplitudeService.policyPageOpened(pageName, contextName, this.userId);
  }

  faqPageOpened(pageName: string, contextName: string) {
    this.amplitudeService.faqPageOpened(pageName, contextName, this.userId);
  }

  contactUsPageOpened(pageName: string, contextName: string) {
    this.amplitudeService.contactUsPageOpened(pageName, contextName, this.userId);
  }

  setUserExperimentGroupName(experimentName: string, groupName: string) {
    this.amplitudeService.setUserExperimentGroupName(experimentName, groupName);
  }

  launch() {
    this.amplitudeService.launch();
  }

  extendedLpRedirect() {
    this.amplitudeService.extendedLpRedirect(this.userId);
  }

  extendedLpOpened() {
    this.amplitudeService.extendedLpOpened(this.userId);
  }

  extendedLpGetReport() {
    this.amplitudeService.extendedLpGetReport(this.userId);
  }

  billingLpOpened() {
    this.amplitudeService.billingLpOpened(this.userId);
  }

  billingLpGetReport() {
    this.amplitudeService.billingLpGetReport(this.userId);
  }

  billingLpAdditionalDiscountPopup() {
    this.amplitudeService.billingLpAdditionalDiscountPopup(this.userId);
  }

  billingLpGetAdditionalDiscount() {
    this.amplitudeService.billingLpGetAdditionalDiscount(this.userId);
  }

  additionalDiscountLpOpened() {
    this.amplitudeService.additionalDiscountLpOpened(this.userId);
  }

  additionalDiscountLpGetReport(product: PurchaseProductName) {
    this.amplitudeService.additionalDiscountLpGetReport(this.userId, product);
  }

  paymentCardChosen() {
    this.amplitudeService.paymentCardChosen(this.userId);
  }

  paymentPaypalChosen() {
    this.amplitudeService.paymentPaypalChosen(this.userId);
  }

  paymentRetried() {
    this.amplitudeService.paymentRetried(this.userId);
  }

  paymentError() {
    this.amplitudeService.paymentError(this.userId);
  }

  paymentSuccess(paymentData: { productId: string, price: number; orderId: string }) {
    this.amplitudeService.paymentSuccess(paymentData);
  }

  numerologyLpOpened(pageOrder: number) {
    this.amplitudeService.numerologyLpOpened(this.userId, pageOrder);
  }

  numerologyLpGetReport(pageOrder: number) {
    this.amplitudeService.tarotLpGetReport(pageOrder);
  }

  numerologyLpSkip(pageOrder: number) {
    this.amplitudeService.numerologyLpSkip(this.userId, pageOrder);
  }

  tarotLpOpened(pageOrder: number) {
    this.amplitudeService.tarotLpOpened(this.userId, pageOrder);
  }

  tarotLpGetReport(pageOrder: number) {
    this.amplitudeService.tarotLpGetReport(pageOrder);
  }

  tarotLpSkip(pageOrder: number) {
    this.amplitudeService.tarotLpSkip(pageOrder);
  }

  emailMarketingLpOpened(pageOrder: number) {
    this.amplitudeService.emailMarketingLpOpened(this.userId, pageOrder);
  }

  emailMarketingLpGetProduct(productName: PurchaseProductName, pageOrder: number) {
    this.amplitudeService.emailMarketingLpGetProduct(this.userId, productName, pageOrder);
  }

  private loadUtmTags() {
    const utmTags = this.loadUtmTagsFromStorage();

    const parsedQs = qs.parse(window.location.search, { ignoreQueryPrefix: true }) as { [key: string]: unknown };

    if (parsedQs.utm_source) {
      utmTags.utm_source = Array.isArray(parsedQs.utm_source) ? parsedQs.utm_source[0] : parsedQs.utm_source;
    }
    if (parsedQs.utm_medium) {
      utmTags.utm_medium = Array.isArray(parsedQs.utm_medium) ? parsedQs.utm_medium[0] : parsedQs.utm_medium;
    }
    if (parsedQs.utm_campaign) {
      utmTags.utm_campaign = Array.isArray(parsedQs.utm_campaign) ? parsedQs.utm_campaign[0] : parsedQs.utm_campaign;
    }
    if (parsedQs.utm_content) {
      utmTags.utm_content = Array.isArray(parsedQs.utm_content) ? parsedQs.utm_content[0] : parsedQs.utm_content;
    }
    if (parsedQs.utm_term) {
      utmTags.utm_term = Array.isArray(parsedQs.utm_term) ? parsedQs.utm_term[0] : parsedQs.utm_term;
    }

    return utmTags;
  }

  private saveUtmTagsToStorage(utmTags: UtmTags) {
    try {
      window.sessionStorage.setItem('utmTags', JSON.stringify(utmTags));
    } catch (err) {
      // TODO(Logger)
      console.error('Failed to save utm tags', err);
    }
  }

  private loadUtmTagsFromStorage(): UtmTags {
    try {
      const utmTagsRaw = window.sessionStorage.getItem('utmTags') || '{}';
      return JSON.parse(utmTagsRaw);
    } catch (err) {
      // TODO(Logger)
      console.error('Failed to load utm tags', err);
      return {};
    }
  }

  private loadUserIdFromStorage(): string {
    try {
      const userStorage = sessionStorage.getItem('astromix') || '';
      return JSON.parse(userStorage).userProfile.id;
    } catch (err) {
      // TODO(Logger)
      console.error('Failed to load user id', err);
      return '';
    }
  }
}
