import { useEffect } from 'react';
import { useLastLocation } from 'react-router-last-location';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { BASE_ROUTES } from 'src/astromix/constants';

export function useContactUs() {
  const { analyticsService } = useAstromixServices();

  const lastLocation = useLastLocation();

  useEffect(() => {
    analyticsService.contactUsPageOpened(BASE_ROUTES.CONTACT_US, lastLocation?.pathname || '');
  }, [analyticsService]);
}
