/* eslint-disable max-len,react/jsx-one-expression-per-line */
import React from 'react';
import { config } from 'src/astromix/config';
import { BASE_ROUTES } from 'src/astromix/constants';
import { usePrivacyPolicy } from './usePrivacyPolicy';
import { DropdownSection } from './DropdownSection';
import {
  Address,
  Container,
  Link,
  ListItem,
  MainTitle,
  NumberList,
  RouteLink,
  StyledHeader,
  Subtitle,
  Text,
  Title,
  UnorderedList,
  Wrapper,
} from './styled';

const createSectionParams = (id: string) => ({
  id,
  href: `#${id}`,
});

const sections = {
  1: createSectionParams('section1'),
  2: createSectionParams('section2'),
  3: createSectionParams('section3'),
  4: createSectionParams('section4'),
  5: createSectionParams('section5'),
  6: createSectionParams('section6'),
  7: createSectionParams('section7'),
  8: createSectionParams('section8'),
  9: createSectionParams('section9'),
  10: createSectionParams('section10'),
  11: createSectionParams('section11'),
  12: createSectionParams('section12'),
  13: createSectionParams('section13'),
};

const PrivacyPolicy = () => {
  usePrivacyPolicy();

  return (
    <>
      <StyledHeader buttonBack={false} />
      <div>
        <MainTitle>ASTROMIX</MainTitle>
        <Title>IMPORTANT PRIVACY INFORMATION</Title>
        <Text>
          When you access our website we automatically collect from your device language settings,
          IP address, time zone, type and model of a device, device settings, operating system,
          Internet service provider, mobile carrier, hardware ID, Facebook ID, and other unique
          identifiers. We need this data to provide our services, analyze how our customers use the
          website and to measure ads.
        </Text>
        <Text>
          For improving the website and serving ads, we use third party solutions. As a result, we
          may process data using solutions developed by{' '}
          <b>Amazon, Amplitude, Google, Facebook, Twitter, TikTok, Pinterest, Snapchat, Zendesk.</b>
          Therefore, some of the data is stored and processed on the servers of such third parties.
          This enables us to (1) analyze different interactions (how often users make purchases,
          what products our users viewed); (2) serve and measure ads (and show them only to a
          particular group of users, for example, only to those, who have made a purchase).{' '}
        </Text>
        <Text>
          Please read our Privacy Policy below to know more about what we do with data (
          <Link href={sections[3].href}>Section 3</Link>) , what data privacy rights are available
          to you (<Link href={sections[6].href}>Section 6</Link>) and who will be the data
          controller (<Link href={sections[1].href}>Section 1</Link>
          ). If any questions will remain unanswered, please contact us at{' '}
          <Link href={`mailto: ${config.supportCenter}`}>Support Center</Link>.
        </Text>
        <Title>PRIVACY POLICY</Title>
        <Text>
          This Privacy Policy explains what personal data is collected when you use the website
          located at: <b>https://astro-mix.co/</b> (the <b>“Website”</b>
          ), the services and products provided through it (together with the Website, the{' '}
          <b>“Service”</b>
          ), how such personal data will be processed.
        </Text>
        <Text>
          BY USING THE SERVICE, YOU PROMISE US THAT (I) YOU HAVE READ, UNDERSTAND AND AGREE TO THIS
          PRIVACY POLICY, AND (II) YOU ARE OVER 16 YEARS OF AGE (OR HAVE HAD YOUR PARENT OR GUARDIAN
          READ AND AGREE TO THIS PRIVACY POLICY FOR YOU). If you do not agree, or are unable to make
          this promise, you must not use the Service. In such case, you must (a) contact us and
          request deletion of your data; (b) leave the Website and not access or use it.{' '}
        </Text>
        <Text>
          Any translation from English version is provided for your convenience only. In the event
          of any difference in meaning or interpretation between the English language version of
          this Privacy Policy available at{' '}
          <RouteLink to={BASE_ROUTES.PRIVACY_POLICY}>[LINK]</RouteLink>, and any translation, the
          English language version will prevail. The original English text shall be the sole legally
          binding version.
        </Text>
        <Text>
          <b>“GDPR”</b> means the General Data Protection Regulation (EU) 2016/679 of the European
          Parliament and of the Council of 27 April 2016 on the protection of natural persons with
          regard to the processing of personal data and on the free movement of such data.
        </Text>
        <Text>
          <b>“EEA”</b> includes all current member states to the European Union and the European
          Free Trade Association. For the purpose of this policy EEA shall include the United
          Kingdom of Great Britain and Northern Ireland.
        </Text>
        <Text>
          <b>“Process”</b>, in respect of personal data, includes to collect, store, and disclose to
          others.
        </Text>
        <Subtitle>TABLE OF CONTENTS</Subtitle>
        <NumberList>
          <ListItem>
            <Link href={sections[1].href}>PERSONAL DATA CONTROLLER</Link>
          </ListItem>
          <ListItem>
            <Link href={sections[2].href}>CATEGORIES OF PERSONAL DATA WE COLLECT</Link>
          </ListItem>
          <ListItem>
            <Link href={sections[3].href}>FOR WHAT PURPOSES WE PROCESS PERSONAL DATA</Link>
          </ListItem>
          <ListItem>
            <Link href={sections[4].href}>
              UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA (Applies only to EEA-based users)
            </Link>
          </ListItem>
          <ListItem>
            <Link href={sections[5].href}>WITH WHOM WE SHARE YOUR PERSONAL DATA</Link>
          </ListItem>
          <ListItem>
            <Link href={sections[6].href}>HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS</Link>
          </ListItem>
          <ListItem>
            <Link href={sections[7].href}>AGE LIMITATION</Link>
          </ListItem>
          <ListItem>
            <Link href={sections[8].href}>INTERNATIONAL DATA TRANSFERS</Link>
          </ListItem>
          <ListItem>
            <Link href={sections[9].href}>CHANGES TO THIS PRIVACY POLICY</Link>
          </ListItem>
          <ListItem>
            <Link href={sections[10].href}>CALIFORNIA PRIVACY RIGHTS</Link>
          </ListItem>
          <ListItem>
            <Link href={sections[11].href}>DATA RETENTION</Link>
          </ListItem>
          <ListItem>
            <Link href={sections[12].href}>HOW “DO NOT TRACK” REQUESTS ARE HANDLED</Link>
          </ListItem>
          <ListItem>
            <Link href={sections[13].href}>CONTACT US</Link>
          </ListItem>
        </NumberList>
        <NumberList>
          <ListItem id={sections[1].id}>
            <b>PERSONAL DATA CONTROLLER</b>
            <Text>
              Obrio Limited a legal entity incorporated under the laws of the Republic of Cyprus,
              having its registered office at <Address title="address" />, Nicosia, Cyprus, will be
              the controller of your personal data.
            </Text>
          </ListItem>
          <ListItem id={sections[2].id}>
            <b>CATEGORIES OF PERSONAL DATA WE COLLECT</b>
            <Text>
              We collect data you give us voluntarily (for example, email address, name, date of
              birth, gender). We also collect data automatically (for example, your IP address).
            </Text>
            <DropdownSection id="section_two">
              <Container>
                <NumberList>
                  <ListItem>
                    <b>Data you give us</b>
                    <Text>
                      We may ask you to provide information about yourself when you register for
                      and/or use the Service. This information includes: email address, name,
                      gender, date of birth, place of birth, and time of birth. We may also ask you
                      to provide the date of birth, place of birth, and time of birth of your
                      partner.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <b>Data provided by third parties</b>
                    <Text>
                      When you use sign in with Apple to register an account in the Service, we get
                      personal data from your Apple ID account. This data may include, in
                      particular, your name and verified email address. You may choose to share your
                      real email address or an anonymous one that uses the private email relay
                      service. Apple will show you their detailed privacy information on the sign in
                      with Apple screen. Find more about sign with Apple
                      <Link
                        href="https://support.apple.com/en-ng/HT208650"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        here
                      </Link>
                      .
                    </Text>
                    <Text>
                      When you decide to log in using Facebook, we get personal data from your
                      Facebook account. This includes your profile image, name, and Facebook ID.
                      Unless you opt out on the Facebook Login screen, we will also collect other
                      data, such as email address.
                    </Text>
                    <Text>
                      For more information, please refer to the Facebook{' '}
                      <Link
                        href="https://developers.facebook.com/docs/permissions/reference"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Permissions Reference
                      </Link>{' '}
                      (describes the categories of information, which Facebook may share with third
                      parties and the set of requirements) and to the Facebook{' '}
                      <Link
                        href="https://www.facebook.com/about/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Data policy
                      </Link>
                      . In addition, Facebook lets you control the choices you made when connecting
                      your Facebook profile to the Website on their{' '}
                      <Link
                        href="https://www.facebook.com/settings?tab=applications"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Apps and Websites page
                      </Link>
                      .
                    </Text>
                    <Text>
                      When you decide to log in using Google, we receive personal data from your
                      Google Account: name, email address, profile picture associated with your
                      Google Account. You can revoke access provided to us on{' '}
                      <Link
                        href="https://myaccount.google.com/permissions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Apps Permissions page
                      </Link>
                      . To know more about how Google processes your data, visit its{' '}
                      <RouteLink to={BASE_ROUTES.PRIVACY_POLICY}>Privacy Policy</RouteLink>.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <b>Data we collect automatically:</b>
                    <Container>
                      <NumberList>
                        <ListItem>
                          <b>Data about how you found us</b>
                          <Text>
                            We collect data about your referring app or URL (that is, the app or
                            place on the Web where you were when you tapped/clicked on our ad).
                          </Text>
                        </ListItem>
                        <ListItem>
                          <b>Device and Location data</b>
                          <Text>
                            We collect data from your mobile device. Examples of such data include:
                            language settings, IP address, time zone, type and model of a device,
                            device settings, operating system, Internet service provider, mobile
                            carrier, hardware ID, and Facebook ID. We also record the ads in our
                            Website with which you interact (and the Internet links to which those
                            ads lead).
                          </Text>
                        </ListItem>
                        <ListItem>
                          <b>Usage data</b>
                          <Text>
                            We record how you interact with our Service. For example, we log what
                            pages you have viewed, the features and content you interact with, how
                            often you use the Website, how long you are on the Website, your
                            purchases.
                          </Text>
                        </ListItem>
                        <ListItem>
                          <b>Advertising IDs</b>
                          <Text>
                            We collect your Apple Identifier for Advertising (“IDFA”), Identifier
                            for Vendor (“IDFV”) or Google Advertising ID (“AAID”) (depending on the
                            operating system of your device) when you access our Website from a
                            mobile device. You can typically reset these numbers through the
                            settings of your device’s operating system (but we do not control this).
                          </Text>
                        </ListItem>
                        <ListItem>
                          <b>Transaction data</b>
                          <Text>
                            When you make payments through the Service, you need to provide
                            financial account data, such as your credit card number, to our
                            third-party service providers. We do not collect or store full credit
                            card number data, though we may receive credit card-related data, data
                            about the transaction, including: date, time and amount of the
                            transaction, the type of payment method used.
                          </Text>
                        </ListItem>
                        <ListItem>
                          <b>Cookies</b>
                          <Text>
                            A cookie is a small text file that is stored on a user&apos;s computer
                            for record-keeping purposes. Cookies can be either session cookies or
                            persistent cookies. A session cookie expires when you close your browser
                            and is used to make it easier for you to navigate our Service. A
                            persistent cookie remains on your hard drive for an extended period of
                            time. We also use tracking pixels that set cookies to assist with
                            delivering online advertising.
                          </Text>
                          <Text>
                            Cookies are used, in particular, to automatically recognize you the next
                            time you visit our website. As a result, the information, which you have
                            earlier entered in certain fields on the website may automatically
                            appear the next time when you use our Service. Cookie data will be
                            stored on your device and most of the times only for a limited time
                            period.
                          </Text>
                        </ListItem>
                      </NumberList>
                    </Container>
                  </ListItem>
                </NumberList>
              </Container>
            </DropdownSection>
          </ListItem>
          <ListItem id={sections[3].id}>
            <b>FOR WHAT PURPOSES WE PROCESS YOUR PERSONAL DATA</b>
            <Container>
              <Text>We process your personal data:</Text>
              <NumberList>
                <ListItem>
                  <b>To provide our Service</b>
                  <Text>
                    This includes enabling you to use the Service in a seamless manner and
                    preventing or addressing Service errors or technical issues. As a result of such
                    processing, we will use your email, for example, for sending you the report.
                  </Text>
                  <DropdownSection id="section_three-one">
                    <Text>
                      To host personal data and enable our Service to operate and be distributed we
                      use <b>Amazon Web Services</b>, which is a hosting and backend service
                      provided by Amazon.
                    </Text>
                  </DropdownSection>
                </ListItem>
                <ListItem>
                  <b>To customize your experience</b>
                  <Text>
                    We process your personal data, such as age, gender, date of birth, to adjust the
                    content of the Service and provide content tailored to your personal
                    preferences.
                  </Text>
                </ListItem>
                <ListItem>
                  <b>To provide you with customer support</b>
                  <Text>
                    We process your personal data to respond to your requests for technical support,
                    Service information or to any other communication you initiate. For this
                    purpose, we may send you, for example, notifications or emails about, the
                    performance of our Service, security, payment transactions, notices regarding
                    our <RouteLink to={BASE_ROUTES.TERMS}>Terms and Conditions of Use</RouteLink> or
                    this <RouteLink to={BASE_ROUTES.TERMS}>Privacy Policy</RouteLink>.
                  </Text>
                </ListItem>
                <ListItem>
                  <b>To communicate with you regarding your use of our Service</b>
                  <Text>
                    We communicate with you, for example, by emails. These may include for example,
                    emails with information about the Service. To opt out of receiving emails, you
                    need to follow the instructions in the footer of the emails.
                  </Text>
                  <DropdownSection id="section_three-four">
                    <Text>
                      The services that we use for these purposes may collect data concerning the
                      date and time when the message was viewed by our Service’s users, as well as
                      when they interacted with it, such as by tapping/clicking on links included in
                      the message.
                    </Text>
                    <Text>
                      We use Zendesk ticketing system to handle customer inquiries. When you send us
                      inquiries via contact form or via email, we will store the details provided by
                      you via Zendesk ticketing system, which enables us to track, prioritize and
                      quickly solve your requests.{' '}
                      <Link href="https://www.zendesk.com/company/customers-partners/privacy-policy/">
                        Privacy Policy
                      </Link>
                      .
                    </Text>
                  </DropdownSection>
                </ListItem>
                <ListItem>
                  <b>To research and analyze your use of the Service</b>
                  <Text>
                    This helps us to better understand our business, analyze our operations,
                    maintain, improve, innovate, plan, design, and develop the Service and our new
                    products. We also use such data for statistical analysis purposes, to test and
                    improve our offers. This enables us to better understand what categories of
                    users use our Services. As a consequence, we often decide how to improve the
                    Service based on the results obtained from this processing.
                  </Text>
                  <DropdownSection id="section_three-five">
                    <Text>
                      We use <b>Facebook Analytics</b>, which is a service provided by Facebook that
                      allows us to use different analytical tools. On Facebook Analytics we get, in
                      particular, aggregated demographics and insights on how many people visit our
                      Website, how often users make purchases, and other interactions. Lean more
                      about Facebook’s approach to data from its{' '}
                      <Link href="https://www.facebook.com/about/privacy/">Privacy Policy</Link>.
                    </Text>
                    <Text>
                      <b>Amplitude</b> is an analytics service provided by Amplitude Inc. We use
                      this tool to understand how customers use our Service. Amplitude collects
                      various technical information, in particular, time zone, type of device
                      (phone, tablet or laptop), unique identifiers (including advertising
                      identifiers). Amplitude also allows us to track various interactions that
                      occur in our Website. As a result, Amplitude helps us to decide what features
                      we should focus on. Amplitude provides more information on how they process
                      data in its <Link href="https://amplitude.com/privacy">Privacy Policy</Link>.
                    </Text>
                    <Text>
                      To analyse how visitors use our Service and to measure effectiveness of some
                      ads we use <b>Google Analytics</b>, a web analysis program of Google. In order
                      to provide us with analytics, Google Analytics places cookies on your device.
                      On Google Analytics we get, in particular, aggregated information on the data
                      you enter on our Website and users’ interactions within the Website. Google
                      allows you to influence the collection and processing of information generated
                      by the Google, in particular, by installing a browser plug-in, available{' '}
                      <Link href="https://tools.google.com/dlpage/gaoptout/">here</Link>. You can
                      read more about how Google uses information{' '}
                      <Link href="https://policies.google.com/technologies/partner-sites?hl=en">
                        here
                      </Link>
                      .
                    </Text>
                    <Text>
                      To perform our App analysis, we also use <b>Tableau</b>, a company providing
                      interactive data visualization products focused on business intelligence{' '}
                      <Link href="https://www.tableau.com/privacy">
                        Tableau Software Privacy Policy
                      </Link>
                      .
                    </Text>
                  </DropdownSection>
                </ListItem>
                <ListItem>
                  <b>To send you marketing communications</b>
                  <Text>
                    We process your personal data for our marketing campaigns. As a result, you will
                    receive information about our products, such as, for example, special offers or
                    new features and products available on the Website. We may show you
                    advertisements on our Website, and send you emails for marketing purposes. If
                    you do not want to receive marketing emails from us, you can unsubscribe
                    following instructions in the footer of the marketing emails.
                  </Text>
                </ListItem>
                <ListItem>
                  <b>To personalize our ads</b>
                  <Text>
                    We and our partners use your personal data to tailor ads and possibly even show
                    them to you at the relevant time. For example, if you visited our Website, you
                    might see ads of our products in your Facebook’s feed.
                  </Text>
                  <Text>
                    <b>How to opt out or influence personalized advertising</b>
                  </Text>
                  <Text>
                    <b>iOS</b>: On your iPhone or iPad, go to Settings &gt; Privacy &gt; Apple
                    Advertising and deselect Personalized Ads.
                  </Text>
                  <Text>
                    <b>Android</b>: To opt-out of ads on an Android device, go to Settings &gt;
                    Privacy &gt; Ads and enable Opt out of Ads personalization. In addition, you can
                    reset your advertising identifier in the same section (this also may help you to
                    see less of personalized ads). To learn even more about how to affect
                    advertising choices on various devices, please look at the information available{' '}
                    <Link href="https://www.networkadvertising.org/mobile-choice">here</Link>.
                  </Text>
                  <Text>
                    <b>macOS</b>: On your MacBook, you can disable personalized ads: go to System
                    Preferences &gt; Security & Privacy &gt; Privacy, select Apple Advertising, and
                    deselect Personalized Ads.
                  </Text>
                  <Text>
                    <b>Windows</b>: On your laptop running Windows 10, you shall select Start &gt;
                    Settings &gt; Privacy and then turn off the setting for Let apps use advertising
                    ID to make ads more interesting to you based on your app activity. If you have
                    other Windows version, please follow the steps{' '}
                    <Link href="https://account.microsoft.com/privacy/ad-settings/">here</Link>
                  </Text>
                  <Text>
                    To learn even more about how to affect advertising choices on various devices,
                    please look at the information available{' '}
                    <Link href="https://www.networkadvertising.org/mobile-choice">here</Link>.
                  </Text>
                  <Text>
                    In addition, you may get useful information and opt out of some interest-based
                    advertising, by visiting the following links:
                  </Text>
                  <UnorderedList>
                    <ListItem>
                      Network Advertising Initiative –{' '}
                      <Link href="http://optout.networkadvertising.org/">
                        http://optout.networkadvertising.org/
                      </Link>
                    </ListItem>
                    <ListItem>
                      Digital Advertising Alliance –{' '}
                      <Link href="http://optout.aboutads.info/">http://optout.aboutads.info/</Link>
                    </ListItem>
                    <ListItem>
                      Digital Advertising Alliance (Canada) –{' '}
                      <Link href="http://youradchoices.ca/choices">
                        http://youradchoices.ca/choices
                      </Link>
                    </ListItem>
                    <ListItem>
                      Digital Advertising Alliance (EU) –{' '}
                      <Link href="http://www.youronlinechoices.com/">
                        http://www.youronlinechoices.com/
                      </Link>
                    </ListItem>
                    <ListItem>
                      DAA AppChoices page –{' '}
                      <Link href="http://www.aboutads.info/appchoices">
                        http://www.aboutads.info/appchoices
                      </Link>
                    </ListItem>
                  </UnorderedList>
                  <Text>
                    <b>Browsers</b>: It is also may be possible to stop your browser from accepting
                    cookies altogether by changing your browser’s cookie settings. You can usually
                    find these settings in the “options” or “preferences” menu of your browser. The
                    following links may be helpful, or you can use the “Help” option in your
                    browser.
                  </Text>
                  <UnorderedList>
                    <ListItem>
                      <Link href="http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies#ie=ie-10">
                        Cookie settings in Internet Explorer
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="http://support.mozilla.com/en-US/kb/Cookies">
                        Cookie settings in Firefox
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666">
                        Cookie settings in Chrome
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666">
                        Cookie settings in Safari web
                      </Link>{' '}
                      and
                      <Link href="http://support.apple.com/kb/HT1677">iOS</Link>
                    </ListItem>
                  </UnorderedList>
                  <Text>
                    Google allows its users to{' '}
                    <Link href="https://adssettings.google.com/authenticated?hl=ru">
                      opt out of Google’s personalized ads
                    </Link>{' '}
                    and to{' '}
                    <Link href="https://tools.google.com/dlpage/gaoptout/">
                      prevent their data from being used by Google Analytics
                    </Link>
                    .
                  </Text>
                  <DropdownSection id="section_three-seven">
                    <Text>
                      We value your right to influence the ads that you see, thus we are letting you
                      know what service providers we use for this purpose and how some of them allow
                      you to control your ad preferences.
                    </Text>
                    <Text>
                      We use <b>Facebook pixel</b> on the Website. Facebook pixel is a code placed
                      on the Website collecting data that helps us track conversions from Facebook
                      ads, build targeted audience and remarket to people who have taken some action
                      on the Website (for example, purchased a meal plan).
                    </Text>
                    <Text>
                      We use <b>Facebook Ads Manager</b> together with{' '}
                      <b>Facebook Custom Audience</b>, which allows us to choose audiences that will
                      see our ads on Facebook or other Facebook’s products (for example, Instagram).
                      Through Facebook Custom Audience we may create a list of users with certain
                      sets of data, such as an IDFA, choose users that have completed certain
                      actions in the Service (for example, visited certain sections of the Website).
                      As a result, we may ask Facebook to show some ads to a particular list of
                      users. As a result, more of our ads may show up while you are using Facebook
                      or other Facebook’s products (for example, Instagram). You may learn how to
                      opt out of advertising provided to you through Facebook Custom Audience{' '}
                      <Link href="https://www.facebook.com/help/568137493302217">here</Link>.
                    </Text>
                    <Text>
                      Facebook also allows its users to influence the types of ads they see on
                      Facebook. To find how to control the ads you see on Facebook, please go{' '}
                      <Link href="https://www.facebook.com/help/146952742043748?helpref=related">
                        here
                      </Link>{' '}
                      or adjust your ads settings on{' '}
                      <Link href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">
                        Facebook
                      </Link>
                      .
                    </Text>
                    <Text>
                      <b>Google Ads</b> is an ad delivery service provided by Google that can
                      deliver ads to users. In particular, Google allows us to tailor the ads in a
                      way that they will appear, for example, only to users that have conducted
                      certain actions with our Website (for example, show our ads to users who have
                      made a purchase). Some other examples of events that may be used for tailoring
                      ads include, in particular, visiting our Website. Google allows its users to{' '}
                      <Link href="https://adssettings.google.com/authenticated?hl=ru">
                        opt out of Google’s personalized ads
                      </Link>{' '}
                      and to
                      <Link href="https://tools.google.com/dlpage/gaoptout/">
                        prevent their data from being used by Google Analytics
                      </Link>
                      .
                    </Text>
                    <Text>
                      <b>Google AdSense</b> is an ad delivery service provided by Google. Google
                      AdSense enables publishers to monetize content online. AdSense selects ads
                      based on the content of the website and the website&apos;s audience. To know
                      more about how Google processes your data, visit its
                      <Link href="https://policies.google.com/technologies/partner-sites?hl=en">
                        Privacy Policy
                      </Link>
                      .
                    </Text>
                    <Text>
                      We also use <b>Snapchat Advertising Platform</b> together with{' '}
                      <b>Snapchat Audience Based Match</b>, which is an ad delivery service provided
                      by Snapchat that can link the activity of some users of our Website with the
                      Snapchat advertising network and show some of our ads to them. As a result,
                      you may see more of ads on Snapchat in case you use our Website. Snapchat
                      allows you to{' '}
                      <Link href="https://support.snapchat.com/en-US/a/advertising-preferences">
                        Opt Out
                      </Link>{' '}
                      of their audience based ads.{' '}
                      <Link href="https://www.snap.com/en-US/privacy/privacy-policy/">
                        Privacy Policy
                      </Link>
                      .
                    </Text>
                    <Text>
                      <b>The Snap Pixel</b> is a piece of JavaScript code that helps Advertisers
                      measure the cross-device impact of Campaigns. Advertisers will be able to see
                      how many Snapchatters take action on their website(s) after seeing their Ad.
                      Snapchat allows you to{' '}
                      <Link href="https://support.snapchat.com/en-US/a/advertising-preferences">
                        Opt Out
                      </Link>{' '}
                      of their audience based ads.{' '}
                      <Link href="https://www.snap.com/en-US/privacy/privacy-policy/">
                        Privacy Policy
                      </Link>
                      .
                    </Text>
                    <Text>
                      <b>TikTok Ads</b> is the service provided by TikTok that can deliver ads to
                      its users. The ads can be tailored to specific categories of users (for
                      instance, based on their geographical location).
                      <Link href="https://ads.tiktok.com/i18n/official/policy/privacy">
                        TikTok’s Privacy Policy
                      </Link>
                      .
                    </Text>
                    <Text>
                      We also use <b>Twitter Ads</b> provided by Twitter to deliver advertising.
                      Twitter Ads allows us to choose specific audiences based on geographic areas
                      or user’s interests. As a result, we may ask Twitter to deliver our ads to
                      certain list of users. Twitter allows you{' '}
                      <Link href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads">
                        to opt-out its internet-based advertising
                      </Link>
                      . <Link href="https://twitter.com/en/privacy">Privacy Policy</Link>.
                    </Text>
                    <Text>
                      We use <b>Pinterest Ads</b> to deliver group-based advertisements. For
                      example, you may see our ads if you are interested in specific services,
                      information, or offers.{' '}
                      <Link href="https://policy.pinterest.com/en/privacy-policy">
                        Pinterest Privacy Policy
                      </Link>
                      .
                    </Text>
                  </DropdownSection>
                </ListItem>
                <ListItem>
                  <b>To process your payments</b>
                  <Text>
                    We provide paid products and/or services within the Service. For this purpose,
                    we use third-party services for payment processing (for example, payment
                    processors). As a result of this processing, you will be able to make a payment
                    and we will be notified that the payment has been made.
                  </Text>
                  <Text>
                    We will not store or collect your payment card details ourselves. This
                    information will be provided directly to our third-party payment processors.
                  </Text>
                  <Text>
                    To enable the purchase and to process your payments we use <b>PayPal</b> and{' '}
                    <b>Solid</b>, payment processing providers.
                  </Text>
                </ListItem>
                <ListItem>
                  <b>To enforce our Terms and Conditions of Use and to prevent and combat fraud</b>
                  <Text>
                    We use personal data to enforce our agreements and contractual commitments, to
                    detect, prevent, and combat fraud. As a result of such processing, we may share
                    your information with others, including law enforcement agencies (in particular,
                    if a dispute arises in connection with our{' '}
                    <RouteLink to={BASE_ROUTES.TERMS}>Terms and Conditions of Use</RouteLink>
                    ).
                  </Text>
                </ListItem>
                <ListItem>
                  <b>To comply with legal obligations</b>
                  <Text>
                    We may process, use, or share your data when the law requires it, in particular,
                    if a law enforcement agency requests your data by available legal means.
                  </Text>
                </ListItem>
              </NumberList>
            </Container>
          </ListItem>
          <ListItem id={sections[4].id}>
            <b>UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA</b>
            <Text>
              In this section, we are letting you know what legal basis we use for each particular
              purpose of processing. For more information on a particular purpose, please refer to{' '}
              <Link href={sections[3].href}>Section 3</Link>. This section applies only to EEA-based
              users.
            </Text>
            <Container>
              <Text>We process your personal data under the following legal bases:</Text>
              <NumberList>
                <ListItem>
                  to perform our contract with you;
                  <Text>Under this legal basis we:</Text>
                  <UnorderedList>
                    <ListItem>
                      Provide our Service (in accordance with our Terms and Conditions of Use)
                    </ListItem>
                    <ListItem>Customize your experience</ListItem>
                    <ListItem>Provide you with customer support</ListItem>
                    <ListItem>Communicate with you regarding your use of our Service</ListItem>
                    <ListItem>Process your payments</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>
                  for our (or others&apos;) legitimate interests, unless those interests are
                  overridden by your interests or fundamental rights and freedoms that require
                  protection of personal data;
                  <Text>We rely on legitimate interests:</Text>
                  <UnorderedList>
                    <ListItem>
                      to communicate with you regarding your use of our Service
                      <Text>
                        This includes, for example, sending you email to notify you that we have
                        released a new product or reminding to finish a purchase. The legitimate
                        interest we rely on for this purpose is our interest to encourage you to use
                        our Service more often.
                      </Text>
                    </ListItem>
                    <ListItem>
                      to research and analyze your use of the Service
                      <Text>
                        Our legitimate interest for this purpose is our interest in improving our
                        Service so that we understand users’ preferences and are able to provide you
                        with a better experience (for example, to make the use of the Website easier
                        and more enjoyable, or to introduce and test new features).
                      </Text>
                    </ListItem>
                    <ListItem>
                      to send you marketing communications
                      <Text>
                        The legitimate interest we rely on for this processing is our interest to
                        promote our Service, including new products and special offers, in a
                        measured and appropriate way.
                      </Text>
                    </ListItem>
                    <ListItem>
                      to personalize our ads
                      <Text>
                        The legitimate interest we rely on for this processing is our interest to
                        promote our Service in a reasonably targeted way.
                      </Text>
                    </ListItem>
                    <ListItem>
                      to enforce our{' '}
                      <RouteLink to={BASE_ROUTES.TERMS}>Terms and Conditions of Use</RouteLink> and
                      to prevent and combat fraud
                      <Text>
                        Our legitimate interests for this purpose are enforcing our legal rights,
                        preventing and addressing fraud and unauthorised use of the Service,
                        non-compliance with our{' '}
                        <RouteLink to={BASE_ROUTES.TERMS}>Terms and Conditions of Use</RouteLink>.
                      </Text>
                    </ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>to comply with legal obligations.</ListItem>
              </NumberList>
            </Container>
          </ListItem>
          <ListItem id={sections[5].id}>
            <b>WITH WHOM WE SHARE YOUR PERSONAL DATA</b>
            <Text>
              We share information with third parties that help us operate, provide, improve,
              integrate, customize, support, and market our Service. We may share some sets of
              personal data, in particular, for purposes and with parties indicated in{' '}
              <Link href={sections[3].href}>Section 3</Link> of this Privacy Policy. The types of
              third parties we share information with include, in particular:
            </Text>
            <Container>
              <NumberList>
                <ListItem>
                  <b>Service providers</b>
                  <Text>
                    We share personal data with third parties that we hire to provide services or
                    perform business functions on our behalf, based on our instructions. We may
                    share your personal information with the following types of service providers:
                  </Text>
                  <UnorderedList>
                    <ListItem>cloud storage providers (Amazon)</ListItem>
                    <ListItem>
                      data analytics providers (Facebook, Amplitude, Tableau, Google)
                    </ListItem>
                    <ListItem>measurement partners</ListItem>
                    <ListItem>communication service providers (Zendesk)</ListItem>
                    <ListItem>
                      marketing partners (in particular, social media networks, marketing agencies,
                      email delivery services; Facebook, Google, Snapchat, TikTok, Twitter,
                      Pinterest)
                    </ListItem>
                    <ListItem>payment service providers (PayPal, Solid)</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>
                  <b>Law enforcement agencies and other public authorities</b>
                  <Text>
                    We may use and disclose personal data to enforce our{' '}
                    <RouteLink to={BASE_ROUTES.TERMS}>Terms and Conditions of Use</RouteLink>, to
                    protect our rights, privacy, safety, or property, and/or that of our affiliates,
                    you or others, and to respond to requests from courts, law enforcement agencies,
                    regulatory agencies, and other public and government authorities, or in other
                    cases provided for by law.
                  </Text>
                </ListItem>
                <ListItem>
                  <b>Third parties as part of a merger or acquisition</b>
                  <Text>
                    As we develop our business, we may buy or sell assets or business offerings.
                    Customers’ information is generally one of the transferred business assets in
                    these types of transactions. We may also share such information with any
                    affiliated entity (e.g. parent company or subsidiary) and may transfer such
                    information in the course of a corporate transaction, such as the sale of our
                    business, a divestiture, merger, consolidation, or asset sale, or in the
                    unlikely event of bankruptcy.
                  </Text>
                </ListItem>
              </NumberList>
            </Container>
          </ListItem>
          <ListItem id={sections[6].id}>
            <b>HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS</b>
            <Text>To be in control of your personal data, you have the following rights:</Text>
            <Text>
              <b>Accessing / reviewing / updating / correcting your personal data</b>. You may
              review, edit, or change the personal data that you had previously provided on the
              Website.
            </Text>
            <Text>
              <b>Deleting your personal data</b>. You can request erasure of your personal data as
              permitted by law.
            </Text>
            <Text>
              When you request deletion of your personal data, we will use reasonable efforts to
              honor your request. In some cases, we may be legally required to keep some of the data
              for a certain time; in such event, we will fulfill your request after we have complied
              with our obligations.
            </Text>
            <Text>
              <b>Objecting to or restricting the use of your personal data</b>. You can ask us to
              stop using all or some of your personal data or limit our use thereof.
            </Text>
            <Wrapper>
              <Text>
                <u>
                  <b>Additional information for EEA-based users:</b>
                </u>
              </Text>
              <Text>
                If you are based in the EEA, you have the following rights in addition to the above:
              </Text>
              <Text>
                <b>The right to lodge a complaint with supervisory authority</b>. We would love you
                to contact us directly, so we could address your concerns. Nevertheless, you have
                the right to lodge a complaint with a competent data protection supervisory
                authority, in particular in the EU Member State where you reside, work or where the
                alleged infringement has taken place.
              </Text>
              <Text>
                <b>The right to data portability</b>. If you wish to receive your personal data in a
                machine-readable format, you can send respective request to us as described below.
              </Text>
            </Wrapper>
            <Text>
              To exercise any of your privacy rights, please send a request to{' '}
              <Link href={`mailto: ${config.mail}`}>Support center</Link>.
            </Text>
          </ListItem>
          <ListItem id={sections[7].id}>
            <b>AGE LIMITATION</b>
            <Text>
              We do not knowingly process personal data from persons under 16 years of age. If you
              learn that anyone younger than 16 has provided us with personal data, please contact
              us.
            </Text>
          </ListItem>
          <ListItem id={sections[8].id}>
            <b>INTERNATIONAL DATA TRANSFERS</b>
            <Text>
              We may transfer personal data to countries other than the country in which the data
              was originally collected in order to provide the Service set forth in the{' '}
              <RouteLink to={BASE_ROUTES.TERMS}>Terms and Conditions of Use</RouteLink> and for
              purposes indicated in this Privacy Policy. If these countries do not have the same
              data protection laws as the country in which you initially provided the information,
              we deploy special safeguards.
            </Text>
            <Text>
              In particular, if we transfer personal data originating from the EEA to countries with
              not adequate level of data protection, we use one of the following legal bases: (i)
              Standard Contractual Clauses approved by the European Commission (details available{' '}
              <Link href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en">
                here
              </Link>
              ), or (ii) the European Commission adequacy decisions about certain countries (details
              available{' '}
              <Link href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en">
                here
              </Link>
              ).
            </Text>
          </ListItem>
          <ListItem id={sections[9].id}>
            <b>CHANGES TO THIS PRIVACY POLICY</b>
            <Text>
              We may modify this Privacy Policy from time to time. If we decide to make material
              changes to this Privacy Policy, you will be notified by available means such as email
              and will have an opportunity to review the revised Privacy Policy. By continuing to
              access or use the Service after those changes become effective, you agree to be bound
              by the revised Privacy Policy.
            </Text>
          </ListItem>
          <ListItem id={sections[10].id}>
            <b>CALIFORNIA PRIVACY RIGHTS</b>
            <Text>
              California’s Shine the Light law gives California residents the right to ask companies
              once a year what personal information they share with third parties for those third
              parties&apos; direct marketing purposes. Learn more about what is considered to be{' '}
              <Link href="http://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CIV&sectionNum=1798.81.5">
                personal information under the statute
              </Link>
              .
            </Text>
            <Text>
              To obtain this information from us, please send an email message to{' '}
              <Link href={`mailto: ${config.mail}`}>Support center</Link> which includes “Request
              for California Privacy Information” on the subject line and your state of residence
              and email address in the body of your message. If you are a California resident, we
              will provide the requested information to you at your email address in response.
            </Text>
          </ListItem>
          <ListItem id={sections[11].id}>
            <b>DATA RETENTION</b>
            <Text>
              We will store your personal data for as long as it is reasonably necessary for
              achieving the purposes set forth in this Privacy Policy (including providing the
              Service to you). We will also retain and use your personal data as necessary to comply
              with our legal obligations, resolve disputes, and enforce our agreements.
            </Text>
          </ListItem>
          <ListItem id={sections[12].id}>
            <b>HOW “DO NOT TRACK” REQUESTS ARE HANDLED</b>
            <Text>
              Except as otherwise stipulated in this Privacy Policy, this Website does not support
              “Do Not Track” requests. To determine whether any of the third-party services it uses
              honor the “Do Not Track” requests, please read their privacy policies.
            </Text>
          </ListItem>
          <ListItem id={sections[13].id}>
            <b>CONTACT US</b>
            <Text>
              You may contact us at any time for details regarding this Privacy Policy and its
              previous versions. For any questions concerning your account or your personal data
              please contact us at <Link href={`mailto: ${config.mail}`}>Support center</Link>.
            </Text>
          </ListItem>
        </NumberList>
        <Text>Effective as of: {config.dateOfTerms}</Text>
      </div>
    </>
  );
};

export default PrivacyPolicy;
