export * from './Payment';
export * from './Zodiac';
export * from './Quiz';
export * from './Products';

export enum LoadingStatuses {
  IDLE = 1,
  PENDING = 2,
  FULFILLED,
  FAILED,
}
