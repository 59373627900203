import React from 'react';
import { useFaq } from './useFaq';
import { Container, MainTitle } from './styled';

const Faq = () => {
  useFaq();

  return (
    <Container>
      <MainTitle>
        FAQ
      </MainTitle>
    </Container>
  );
};

export default Faq;
