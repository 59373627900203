import { useEffect } from 'react';
import { useSplitSystem } from 'src/splitSystem';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { ANALYTICS_NAME } from 'src/common/types';
import { Experiments } from 'src/astromix/experiments';

const useExperimentAnalytic = (name: ANALYTICS_NAME) => {
  const splitSystem = useSplitSystem();
  const experiment = splitSystem.getExperiment(Experiments.NO_PLACE_TIME);

  const { analyticsService } = useAstromixServices();

  useEffect(() => {
    analyticsService.setUserExperimentGroupName(experiment.name, experiment.group);
    analyticsService.setCurrentQuizName(name);
  }, [analyticsService]);
};

export default useExperimentAnalytic;
