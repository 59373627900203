import styled, { keyframes } from 'styled-components';
import ImagedPageSectionButton from '.';

const impulseBtn = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(39, 174, 96,.3), 0 0 0 0 rgba(39, 174, 96,.2)
  } 18% {
    box-shadow: 0 0 0 0 rgba(39, 174, 96,.3), 0 0 0 0 rgba(39, 174, 96,.2)
  } 100% {
    box-shadow:0 0 0 16px rgba(39, 174, 96,0),0 0 0 27px rgba(39, 174, 96,0)
  }
`;

export const ImagedAnimatedPageSectionButton = styled(ImagedPageSectionButton)`
  box-shadow: 0 12px 23px rgba(39, 174, 96, 0.31);
  border-radius: 26px;
  transition: .2s all ease;

  :after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation-name: ${impulseBtn};
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    border-radius: 26px;
  }
`;
