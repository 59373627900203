import React, { FC, ChangeEvent } from 'react';
import { DPIScales } from 'src/common/components/ImagedPageSection';
import { PurchaseProductName } from 'src/common/types';
import { BASE_ROUTES } from 'src/astromix/constants';
import { ReactComponent as Guarantee } from 'src/astromix/assets/images/guarantee.svg';

import webpXl from 'src/astromix/assets/images/payment/payment-xl.webp';
import webpXl2x from 'src/astromix/assets/images/payment/payment-xl-2x.webp';
import pngXl from 'src/astromix/assets/images/payment/payment-xl.png';
import pngXl2x from 'src/astromix/assets/images/payment/payment-xl-2x.png';
import webpMd from 'src/astromix/assets/images/payment/payment-md.webp';
import webpMd2x from 'src/astromix/assets/images/payment/payment-md-2x.webp';
import pngMd from 'src/astromix/assets/images/payment/payment-md.png';
import pngMd2x from 'src/astromix/assets/images/payment/payment-md-2x.png';
import webpXs from 'src/astromix/assets/images/payment/payment-xs.webp';
import webpXs2x from 'src/astromix/assets/images/payment/payment-xs-2x.webp';
import pngXs from 'src/astromix/assets/images/payment/payment-xs.png';
import pngXs2x from 'src/astromix/assets/images/payment/payment-xs-2x.png';

import AdvisoryPlans from './AdvisoryPlans';
import {
  StyledButton,
  MobilePaymentWrapper,
  PaymentWrapper,
  Link,
  ReportDescription,
  ImagedSection,
  SectionInscription,
  CheckboxText,
  BoldText,
  StyledCheckbox,
  Label,
  Possibilities,
} from './styled';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

export type SectionPaymentProps = {
  onGetReport: () => void;
  isLoading: boolean;
  plan: PurchaseProductName;
  setPlan: (value: PurchaseProductName) => void;
  isAgree: boolean;
  setIsAgree: (value: boolean) => void;
  isTimeUp: boolean;
  monthPlan: PurchaseProductName;
};

const SectionPayment: FC<SectionPaymentProps> = ({
  onGetReport,
  isLoading,
  plan,
  setPlan,
  isAgree,
  setIsAgree,
  isTimeUp,
  monthPlan,
}) => (
  <PaymentWrapper>
    <MobilePaymentWrapper>
      <AdvisoryPlans value={plan} handleChange={setPlan} isTimeUp={isTimeUp} monthPlan={monthPlan} />
      <StyledCheckbox
        type="checkbox"
        id="billing_payment_terms"
        checked={isAgree}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setIsAgree(e.target.checked)}
        name="billing_payment_terms"
      />
      <Label htmlFor="billing_payment_terms">
        <CheckboxText>
          I want to use
          {' '}
          <Link to={BASE_ROUTES.PAYMENT_TERMS}>money-back guarantee</Link>
          {' '}
          (find out applicable limitations) and get my money back if report doesn’t work for me
        </CheckboxText>
      </Label>
      <StyledButton onClick={onGetReport} loading={isLoading} disabled={!isAgree} variant="success">
        {isLoading ? 'Processing' : 'Get access now'}
      </StyledButton>
      <Possibilities>Premium access | Personal consultations</Possibilities>
      <ReportDescription>
        By continuing you agree that if you don&apos;t cancel prior to the end of
        {plan === monthPlan
          ? ' the first renewal period' : ' one week introductory offer'}
        , you will automatically be charged
        {plan === monthPlan && ' the full price of'}
        {' '}
        <BoldText> $28.56 every month </BoldText>
        until you cancel in settings. Learn more about cancellation in
        {' '}
        <Link to={BASE_ROUTES.TERMS}>Subscription terms</Link>
        .
      </ReportDescription>
      <SectionInscription icon={<Guarantee />}>Guaranteed security payments</SectionInscription>
      <ImagedSection
        originalUrl={sectionData.originalUrl}
        sources={sectionData.sources}
      />
    </MobilePaymentWrapper>
  </PaymentWrapper>
);

export default SectionPayment;
