import styled from 'styled-components';

export const Root = styled.div`
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(182.59deg, #3E1D76 -3.21%, #56CCF2 117.2%);
  }
`;

export const Content = styled.div`
  position: relative;
`;
