import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';
import ImagedPageSectionInscription, { StyledInscription } from 'src/common/components/ImagedPageSectionInscription';
import ImagedPageSection, { ImagedPageSectionHolder } from 'src/common/components/ImagedPageSection';
import Button from 'src/common/components/Button';
import checkbox from 'src/astromix/assets/images/billing/checkbox.svg';

const sectionWidth = 360;
const sectionHeight = 140;
const sectionTabletWidth = 708;
const sectionTabletHeight = 77;
const sectionDesktopWidth = 708;
const sectionDesktopHeight = 77;

export const ImagedSection = styled(ImagedPageSection)`
  margin: 30px auto 0;
  max-width: 708px;

  @media ${(props) => props.theme.breakpoints.tablet} {
      margin-bottom: 50px;
    }

  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionTabletHeight} / ${sectionTabletWidth} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionDesktopHeight} / ${sectionDesktopWidth} * 100%);
    }
  }
`;

export const PaymentWrapper = styled.div`
  width: 100%;
  padding-top: 40px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding-top: 20px;
  }
`;

export const ReportDescription = styled.p`
  margin: 10px auto 30px;
  max-width: 526px;
  text-align: center;
  font-size: 11px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.inactive};

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 30px;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Link = styled(RLink)`
  text-decoration: underline;
  font-weight: bold;
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SectionInscription = styled(ImagedPageSectionInscription)`
  position: relative;

  
  & ${StyledInscription} {
    color: ${(props) => props.theme.colors.active};

    @media ${(props) => props.theme.breakpoints.tablet} {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const MobilePaymentWrapper = styled.div`
  border: ${(props) => `2px solid ${props.theme.colors.info}`};
  padding: 15px;
  border-radius: 16px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    border: none;
    padding: 0;
  }
`;

export const CheckboxText = styled.p`
  padding-left: 34px;
  color: ${(props) => props.theme.colors.inactive};
  font-size: 11px;
  line-height: 18px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 14px;
  }
`;

export const BoldText = styled.b``;

export const CheckboxTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  margin-bottom: 20px;
  position: relative;
  max-width: 708px;
  margin-left: auto;
  margin-right: auto;
  display: block;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 30px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 24px;
    width: 24px;
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: transparent;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid ${(props) => props.theme.colors.primary};
    border-radius: 3px;
    cursor: pointer;

    @media ${(props) => props.theme.breakpoints.tablet} {
      top: 4px;
    }
  }
`;

export const StyledCheckbox = styled.input`

  &:checked + ${Label}::before {
    background-color: transparent;
    background-image: url(${checkbox});
    border-radius: 3px;
  }
`;

export const Possibilities = styled.p`
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.active};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 18px;
    margin-top: 30px;
  }
`;
