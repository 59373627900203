import React, { useEffect } from 'react';
import Header from 'src/astromix/components/Header';
import { Background } from 'src/astromix/components/Quiz/styled';
import { useQuizStep } from 'src/common/contexts/QuizContext';
import { InformationalStepData } from 'src/astromix/types';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import {
  Button, Container, Image, ImageContainer, Text,
} from './styled';

export type InformationPageProps = { id: string };

const Information = ({ id }: InformationPageProps) => {
  const { back, stepData, next } = useQuizStep(id);

  const { content, analytic } = stepData as InformationalStepData;

  const { analyticsService } = useAstromixServices();

  useEffect(() => {
    analyticsService.trackQuizEvent(analytic.baseEvent, analytic.baseEventProperties);
  }, [analyticsService, analytic]);

  const handleButtonClick = () => next();
  const onBack = () => back();

  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <Background />
      <Container>
        <ImageContainer>
          <Image src={content.image} alt="planet" />
        </ImageContainer>
        <Text dangerouslySetInnerHTML={{ __html: content.text }} />
        <Button onClick={handleButtonClick} variant="secondary">
          Continue
        </Button>
      </Container>
    </>
  );
};

export default Information;
