import React, { useState, useCallback } from 'react';
import Header from 'src/astromix/components/Header';
import PaymentDialog from 'src/common/components/PaymentDialog';
import AdditionalDiscountModal from 'src/astromix/components/AdditionalDiscountModal';
import { LoadingStatuses, PaymentOrderDescription, PaymentType } from 'src/common/types';
import SectionMain from './SectionMain';
import SectionPayment from './SectionPayment';
import SectionFeedback from './SectionFeedback';
import { useBilling } from './useBilling';

const Billing = () => {
  const [isPaymentDialogVisible, setIsPaymentDialogVisible] = useState(false);
  const [isDiscountModalVisible, setIsDiscountModalVisible] = useState(false);

  const {
    price,
    priceLoadingStatus,
    paymentSucceed,
    cardPaymentProcessing,
    paypalPaymentProcessing,
    paymentRetried,
    paymentFailed,
    getPaymentUrls,
    getDiscount,
    onAdditionalDiscountModalOpen,
    advisoryPlan,
    setAdvisoryPlan,
    monthPlan,
    setIsTimeUp,
    isTimeUp,
    isAgree,
    setIsAgree,
  } = useBilling();

  const onGetReport = () => {
    setIsPaymentDialogVisible(true);
  };

  const onPaymentDialogClose = () => {
    setIsPaymentDialogVisible(false);
    onAdditionalDiscountModalOpen();
    setIsDiscountModalVisible(true);
  };

  const onPaymentSuccess = useCallback(
    (orderData: PaymentOrderDescription, paymentType: PaymentType) => {
      setIsPaymentDialogVisible(false);
      paymentSucceed(orderData, paymentType);
    },
    [paymentSucceed],
  );

  const isLoading = priceLoadingStatus === LoadingStatuses.PENDING;

  return (
    <>
      <Header />
      <SectionMain onGetReport={onGetReport} isTimeUp={isTimeUp} isLoading={isLoading} setIsTimeUp={setIsTimeUp} />
      <SectionPayment
        onGetReport={onGetReport}
        isLoading={isLoading}
        plan={advisoryPlan}
        setPlan={setAdvisoryPlan}
        isAgree={isAgree}
        monthPlan={monthPlan}
        setIsAgree={setIsAgree}
        isTimeUp={isTimeUp}
      />
      <SectionFeedback
        onGetReport={onGetReport}
        isLoading={isLoading}
        isAgree={isAgree}
        monthPlan={monthPlan}
        plan={advisoryPlan}
      />
      <PaymentDialog
        onPaymentRetried={paymentRetried}
        onPaymentFailed={paymentFailed}
        onPaypalPaymentProcessing={paypalPaymentProcessing}
        onCardPaymentProcessing={cardPaymentProcessing}
        isOpen={isPaymentDialogVisible}
        onClose={onPaymentDialogClose}
        price={price}
        getPaymentUrls={getPaymentUrls}
        onPaymentSuccess={onPaymentSuccess}
      />
      <AdditionalDiscountModal onGetDiscount={getDiscount} isOpen={isDiscountModalVisible} />
    </>
  );
};

export default Billing;
