import styled from 'styled-components';
import MoneyBack from 'src/astromix/components/MoneyBack';
import Button from 'src/common/components/Button';
import { Link as RLink } from 'react-router-dom';
import ImagedPageInscription, { StyledInscription } from 'src/common/components/ImagedPageSectionInscription';

export const StyledButton = styled(Button)`
  margin: 20px auto;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 24px;
    margin-top:  50px;
    margin-bottom:  50px;
  }
`;

export const MoneyBackGuarantee = styled(MoneyBack)`
  margin: 50px auto;
`;

export const SectionInscription = styled(ImagedPageInscription)`
  position: relative;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 20px;
    margin-bottom: 28px;
  }

  
  & ${StyledInscription} {
    color: ${(props) => props.theme.colors.active};

    @media ${(props) => props.theme.breakpoints.tablet} {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const Address = styled.span`
  color: ${(props) => props.theme.colors.inactive};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  font-size: 10px;
  line-height: 140%;
  width: 212px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 12px;
    width: auto;
  }
`;

export const ReportDescription = styled.p`
  margin-left: auto;
  margin-right: auto;
  max-width: 526px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.active};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 10px;
    line-height: 140%;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const BoldText = styled.b``;

export const Link = styled(RLink)`
  text-decoration: underline;
  font-weight: bold;
`;
