import React from 'react';
import Header from 'src/astromix/components/Header';
import ProgressBar from 'src/common/components/ProgressBar';
import ColorPickerContainer from 'src/astromix/components/Quiz/ColorPickerContainer';
import { useQuizStep } from 'src/common/contexts/QuizContext';
import { ColorPickerStepData } from 'src/astromix/types';
import useColorPicker from './useColorPicker';

export type ColorPickerPageProps = { id: string };

const ColorPicker = ({ id }: ColorPickerPageProps) => {
  const { back, stepData, next } = useQuizStep(id);

  const {
    dataKey,
    content,
    stepOrder,
    totalSteps,
    analytic,
  } = stepData as ColorPickerStepData;

  const { onBack, onAnswerClick } = useColorPicker({
    dataKey,
    back,
    next,
    analytic,
  });

  return (
    <>
      <Header onBack={onBack} />
      <ProgressBar step={stepOrder} totalSteps={totalSteps} />
      <ColorPickerContainer title={content.title} onAnswerClick={onAnswerClick} />
    </>
  );
};

export default ColorPicker;
