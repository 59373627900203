import React from 'react';
import { SplitSystem } from '../SplitSystem';

export const SplitSystemContext = React.createContext<SplitSystem>(null!);

export interface SplitSystemProviderProps {
  children: React.ReactNode;
  splitSystem: SplitSystem;
}

export const SplitSystemProvider = ({ children, splitSystem }: SplitSystemProviderProps) => (
  <SplitSystemContext.Provider value={splitSystem}>{children}</SplitSystemContext.Provider>
);
