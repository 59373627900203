import {
  useEffect, useState, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useCreatePayment } from 'src/common/hooks/useCreatePayment';
import { PaymentOrderDescription, PaymentType, PurchaseProductName } from 'src/common/types';
import { BASE_ROUTES, LANDINGS } from 'src/astromix/constants';
import { joinUrls } from 'src/common/utils/joinUrls';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { useProductPrice } from 'src/common/hooks/useProductPrice';
import { usePaymentStatus } from 'src/astromix/hooks/usePaymentStatus';

export function useBilling() {
  const [advisoryPlan, setAdvisoryPlan] = useState(PurchaseProductName.ASTROMIX_7D_TRIAL_3M_9999);

  const history = useHistory();

  const { getPaymentUrls } = useCreatePayment(advisoryPlan);

  const { analyticsService } = useAstromixServices();

  const {
    cardPaymentProcessing,
    paymentSucceed: reportPaymentSucceed,
    paypalPaymentProcessing,
    paymentRetried,
    paymentFailed,
  } = usePaymentStatus(advisoryPlan);

  const {
    price,
    loadingStatus: priceLoadingStatus,
  } = useProductPrice(PurchaseProductName.ASTROMIX_7D_TRIAL_3M_9999);

  useEffect(() => {
    analyticsService.billingLpOpened();
  }, [analyticsService]);

  const paymentSucceed = useCallback(
    (orderData: PaymentOrderDescription, paymentType: PaymentType) => {
      reportPaymentSucceed(orderData, paymentType);
      history.push(joinUrls(BASE_ROUTES.ASTROMIX, LANDINGS.NUMEROLOGY));
    },
    [history, reportPaymentSucceed, analyticsService],
  );

  const getDiscount = useCallback(() => {
    analyticsService.billingLpGetAdditionalDiscount();
    history.push(joinUrls(BASE_ROUTES.ASTROMIX, LANDINGS.ADDITIONAL_DISCOUNT));
  }, [analyticsService, history]);

  const onAdditionalDiscountModalOpen = () => {
    analyticsService.billingLpAdditionalDiscountPopup();
  };

  return {
    price,
    priceLoadingStatus,
    getPaymentUrls,
    paymentSucceed,
    cardPaymentProcessing,
    paypalPaymentProcessing,
    paymentRetried,
    paymentFailed,
    getDiscount,
    onAdditionalDiscountModalOpen,
    advisoryPlan,
    setAdvisoryPlan,
  };
}
