import {
  LocalStorageIdentifier,
  ConfigType,
  SplitSystemExperiment,
  SplitSystem,
} from 'src/splitSystem';
import { Experiments, Variants } from './enums';

export interface SplitSystemService {
  splitSystem: SplitSystem;
}

export const createExperiment = (config: ConfigType) => {
  const id = new LocalStorageIdentifier();

  const experiment = new SplitSystemExperiment(config, id);

  return experiment;
};

const timeAndPlace = {
  name: Experiments.NO_PLACE_TIME,
  tests: [
    {
      name: Variants.WITH_PLACE_TIME,
      weight: 0.5,
    },
    {
      name: Variants.NO_PLACE_TIME,
      weight: 0.5,
    },
  ],
};

const timeAndPlaceExperiment = createExperiment(timeAndPlace);

const experiments = {
  [timeAndPlaceExperiment.name]: timeAndPlaceExperiment,
};

export const splitSystem = new SplitSystem(experiments);
