import { PurchaseProductName, LoadingStatuses } from 'src/common/types';
import { useCallback, useState } from 'react';

import { UnknownError } from 'src/common/errors';
import useServices from './useServices';
import { useLogger } from './useLogger';

export const usePurchaseReport = (reportType: PurchaseProductName) => {
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.IDLE);
  const [error, setError] = useState('');

  const { purchaseService } = useServices();
  const logger = useLogger();

  const clearState = () => {
    setLoadingStatus(LoadingStatuses.IDLE);
    setError('');
  };

  const purchase = useCallback(async () => {
    if (loadingStatus === LoadingStatuses.PENDING) return;

    clearState();

    try {
      setLoadingStatus(LoadingStatuses.PENDING);
      await purchaseService.purchaseReport(reportType);
      setLoadingStatus(LoadingStatuses.FULFILLED);
    } catch (err) {
      setLoadingStatus(LoadingStatuses.FAILED);
      if (err instanceof Error) {
        logger.error(err);
        setError(err.message);
      } else {
        logger.error(new UnknownError(err));
        setError('Sorry, something went wrong');
      }
    }
  }, [loadingStatus, reportType, purchaseService, logger]);

  return {
    purchase,
    loadingStatus,
    error,
  };
};
