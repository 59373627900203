import React from 'react';
import { PurchaseProductName } from 'src/common/types';
import { PriceTypes } from 'src/astromix/components/Price';
import { ReactComponent as StarIcon } from 'src/astromix/assets/images/star.svg';
import { ReactComponent as LoveIcon } from 'src/astromix/assets/images/heart.svg';
import {
  AdvisoryPlansWrapper,
  Label,
  Input,
  Description,
  Title,
  TotalPrice,
  StyledPrice,
  PriceSection,
  PricePeriod,
  OldPrice,
  BoldText,
  Offer,
  Benefit,
  Discount,
  OfferText,
} from './styled';

interface AdvisoryPlansProps {
  value: Partial<PurchaseProductName>;
  handleChange: (value: Partial<PurchaseProductName>) => void;
  isTimeUp: boolean;
  monthPlan: PurchaseProductName;
}

const AdvisoryPlans = ({
  value, handleChange, isTimeUp, monthPlan,
}: AdvisoryPlansProps) => (
  <AdvisoryPlansWrapper>
    <Input
      type="radio"
      name="advisory-plan"
      id="1-week-plan"
      value={PurchaseProductName.ASTROMIX_1W_999_1M_2856}
      checked={value === PurchaseProductName.ASTROMIX_1W_999_1M_2856}
      onChange={(e) => handleChange(e.target.value as Partial<PurchaseProductName>)}
    />
    <Label htmlFor="1-week-plan">
      <Description>
        <Title>REPORT & 1-week access</Title>
        <TotalPrice>
          Billed
          <BoldText> weekly </BoldText>
          at:
          <BoldText> $9.99</BoldText>
        </TotalPrice>
      </Description>
      <PriceSection>
        <StyledPrice type={PriceTypes.SELECTED}>$1.43</StyledPrice>
        <PricePeriod>per day</PricePeriod>
      </PriceSection>
    </Label>
    <Input
      type="radio"
      name="advisory-plan"
      id="1-month-plan"
      value={monthPlan}
      checked={value === monthPlan}
      onChange={(e) => handleChange(e.target.value as Partial<PurchaseProductName>)}
    />
    <Label htmlFor="1-month-plan">
      <Offer>
        <Benefit>
          <StarIcon />
          <OfferText>Best option</OfferText>
        </Benefit>
        {!isTimeUp && (
          <Discount>
            <LoveIcon />
            <OfferText>-30% OFF</OfferText>
          </Discount>
        )}
      </Offer>
      <Description>
        <Title>REPORT & 1-month access</Title>
        <TotalPrice>
          Billed mothly at:
          <BoldText>{isTimeUp ? ' $28.56 ' : ' $19.99 '}</BoldText>
          {!isTimeUp && <OldPrice>28.56</OldPrice>}
        </TotalPrice>
      </Description>
      <PriceSection>
        <StyledPrice type={PriceTypes.SELECTED}>{isTimeUp ? ' $0.95 ' : ' $0.66 '}</StyledPrice>
        <PricePeriod>per day</PricePeriod>
      </PriceSection>
    </Label>
  </AdvisoryPlansWrapper>
);

export default AdvisoryPlans;
