import { useDispatch } from 'react-redux';
import { setUserInformation } from 'src/astromix/store/userProfile';
import { useEffect } from 'react';
import { QUIZ_ANALYTIC_EVENTS } from 'src/astromix/services/analytics';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';

export default function useAnswer({
  dataKey,
  next,
  back,
  analytic,
}: {
  dataKey: string;
  back: () => Promise<void>;
  next: () => Promise<void>;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
  }
}) {
  const dispatch = useDispatch();

  const { analyticsService } = useAstromixServices();

  useEffect(() => {
    analyticsService.trackQuizEvent(analytic.baseEvent);
  }, [analyticsService, analytic]);

  const onBack = () => back();
  const onAnswerClick = (choice: string) => {
    dispatch(setUserInformation(dataKey, choice));
    next();
  };

  return {
    onBack,
    onAnswerClick,
  };
}
