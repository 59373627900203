import React, { useCallback, useEffect, useState } from 'react';
import { LoadingStatuses } from 'src/common/types';
import { PaymentFailedDialog } from 'src/common/components/PaymentDialog';
import Header from 'src/astromix/components/Header';
import SectionFooter from './SectionFooter';
import SectionHeader from './SectionHeader';
import SectionInfo from './SectionInfo';

import { Content, Root } from '../styled';
import { useTarot } from '../useTarot';

const Tarot = () => {
  const [isFailedDialogVisible, setFailedDialogVisible] = useState(false);

  const {
    getReport, skip, loadingStatus, error, price, priceLoadingStatus,
  } = useTarot();

  useEffect(() => {
    setFailedDialogVisible(!!error);
  }, [error]);

  const onCloseFailedPayment = useCallback(() => {
    setFailedDialogVisible(false);
  }, []);

  const tryAgain = useCallback(() => {
    setFailedDialogVisible(false);
    getReport();
  }, [getReport]);

  const isLoading = loadingStatus === LoadingStatuses.PENDING || priceLoadingStatus === LoadingStatuses.PENDING;

  return (
    <Root>
      <Content>
        <Header variant="secondary" buttonBack={false} />
        <SectionHeader />
        <SectionInfo
          isLoading={isLoading}
          onGetReport={getReport}
          onHandleSkipClick={skip}
          price={price}
        />
        <SectionFooter
          isLoading={isLoading}
          onGetReport={getReport}
          onHandleSkipClick={skip}
        />
        <PaymentFailedDialog
          onTryAgain={tryAgain}
          onClose={onCloseFailedPayment}
          isOpen={isFailedDialogVisible}
        />
      </Content>
    </Root>
  );
};

export default Tarot;
