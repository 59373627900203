import React from 'react';
import ImagedPageSectionButton from 'src/common/components/ImagedPageSectionButton';
import { DPIScales } from 'src/common/components/ImagedPageSection';

import pngXl from 'src/astromix/assets/images/tarot/footer/footer-xl.png';
import pngXl2x from 'src/astromix/assets/images/tarot/footer/footer-xl-2x.png';
import webpXl from 'src/astromix/assets/images/tarot/footer/footer-xl.webp';
import webpXl2x from 'src/astromix/assets/images/tarot/footer/footer-xl-2x.webp';
import pngMd from 'src/astromix/assets/images/tarot/footer/footer-md.png';
import pngMd2x from 'src/astromix/assets/images/tarot/footer/footer-md-2x.png';
import webpMd from 'src/astromix/assets/images/tarot/footer/footer-md.webp';
import webpMd2x from 'src/astromix/assets/images/tarot/footer/footer-md-2x.webp';
import pngXs from 'src/astromix/assets/images/tarot/footer/footer-xs.png';
import pngXs2x from 'src/astromix/assets/images/tarot/footer/footer-xs-2x.png';
import webpXs from 'src/astromix/assets/images/tarot/footer/footer-xs.webp';
import webpXs2x from 'src/astromix/assets/images/tarot/footer/footer-xs-2x.webp';

import {
  buttonTopOffsetTablet,
  buttonTopOffsetDesktop,
  sectionHeightTablet,
  sectionHeightMobile,
  sectionHeight,
  buttonTopOffset,
  ImagedSection,
  Skip,
  StyledAddress,
} from './styled';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

export type SectionInfoProps = {
  onGetReport: () => void;
  onHandleSkipClick: () => void;
  isLoading?: boolean;
}

const SectionFooter = ({
  onGetReport,
  onHandleSkipClick,
  isLoading,
}: SectionInfoProps) => (
  <>
    <ImagedSection
      originalUrl={sectionData.originalUrl}
      sources={sectionData.sources}
    >
      <ImagedPageSectionButton
        loading={isLoading}
        onClick={onGetReport}
        aspectRatio={buttonTopOffset / sectionHeightMobile}
        tabletAspectRatio={buttonTopOffsetTablet / sectionHeightTablet}
        desktopAspectRatio={buttonTopOffsetDesktop / sectionHeight}
      >
        { isLoading ? 'Processing' : 'Get my tarot guide' }
      </ImagedPageSectionButton>
      <Skip role="button" onClick={onHandleSkipClick}>
        Skip my special offer
      </Skip>
    </ImagedSection>
    <StyledAddress variant="light" />
  </>
);

export default SectionFooter;
