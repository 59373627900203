import React from 'react';
import Header from 'src/astromix/components/Header';
import { BASE_ROUTES } from 'src/astromix/constants';
import { config } from 'src/astromix/config';
import {
  Address,
  Container,
  Link,
  ListItem,
  MainTitle,
  NumberList,
  RouteLink,
  Text,
} from 'src/astromix/pages/PrivacyPolicy';
import { useTerms } from './useTerms';

const Terms = () => {
  useTerms();

  return (
    <>
      <Header buttonBack={false} />
      <MainTitle>TERMS AND CONDITIONS OF USE</MainTitle>
      <NumberList>
        <ListItem>
          <b>ACCEPTANCE OF TERMS</b>
          <Container>
            <NumberList>
              <ListItem>
                These Terms and Conditions (the “Terms”) govern the relationship
                between you and Obrio Limited, a legal entity incorporated under
                the laws of the Republic of Cyprus, having its registered office
                at
                {' '}
                <Address title="address" />
                {' '}
                (“we“ “us“ “our“ or the
                “Company“) regarding your use of the Company’s website and other
                services (the “Website” or the “Service”), including all
                textual, graphic, video, music, software and other content
                available through the Service (the “Content”).
              </ListItem>
              <ListItem>
                Your access and use of the Service constitutes your agreement to
                be bound by these Terms, which establish a legally binding
                contractual relationship between you and the Company. For this
                reason, PLEASE READ THE TERMS CAREFULLY BEFORE USING THE
                SERVICE.
              </ListItem>
              <ListItem>
                Please review also our
                {' '}
                <RouteLink to={BASE_ROUTES.PRIVACY_POLICY}>
                  Privacy Policy
                </RouteLink>
                {' '}
                . The terms of the Privacy Policy and other supplemental terms,
                policies or documents that may be posted on the Service from
                time to time are hereby expressly incorporated herein by
                reference. We reserve the right, in our sole discretion, to make
                changes or modifications to these Terms at any time and for any
                reason.
              </ListItem>
              <ListItem>
                Any translation from English version is provided for your
                convenience only. In the event of any difference in meaning or
                interpretation between the English language version of these
                Terms available at
                {' '}
                <RouteLink to={BASE_ROUTES.TERMS}>[LINK]</RouteLink>
                , and any
                translation, the English language version will prevail. The
                original English text shall be the sole legally binding version.
              </ListItem>
              <ListItem>
                Unless otherwise expressly provided herein, we will alert you
                about any changes by updating the “Last updated“ date of these
                Terms and you waive any right to receive specific notice of each
                such change.
              </ListItem>
              <ListItem>
                THESE TERMS CONTAIN IMPORTANT DISCLAIMERS (SECTION 2),
                DISCLAIMERS OF WARRANTIES (SECTION 8), LIMITATION OF LIABILITY
                (SECTION 9), AS WELL AS PROVISIONS THAT WAIVE YOUR RIGHT TO A
                JURY TRIAL, RIGHT TO A COURT HEARING AND RIGHT TO PARTICIPATE IN
                A CLASS ACTION (ARBITRATION AND CLASS ACTION WAIVER). UNLESS YOU
                OPT OUT WITHIN 30 DAYS OF FIRST USE OF OUR SERVICE AS PROVIDED
                FOR IN SECTION 12, ARBITRATION IS THE EXCLUSIVE REMEDY FOR ANY
                AND ALL DISPUTES AND IS MANDATORY EXCEPT AS SPECIFIED BELOW IN
                SECTION 12.
              </ListItem>
              <ListItem>
                IF YOU DO NOT AGREE WITH ANY PART OF THESE TERMS, OR IF YOU ARE
                NOT ELIGIBLE OR AUTHORIZED TO BE BOUND BY THESE TERMS, THEN DO
                NOT ACCESS OR USE THE WEBSITE AND THE SERVICE.
              </ListItem>
            </NumberList>
          </Container>
        </ListItem>
        <ListItem>
          <b>IMPORTANT DISCLAIMERS</b>
          <Container>
            <NumberList>
              <ListItem>
                WE MAKE NO GUARANTEES THAT (I) THE SERVICE WILL MEET YOUR
                REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
                SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED
                FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR
                (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR
                OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE
                WILL MEET YOUR EXPECTATIONS OR WILL PROVIDE ANY BENEFIT.
              </ListItem>
              <ListItem>
                NOT ALL INFORMATION DESCRIBED IN THE APP ARE SUITABLE FOR
                EVERYONE. THE SERVICE IS INTENDED ONLY AS A TOOL WHICH MAY BE
                USEFUL IN ACHIEVING YOUR OVERALL LIFESTYLE GOALS. YOU
                ACKNOWLEDGE THAT IF YOUR ACTIVITIES ENCOURAGED OR INSPIRED BY
                THE SERVICE INVOLVE ANY RISKS, YOU ASSUME THOSE RISKS AND
                UNDERSTAND AND AGREE THAT YOU TAKE FULL RESPONSIBILITY FOR YOUR
                HEALTH, LIFE AND WELL-BEING, AS WELL AS THE HEALTH, LIVES AND
                WELL-BEING OF YOUR FAMILY AND CHILDREN (BORN AND UNBORN, AS
                APPLICABLE), AND ALL DECISIONS NOW OR IN THE FUTURE.
              </ListItem>
              <ListItem>
                INFORMATION PROVIDED THROUGH OUR SERVICE IS FOR INFORMATIONAL
                AND ENTERTAINMENT PURPOSES ONLY. THE SERVICE IS NOT INTENDED TO
                BE A SUBSTITUTE FOR ANY PROFESSIONAL ADVICE, INCLUDING BUT NOT
                LIMITED TO (A) PROFESSIONAL MEDICAL OR PSYCHIATRIC ADVICE,
                DIAGNOSIS, OR TREATMENT, OR (B) PROFESSIONAL FINANCIAL OR
                INVESTMENT ADVICE OR GUIDANCE, OR (C) PROFESSIONAL LEGAL ADVICE.
                NEVER DISREGARD OR DELAY SEEKING PROFESSIONAL MEDICAL ADVICE OR
                OTHER PROFESSIONAL ADVICE. YOUR RELIANCE ON THE INFORMATION
                PROVIDED BY THE SERVICE IS SOLELY AT YOUR OWN ELECTION OR
                CHOICE. ANY AND ALL DECISIONS THAT YOU MAKE THAT ARE BASED IN
                WHOLE OR IN PART UPON INFORMATION PROVIDED BY THE SERVICE WILL
                BE YOUR SOLE AND EXCLUSIVE RESPONSIBILITY.
              </ListItem>
            </NumberList>
          </Container>
        </ListItem>
        <ListItem>
          <b>ACCOUNT REGISTRATION</b>
          <Container>
            <NumberList>
              <ListItem>
                In order to use certain features of the Service, you may need to
                register an account (“Account“) and provide certain information
                about yourself as prompted by the registration form.
              </ListItem>
              <ListItem>
                If you register an Account, you represent and warrant to the
                Company that: (i) all required registration information you
                submit is truthful and accurate; (ii) you will maintain the
                accuracy of such information; and (iii) your use of the Service
                does not violate any applicable law or regulation or these
                Terms. Otherwise, the Service may not operate correctly, and we
                may not be able to contact you with important notices.
              </ListItem>
              <ListItem>
                The Service is not intended to be used by individuals under age
                of 18. You hereby represent and warrant to the Company that you
                meet the foregoing qualification. All users who are minors in
                the jurisdiction in which they reside (generally under the age
                of 18) must have the permission of, and be directly supervised
                by, their parent or guardian to use the Service. If you are a
                minor, you must have your parent or guardian read and agree to
                these Terms prior to you using the Service.
              </ListItem>
              <ListItem>
                The Company reserves the right to suspend or terminate your
                Account, or your access to the Service, with or without notice
                to you, in the event that you breach these Terms.
              </ListItem>
              <ListItem>
                You are responsible for maintaining the confidentiality of your
                Account login information and are fully responsible for all
                activities that occur under your Account. You agree to
                immediately notify the Company of any unauthorized use, or
                suspected unauthorized use of your Account or any other breach
                of security. The Company cannot and will not be liable for any
                loss or damage arising from your failure to comply with the
                above requirements.
              </ListItem>
            </NumberList>
          </Container>
        </ListItem>
        <ListItem>
          <b>SERVICE</b>
          <Container>
            <NumberList>
              <ListItem>
                If you use the Service, you represent and warrant to the Company
                that: (i) all required information you submit is truthful and
                accurate; (ii) your use of the Service does not violate any
                applicable law or regulation or these Terms.
              </ListItem>
              <ListItem>
                The Company reserves the right to suspend or terminate your use
                of Service, or your access to the Service, with or without
                notice to you, in the event that you breach these Terms.
              </ListItem>
              <ListItem>
                The Service may be modified, updated, interrupted or suspended
                at any time without notice to you or our liability.
              </ListItem>
              <ListItem>
                You are solely responsible for obtaining the equipment and
                telecommunication services necessary to access the Service, and
                all fees associated therewith (such as computing devices and
                Internet service provider and airtime charges).
              </ListItem>
              <ListItem>
                We retain the right to implement any changes to the Service
                (whether to free or paid features) at any time, with or without
                notice. You acknowledge that a variety of Company&#39;s actions
                may impair or prevent you from accessing the Service at certain
                times and/or in the same way, for limited periods or
                permanently, and agree that the Company has no responsibility or
                liability as a result of any such actions or results, including,
                without limitation, for the deletion of, or failure to make
                available to you, any content or services.
              </ListItem>
              <ListItem>
                Your access to and use of the Service is at your own risk. To
                the extent permitted by law, еhe Company will have no
                responsibility for any harm to your computing system, loss of
                data, or other harm to you or any third party, including,
                without limitation, any bodily harm, that results from your
                access to or use of the Service, or reliance on any information
                or advice.
              </ListItem>
              <ListItem>
                The Company has no obligation to provide you with customer
                support of any kind. However, the Company may provide you with
                customer support from time to time, at the Company&#39;s sole
                discretion.
              </ListItem>
            </NumberList>
          </Container>
        </ListItem>
        <ListItem>
          <b>THIRD PARTY ADS, INTELLECTUAL PROPERTY, USER CONTENT</b>
          <Container>
            <NumberList>
              <ListItem>
                The Service may contain links to third party websites or
                resources and advertisements for third parties (collectively,
                &quot;Third-Party Ads&quot;). Such Third-Party Ads are not under
                the control of the Company and the Company is not responsible
                for any Third-Party Ads. The Company provides these Third-Party
                Ads only as a convenience and does not review, approve, monitor,
                endorse, warrant, or make any representations with respect to
                Third-Party Ads. Advertisements and other information provided
                by Third- Party Sites Ads may not be wholly accurate. You
                acknowledge sole responsibility for and assume all risk arising
                from your use of any such websites or resources. When you link
                to a third-party site, the applicable service provider&#39;s
                terms and policies, including privacy and data gathering
                practices govern. You should make whatever investigation you
                feel necessary or appropriate before proceeding with any
                transaction with any third party. Your transactions and other
                dealings with Third-Party Ads that are found on or through the
                Service, including payment and delivery of related goods or
                services, are solely between you and such merchant or
                advertiser.
              </ListItem>
              <ListItem>
                You hereby release us, our officers, employees, agents and
                successors from claims, demands any and all losses, damages,
                rights, claims, and actions of any kind including personal
                injuries, death, and property damage, that is either directly or
                indirectly related to or arises from any interactions with or
                conduct of any users of the Service, or any Third-Party Ads.
              </ListItem>
              <ListItem>
                Subject to these Terms, the Company grants you a
                non-transferable, non-exclusive, revocable license (without the
                right to sublicense) to use the Service solely for your
                personal, non-commercial purposes.
              </ListItem>
              <ListItem>
                You agree, represent and warrant, that your use of the Service,
                or any portion thereof, will be consistent with the foregoing
                license, covenants and restrictions and will neither infringe
                nor violate the rights of any other party or breach any contract
                or legal duty to any other parties. In addition, you agree that
                you will comply with all applicable laws, regulations and
                ordinances relating to the Service or your use of it, and you
                will be solely responsible for your own individual violations of
                any such laws.
              </ListItem>
              <ListItem>
                You acknowledge that all the text, images, marks, logos,
                compilations (meaning the collection, arrangement and assembly
                of information), data, other content, software and materials
                displayed on the Service or used by the Company to operate the
                Service (including the Content and excluding any User Content
                (as defined below)) is proprietary to us or to the third
                parties.
              </ListItem>
              <ListItem>
                The Company expressly reserves all rights, including all
                intellectual property rights, in all of the foregoing, and
                except as expressly permitted by these Terms, any use,
                redistribution, sale, decompilation, reverse engineering,
                disassembly, translation or other exploitation of them is
                strictly prohibited. The provision of the Service does not
                transfer to you or any third party any rights, title or interest
                in or to such intellectual property rights.
              </ListItem>
              <ListItem>
                The information you submit to us and any data, text and other
                material that you may submit to the Service (”User Content”)
                remain your intellectual property, and the Company does not
                claim any ownership of the copyright or other proprietary rights
                in such registration information and the User Content.
                Notwithstanding the foregoing, you agree that the Company may
                retain copies of the User Content and use it as reasonably
                necessary for or incidental to its operation of the Service and
                as described in these Terms and the
                {' '}
                <RouteLink to={BASE_ROUTES.PRIVACY_POLICY}>
                  Privacy Policy
                </RouteLink>
                .
              </ListItem>
              <ListItem>
                You grant the Company the non-exclusive, worldwide,
                transferable, perpetual, irrevocable right to publish,
                distribute, publicly display and perform the User Content in
                connection with the Service.
              </ListItem>
              <ListItem>
                Each user of the Service is solely responsible for any and all
                his or her User Content. Because we do not control the User
                Content, you acknowledge and agree that we are not responsible
                for any User Content and we make no guarantees regarding the
                accuracy, currency, suitability, or quality of any User Content,
                and we assume no responsibility for any User Content. Your
                interactions with other Service users are solely between you and
                such user. You agree that the Company will not be responsible
                for any loss or damage incurred as the result of any such
                interactions. If there is a dispute between you and any Service
                user, we are under no obligation to become involved.
              </ListItem>
            </NumberList>
          </Container>
        </ListItem>
        <ListItem>
          <b>PAYMENTS AND REFUNDS</b>
          <Container>
            <NumberList>
              <ListItem>
                Certain features of the Service, such as, for example, live
                chats, sessions or readings with astrologers or other experts
                (the “Expert Session(s)”) may be offered for a fee. To access
                certain Expert Sessions, you shall purchase credits (the
                “Credit(s)”) to top-up your credit balance (the “Credit
                balance”).
              </ListItem>
              <ListItem>
                You may top-up your Credit balance by using the credit card,
                debit card, PayPal account or other payment methods available on
                the Service (the “Payment Method”).
              </ListItem>
              <ListItem>
                The Credits will be deducted from your Credit balance when you
                access the Expert Session on a per-minute basis. We will charge
                a certain number of Credits, as indicated on payment screen, for
                each minute of the Expert Session at the beginning of such
                minute.
              </ListItem>
              <ListItem>
                The topping-up of the Credit balance may automatically renew.
                You expressly authorize the Company to automatically top-up your
                Credit balance whenever it falls below the minimum indicated on
                the payment screen during the purchase, until you cancel. To
                top-up your Credit balance the Company will charge your Payment
                Method that you have used for making the purchase. You will be
                notified about the referred automatic topping-up of your Credit
                balance by the Company each time it occurs through the Service
                and/or by sending you an email notification. The terms of
                automatic topping-up and cancellation procedure will be
                disclosed to you on the Website.
              </ListItem>
              <ListItem>
                To the maximum extent permitted by applicable laws, we may
                change the payment terms at any time. We will give you
                reasonable notice of any such changes by posting them on or
                through the Service and/or by sending you an email notification.
              </ListItem>
              <ListItem>
                You authorize us to charge the applicable fees to the Payment
                Method that you submit.
              </ListItem>
              <ListItem>
                To the extent permitted by applicable law, Purchases made via
                our website are non-refundable and/or non-exchangeable, unless
                otherwise is stated herein or is required by applicable law.
              </ListItem>
            </NumberList>
            <Text>
              <b>Note for the EU residents:</b>
            </Text>
            <Text>
              If you are an EU user, you have the right to withdraw from service
              agreement and agreement for digital goods without charge and
              without giving any reason within fourteen (14) days from the date
              of such agreement conclusion. The withdrawal right does not apply
              if the performance of the agreement has begun with your prior
              express consent and your acknowledgment that you thereby lose your
              right of withdrawal.
            </Text>
            <Text>
              YOU HEREBY EXPRESSLY CONSENT TO THE IMMEDIATE PERFORMANCE OF THE
              AGREEMENT AND ACKNOWLEDGE THAT YOU WILL LOSE YOUR RIGHT OF
              WITHDRAWAL FROM THE AGREEMENT ONCE OUR SERVERS VALIDATE YOUR
              PURCHASE AND THE APPLICABLE PURCHASE IS SUCCESSFULLY DELIVERED TO
              YOU. Therefore, unless the Service is defective, you will not be
              eligible to a refund in relation to digital goods, and will only
              be eligible to a proportional refund in relation to digital
              service.
            </Text>
          </Container>
        </ListItem>
        <ListItem>
          <b>USER REPRESENTATIONS AND RESTRICTIONS</b>
          <Container>
            <NumberList>
              <ListItem>
                By using the Service, you represent and warrant that:
                <Container>
                  <NumberList>
                    <ListItem>
                      you have the legal capacity and you agree to comply with
                      these Terms;
                    </ListItem>
                    <ListItem>you are not under the age of 16;</ListItem>
                    <ListItem>
                      you will not access the Service through automated or
                      non-human means, whether through a bot, script or
                      otherwise;
                    </ListItem>
                    <ListItem>
                      you will not use the Service for any illegal or
                      unauthorized purpose;
                    </ListItem>
                    <ListItem>
                      you are not located in a country that is subject to a U.S.
                      government embargo, or that has been designated by the
                      U.S. government as a “terrorist supporting“ country;
                    </ListItem>
                    <ListItem>
                      you are not listed on any U.S. government list of
                      prohibited or restricted parties; and
                    </ListItem>
                    <ListItem>
                      your use of the Service will not violate any applicable
                      law or regulation.
                    </ListItem>
                  </NumberList>
                </Container>
              </ListItem>
              <ListItem>
                If you provide any information that is untrue, inaccurate, not
                current, or incomplete, we have the right to refuse any and all
                current or future use of the Service (or any portion thereof).
              </ListItem>
              <ListItem>
                You may not access or use the Service for any purpose other than
                that for which we make the Service available. The Service may
                not be used in connection with any commercial endeavors except
                those that are specifically endorsed or approved by us.
              </ListItem>
              <ListItem>
                As a user of the Service, you agree not to:
                <Container>
                  <NumberList>
                    <ListItem>
                      systematically retrieve data or other content from the
                      Service to create or compile, directly or indirectly, a
                      collection, compilation, database, or directory without
                      written permission from us;
                    </ListItem>
                    <ListItem>
                      make any unauthorized use of the Service;
                    </ListItem>
                    <ListItem>
                      make any modification, adaptation, improvement,
                      enhancement, translation, or derivative work from the
                      Service;
                    </ListItem>
                    <ListItem>
                      use the Service for any revenue generating endeavor,
                      commercial enterprise, or other purpose for which it is
                      not designed or intended;
                    </ListItem>
                    <ListItem>
                      make the Service available over a network or other
                      environment permitting access or use by multiple devices
                      or users at the same time;
                    </ListItem>
                    <ListItem>
                      use the Service for creating a product, service, or
                      software that is, directly or indirectly, competitive with
                      or in any way a substitute for the Service;
                    </ListItem>
                    <ListItem>
                      use any proprietary information or any of our interfaces
                      or our other intellectual property in the design,
                      development, manufacture, licensing, or distribution of
                      any applications, accessories, or devices for use with the
                      Service;
                    </ListItem>
                    <ListItem>
                      circumvent, disable, or otherwise interfere with
                      security-related features of the Service;
                    </ListItem>
                    <ListItem>
                      engage in unauthorized framing of or linking to the
                      Service;
                    </ListItem>
                    <ListItem>
                      interfere with, disrupt, or create an undue burden on the
                      Service or the networks or services connected to the
                      Service;
                    </ListItem>
                    <ListItem>
                      decipher, decompile, disassemble, or reverse engineer any
                      of the software comprising or in any way making up a part
                      of the Service;
                    </ListItem>
                    <ListItem>
                      attempt to bypass any measures of the Service designed to
                      prevent or restrict access to the Service, or any portion
                      of the Service;
                    </ListItem>
                    <ListItem>
                      upload or distribute in any way files that contain
                      viruses, worms, trojans, corrupted files, or any other
                      similar software or programs that may damage the operation
                      of another&#39;s computer;
                    </ListItem>
                    <ListItem>
                      use, launch, develop, or distribute any automated system,
                      including without limitation, any spider, robot, cheat
                      utility, scraper, or offline reader that accesses the
                      Service, or using or launching any unauthorized script or
                      other software;
                    </ListItem>
                    <ListItem>
                      use the Service to send automated queries to any website
                      or to send any unsolicited commercial e-mail;
                    </ListItem>
                    <ListItem>
                      disparage, tarnish, or otherwise harm, in our opinion, us
                      and/or the Service;
                    </ListItem>
                    <ListItem>
                      use the Service in a manner inconsistent with any
                      applicable laws or regulations; or
                    </ListItem>
                    <ListItem>otherwise infringe these Terms.</ListItem>
                  </NumberList>
                </Container>
              </ListItem>
            </NumberList>
          </Container>
        </ListItem>
        <ListItem>
          <b>DISCLAIMER OF WARRANTIES</b>
          <Text>
            THE WEBSITE, CONTENT AND OTHER ASPECTS OF THE SERVICE ARE PROVIDED
            “AS IS“ AND “AS AVAILABLE“. THE WEBSITE, CONTENT AND OTHER ASPECTS
            OF THE SERVICE ARE PROVIDED WITHOUT REPRESENTATION OR WARRANTY OF
            ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
            IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, INTEGRATION,
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY
            WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE,
            ALL OF WHICH ARE EXPRESSLY DISCLAIMED. THE COMPANY AND ITS
            AFFILIATES, LICENSORS AND SUPPLIERS DO NOT WARRANT THAT: (I) THE
            SERVICE, CONTENT OR OTHER INFORMATION WILL BE TIMELY, ACCURATE,
            RELIABLE OR CORRECT; (II) THE SERVICE WILL BE SECURE OR AVAILABLE AT
            ANY PARTICULAR TIME OR PLACE; (III) ANY DEFECTS OR ERRORS WILL BE
            CORRECTED; (IV) THE SERVICE WILL BE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS; OR (V) ANY RESULT OR OUTCOME CAN BE ACHIEVED.
          </Text>
        </ListItem>
        <ListItem>
          <b>LIMITATION OF LIABILITY</b>
          <Container>
            <NumberList>
              <ListItem>
                IN NO EVENT SHALL WE (AND OUR AFFILIATES) BE LIABLE TO YOU OR
                ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT,
                CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE
                DAMAGES ARISING FROM THESE TERMS OR YOUR USE OF, OR INABILITY TO
                USE, THE SERVICE (INCLUDING THE WEBSITE OR CONTENT), OR
                THIRD-PARTY ADS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
                OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICE (INCLUDING
                THE WEBSITE, CONTENT AND USER CONTENT), AND THIRD-PARTY ADS ARE
                AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
                RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTING SYSTEM OR LOSS OF
                DATA RESULTING THEREFROM.
              </ListItem>
              <ListItem>
                NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, YOU
                AGREE THAT THE AGGREGATE LIABILITY OF THE COMPANY TO YOU FOR ANY
                AND ALL CLAIMS ARISING FROM THE USE OF THE WEBSITE, CONTENT OR
                SERVICE IS LIMITED TO THE AMOUNTS YOU HAVE PAID TO THE COMPANY
                FOR THE SERVICE. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
                FUNDAMENTAL ELEMENTS OF THE BASIS OF THE TERMS BETWEEN THE
                COMPANY AND YOU.
              </ListItem>
              <ListItem>
                SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
                LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE
                LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU AND YOU MAY ALSO
                HAVE OTHER LEGAL RIGHTS THAT VARY FROM JURISDICTION TO
                JURISDICTION.
              </ListItem>
            </NumberList>
          </Container>
        </ListItem>
        <ListItem>
          <b>INDEMNITY</b>
          <Text>
            You agree to indemnify and hold the Company, its successors,
            subsidiaries, affiliates, any related companies, its suppliers,
            licensors and partners, and the officers, directors, employees,
            agents and representatives of each of them harmless, including costs
            and attorneys&#39; fees, from any claim or demand made by any third
            party due to or arising out of (i) your use of the Service, (ii)
            your User Content, or (iii) your violation of these Terms. The
            Company reserves the right, at your expense, to assume the exclusive
            defense and control of any matter for which you are required to
            indemnify us and you agree to cooperate with our defense of these
            claims. You agree not to settle any matter without the prior written
            consent of the Company. The Company will use reasonable efforts to
            notify you of any such claim, action or proceeding upon becoming
            aware of it.
          </Text>
        </ListItem>
        <ListItem>
          <b>INTERNATIONAL USE</b>
          <Text>
            The Company makes no representation that the Service is accessible,
            appropriate or legally available for use in your jurisdiction, and
            accessing and using the Service is prohibited from territories where
            doing so would be illegal. You access the Service at your own
            initiative and are responsible for compliance with local laws.
          </Text>
        </ListItem>
        <ListItem>
          <b>MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER</b>
          <Container>
            <NumberList>
              <ListItem>
                PLEASE READ THIS ARBITRATION PROVISION CAREFULLY TO UNDERSTAND
                YOUR RIGHTS. EXCEPT WHERE PROHIBITED BY LAW, YOU AGREE THAT ANY
                CLAIM THAT YOU MAY HAVE IN THE FUTURE MUST BE RESOLVED THROUGH
                FINAL AND BINDING CONFIDENTIAL ARBITRATION. YOU ACKNOWLEDGE AND
                AGREE THAT YOU ARE WAIVING THE RIGHT TO A TRIAL BY JURY. THE
                RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT, SUCH AS
                DISCOVERY OR THE RIGHT TO APPEAL, MAY BE MORE LIMITED OR MAY NOT
                EXIST.
              </ListItem>
              <ListItem>
                YOU AGREE THAT YOU MAY ONLY BRING A CLAIM IN YOUR INDIVIDUAL
                CAPACITY AND NOT AS A PLAINTIFF (LEAD OR OTHERWISE) OR CLASS
                MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU
                FURTHER AGREE THAT THE ARBITRATOR MAY NOT CONSOLIDATE
                PROCEEDINGS OR CLAIMS OR OTHERWISE PRESIDE OVER ANY FORM OF A
                REPRESENTATIVE OR CLASS PROCEEDING.
              </ListItem>
              <ListItem>
                YOU AND THE COMPANY, AND EACH OF ITS RESPECTIVE AGENTS,
                CORPORATE PARENTS, SUBSIDIARIES, AFFILIATES, PREDECESSORS IN
                INTEREST, SUCCESSORS, AND ASSIGNS, AGREE TO ARBITRATION (EXCEPT
                FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT), AS THE
                EXCLUSIVE FORM OF DISPUTE RESOLUTION EXCEPT AS PROVIDED FOR
                BELOW, FOR ALL DISPUTES AND CLAIMS ARISING OUT OF OR RELATING TO
                THIS AGREEMENT, THE SERVICE, OR THE PRIVACY POLICY, UNLESS YOU
                ARE LOCATED IN A JURISDICTION THAT PROHIBITS THE EXCLUSIVE USE
                OF ARBITRATION FOR DISPUTE RESOLUTION.
              </ListItem>
              <ListItem>
                Arbitration is more informal way to settle disputes than a
                lawsuit in court. A neutral arbitrator instead of a judge or
                jury is used in arbitration, which allows for more limited
                discovery than in court, and is subject to very limited review
                by courts. The same damages and relief that a court can award
                can be awarded by arbitrators. Please see more information about
                arbitration at http://www.adr.org.
              </ListItem>
              <ListItem>
                A party which intends to seek arbitration must first send to the
                other a written notice of intent to arbitrate (a “Notice“) by an
                international courier with a tracking mechanism, or, in the
                absence of a mailing address provided by you to us, via any
                other method available to us, including via e-mail. The Notice
                to the Company must be addressed to:
                {' '}
                <Address title="address" />
                {' '}
                (as applicable, the “Arbitration Notice Address“). The Notice
                shall (i) describe the basis and nature of the claim or dispute;
                and (ii) set the specific relief sought (the “Demand“). If you
                and the Company do not reach an agreement to resolve the claim
                within 30 days after the Notice is received, then you or we may
                commence an arbitration proceeding as set forth below or file an
                individual claim in small claims court.
              </ListItem>
              <ListItem>
                THE AMERICAN ARBITRATION ASSOCIATION (“AAA“) WILL EXCLUSIVELY
                ADMINISTER THE ARBITRATION IN ACCORDANCE WITH ITS COMMERCIAL
                ARBITRATION RULES AND THE SUPPLEMENTARY PROCEDURES FOR CONSUMER
                RELATED DISPUTES (THE “Rules“), AS MODIFIED BY THESE TERMS.
              </ListItem>
              <ListItem>
                If you commence arbitration against us, you are required to
                provide a second Notice to the Company at the Arbitration Notice
                Address within seven (7) days of arbitration commencement. The
                Rules and AAA forms are available online at http://www.adr.org.
                Unless your Demand is equal to or greater than $1,000 or was
                filed in bad faith, in which case you are solely responsible for
                the payment of the filing fee, if you are required to pay a
                filing fee to commence an arbitration against us, then we will
                promptly reimburse you for your confirmed payment of the filing
                fee upon our receipt of the second Notice at the Arbitration
                Notice Address that you have commenced arbitration along with a
                receipt evidencing payment of the filing fee.
              </ListItem>
              <ListItem>
                The arbitration shall be conducted exclusively in English. A
                single, independent and impartial arbitrator with his or her
                primary place of business in Alexandria, Virginia (if you are
                from the United States) or in Nicosia, Cyprus (if you are not
                from the United States) will be appointed pursuant to the Rules,
                as modified herein. You and the Company agree to comply with the
                following rules, which are intended to streamline the
                arbitration process and reduce the costs and burdens on the
                parties: (i) the arbitration will be conducted online and/or be
                solely based on written submissions, the specific manner to be
                chosen by the party initiating the arbitration; (ii) the
                arbitration will not require any personal appearance by the
                parties or witnesses unless otherwise mutually agreed in writing
                by the parties; and (iii) any judgment on the award the
                arbitrator renders may be entered in any court of competent
                jurisdiction.
              </ListItem>
              <ListItem>
                TO THE FULLEST EXTENT PERMITTED UNDER LAW, YOU AND THE COMPANY
                AGREE THAT YOU AND THE COMPANY MAY BRING CLAIMS AGAINST THE
                OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A
                PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS,
                REPRESENTATIVE, OR CONSOLIDATED PROCEEDING. FURTHER, YOU AGREE
                THAT THE ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS OF MORE THAN
                ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM
                OF A REPRESENTATIVE OR CLASS PROCEEDING, AND THAT IF THIS
                SPECIFIC PROVISION IS FOUND TO BE UNENFORCEABLE, THEN THE
                ENTIRETY OF THIS MANDATORY ARBITRATION SECTION WILL BE NULL AND
                VOID.
              </ListItem>
              <ListItem>
                The arbitrator shall have the exclusive and sole authority to
                resolve any dispute relating to the interpretation,
                construction, validity, applicability, or enforceability of
                these Terms,
                {' '}
                <RouteLink to={BASE_ROUTES.PRIVACY_POLICY}>
                  Privacy Policy
                </RouteLink>
                , and this arbitration provision. The arbitrator shall have the
                exclusive and sole authority to determine whether this
                arbitration clause can be enforced against a non-party to this
                agreement and whether a non-party to these Terms can enforce its
                provision against you or us.
              </ListItem>
              <ListItem>
                Barring extraordinary circumstances, the arbitrator will issue
                his or her final, confidential decision within 120 days from the
                date the arbitrator is appointed. The arbitrator may extend this
                time limit for an additional 30 days upon a showing of good
                cause and in the interests of justice. All arbitration
                proceedings will be closed to the public and confidential, and
                all records relating thereto will be permanently sealed, except
                as necessary to obtain court confirmation of the arbitration
                award. The award of the arbitrator will be in writing and will
                include a statement setting forth the reasons for the
                disposition of any claim. The arbitrator shall apply the laws of
                the Commonwealth of Virginia without regard to its conflicts of
                laws principles in conducting the arbitration. You acknowledge
                that these terms and your use of the Service evidences a
                transaction involving interstate commerce. The United States
                Federal Arbitration Act (“FAA“) will govern the interpretation,
                enforcement, and proceedings pursuant to this Section 12. Any
                award rendered shall be final, subject to appeal under the FAA.
              </ListItem>
              <ListItem>
                The abovestated provisions of this Section 12 shall not apply to
                any claim in which either party seeks equitable relief to
                protect such party&#39;s copyrights, trademarks, patents, or
                other intellectual property. For the avoidance of doubt, you
                agree that, in the event the Company or a third party breaches
                these Terms, the damage or harm, if any, caused to you will not
                entitle you to seek injunctive or other equitable relief against
                us, and your only remedy will be for monetary damages, subject
                to the limitations of liability set forth in these Terms.
              </ListItem>
              <ListItem>
                You and we agree that, notwithstanding any other rights a party
                may have at law or in equity, any claim arising out of or
                related to these Terms (including the Privacy Policy) or the
                Service, excluding a claim for indemnification, must be
                initiated with the AAA or filed in small claims court in
                Alexandria, Virginia within one (1) year after the claim
                accrues. Otherwise, such cause of action is permanently and
                forever barred. This one (1) year period includes the thirty
                (30) day pre-dispute procedure set forth in sub-clause 12.5
                above.
              </ListItem>
              <ListItem>
                All claims you bring against the Company must be resolved in
                accordance with this Section. All claims filed or brought
                contrary to this Section shall be considered improperly filed.
                Should you file a claim contrary to this Section, the Company
                may recover attorneys&#39; fees and reimbursement of its costs,
                provided that the Company has notified you in writing of the
                improperly filed claim, and you fail to promptly withdraw such
                claim.
              </ListItem>
              <ListItem>
                In the event that we make any material change to this
                arbitration provision (other than a change to our Arbitration
                Notice Address), you may reject any such change by sending us
                written notice to our Arbitration Notice Address within thirty
                (30) days of the change, in which case you shall terminate your
                use of the Service immediately, and this Section, as in effect
                immediately prior to the amendments you reject, will survive the
                termination of these Terms.
              </ListItem>
              <ListItem>
                If only clause 12.9 paragraph above or the entirety of this
                Section 12 is found to be unenforceable, then the entirety of
                this Section 12 will be null and void and, in such case, the
                parties agree that the exclusive jurisdiction and venue
                described in Section 13 will govern any action arising out of or
                related to this Agreement.
              </ListItem>
              <ListItem>
                YOU UNDERSTAND THAT YOU WOULD HAVE HAD A RIGHT TO LITIGATE
                THROUGH A COURT, TO HAVE A JUDGE OR JURY DECIDE YOUR CASE, AND
                TO BE PARTY TO A CLASS OR REPRESENTATIVE ACTION. HOWEVER, YOU
                UNDERSTAND AND AGREE TO HAVE ANY CLAIMS DECIDED INDIVIDUALLY AND
                ONLY THROUGH BINDING, FINAL, AND CONFIDENTIAL ARBITRATION.
              </ListItem>
              <ListItem>
                YOU HAVE THE RIGHT TO OPT-OUT OF THIS ARBITRATION PROVISION
                WITHIN THIRTY (30) DAYS FROM THE DATE THAT YOU FIRST USE, OR
                ATTEMPT TO USE, THE SERVICE BY WRITING TO
                {' '}
                <Link href={`mailto: ${config.mail}`}>SUPPORT CENTER</Link>
                {' '}
                OR
                TO THE ARBITRATION NOTICE ADDRESS. FOR YOUR OPT-OUT TO BE
                EFFECTIVE, YOU MUST SUBMIT A SIGNED WRITTEN NOTICE OPTING OUT
                AND CONTAINING ENOUGH DETAILS ABOUT YOU FOR US TO BE ABLE TO
                IDENTIFY YOU WITHIN THIRTY (30) DAYS. IF MORE THAN THIRTY (30)
                DAYS HAVE PASSED, YOU ARE NOT ELIGIBLE TO OPT OUT OF THIS
                PROVISION AND YOU MUST PURSUE YOUR CLAIM THROUGH BINDING
                ARBITRATION AS SET FORTH IN THIS AGREEMENT.
              </ListItem>
            </NumberList>
          </Container>
        </ListItem>
        <ListItem>
          <b>GOVERNING LAW</b>
          <Container>
            <NumberList>
              <ListItem>
                The laws of Cyprus, excluding its conflicts of law principles,
                govern these Terms and your use of the Service.
              </ListItem>
              <ListItem>
                To the extent that any action relating to any dispute hereunder
                is permitted to be brought in a court of law, such action will
                be subject to the exclusive jurisdiction of:
                <Container>
                  <NumberList>
                    <ListItem>
                      the state and federal courts in the City of Alexandria,
                      Virginia – if you are a resident of the United States; or
                    </ListItem>
                    <ListItem>
                      the courts of Nicosia, Cyprus – if you are not a resident
                      of the United States;
                    </ListItem>
                    <Text>
                      and you hereby irrevocably submit to personal jurisdiction
                      and venue in such courts, and waive any defense of
                      improper venue or inconvenient forum.
                    </Text>
                  </NumberList>
                </Container>
              </ListItem>
            </NumberList>
          </Container>
        </ListItem>
        <ListItem>
          <b>MISCELLANEOUS PROVISIONS</b>
          <Container>
            <NumberList>
              <ListItem>
                No delay or omission by us in exercising any of our rights
                occurring upon any noncompliance or default by you with respect
                to these Terms will impair any such right or be construed to be
                a waiver thereof, and a waiver by the Company of any of the
                covenants, conditions or agreements to be performed by you will
                not be construed to be a waiver of any succeeding breach thereof
                or of any other covenant, condition or agreement hereof
                contained.
              </ListItem>
              <ListItem>
                Subject to Section 13, if any provision of these Terms is found
                to be invalid or unenforceable, then these Terms will remain in
                full force and effect and will be reformed to be valid and
                enforceable while reflecting the intent of the parties to the
                greatest extent permitted by law.
              </ListItem>
              <ListItem>
                Except as otherwise expressly provided herein, these Terms set
                forth the entire agreement between you and the Company regarding
                its subject matter, and supersede all prior promises, agreements
                or representations, whether written or oral, regarding such
                subject matter.
              </ListItem>
              <ListItem>
                The Company may transfer or assign any and all of its rights and
                obligations under these Terms to any other person, by any way,
                including by novation, and by accepting these Terms you give the
                Company consent to any such assignment and transfer. You confirm
                that placing on the Service of a version of these Terms
                indicating another person as a party to the Terms shall
                constitute valid notice to you of the transfer of Company&#39;s
                rights and obligations under the Agreement (unless otherwise is
                expressly indicated).
              </ListItem>
              <ListItem>
                All information communicated on the Service is considered an
                electronic communication. When you communicate with us through
                or on the Service or via other forms of electronic media, such
                as e-mail, you are communicating with us electronically. You
                agree that we may communicate electronically with you and that
                such communications, as well as notices, disclosures,
                agreements, and other communications that we provide to you
                electronically, are equivalent to communications in writing and
                shall have the same force and effect as if they were in writing
                and signed by the party sending the communication. You further
                acknowledge and agree that by clicking on a button labeled
                “SUBMIT“, “CONTINUE“, “ADD TO CART“, “PAY“ or similar links or
                buttons, you are submitting a legally binding electronic
                signature and are entering into a legally binding contract. You
                acknowledge that your electronic submissions constitute your
                agreement and intent to be bound by these Terms. YOU HEREBY
                AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS AND
                OTHER RECORDS AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES
                AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED THROUGH THE
                SERVICE.
              </ListItem>
              <ListItem>
                In no event shall the Company be liable for any failure to
                comply with these Terms to the extent that such failure arises
                from factors outside the Company&#39;s reasonable control.
              </ListItem>
            </NumberList>
          </Container>
        </ListItem>
        <ListItem>
          <b>CONTACT</b>
          <Text>
            If you want to send any notice under these Terms or have any
            questions regarding the Service, you may contact us at:
            {' '}
            <a href={`mailto: ${config.mail}`}>SUPPORT CENTER</a>
            .
          </Text>
          <Text>
            I HAVE READ THESE TERMS AND AGREE TO ALL OF THE PROVISIONS CONTAINED
            ABOVE.
          </Text>
        </ListItem>
      </NumberList>
      <Text>
        Last updated:
        {config.dateOfTerms}
      </Text>
    </>
  );
};

export default Terms;
