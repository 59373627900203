import React from 'react';
import ImagedPageSection, {
  DPIScales,
  ImagedPageSectionHolder,
} from 'src/common/components/ImagedPageSection';
import styled from 'styled-components';

import webpXl from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-xl.webp';
import webpXl2x from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-xl-2x.webp';
import pngXl from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-xl.png';
import pngXl2x from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-xl-2x.png';
import webpMd from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-md.webp';
import webpMd2x from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-md-2x.webp';
import pngMd from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-md.png';
import pngMd2x from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-md-2x.png';
import webpXs from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-xs.webp';
import webpXs2x from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-xs-2x.webp';
import pngXs from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-xs.png';
import pngXs2x from 'src/astromix/assets/images/email-marketing-2/feedback/feedback-xs-2x.png';
import ImagedPageSectionButton from 'src/common/components/ImagedPageSectionButton';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

const sectionWidth = 360;
const sectionHeight = 1194;
const sectionTabletHeight = 1338;
const sectionTabletWidth = 768;
const sectionDesktopHeight = 1338;
const sectionDesktopWidth = 1440;
const topButtonTopOffset = 165;
const topButtonTopOffsetTablet = 458;
const topButtonTopOffsetDesktop = 458;
const topAspectRatio = topButtonTopOffset / sectionHeight;
const topTabletAspectRatio = topButtonTopOffsetTablet / sectionTabletHeight;
const topDesktopAspectRatio = topButtonTopOffsetDesktop / sectionDesktopHeight;

const bottomButtonTopOffset = 1086;
const bottomButtonTopOffsetTablet = 1215;
const bottomButtonTopOffsetDesktop = 1215;
const bottomAspectRatio = bottomButtonTopOffset / sectionHeight;
const bottomTabletAspectRatio = bottomButtonTopOffsetTablet / sectionTabletHeight;
const bottomDesktopAspectRatio = bottomButtonTopOffsetDesktop / sectionDesktopHeight;

const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionTabletHeight} / ${sectionTabletWidth} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionDesktopHeight} / ${sectionDesktopWidth} * 100%);
    }
  }
`;

export type SectionFeedbackProps = {
  onGetReport: () => void;
}

const SectionFeedback = ({ onGetReport }: SectionFeedbackProps) => (
  <ImagedSection originalUrl={sectionData.originalUrl} sources={sectionData.sources}>
    <ImagedPageSectionButton
      onClick={onGetReport}
      aspectRatio={topAspectRatio}
      tabletAspectRatio={topTabletAspectRatio}
      desktopAspectRatio={topDesktopAspectRatio}
      variant="success"
    >
      Get full report
    </ImagedPageSectionButton>
    <ImagedPageSectionButton
      onClick={onGetReport}
      aspectRatio={bottomAspectRatio}
      tabletAspectRatio={bottomTabletAspectRatio}
      desktopAspectRatio={bottomDesktopAspectRatio}
      variant="success"
    >
      Get full report
    </ImagedPageSectionButton>
  </ImagedSection>
);

export default SectionFeedback;
