import styled from 'styled-components';
import Price from 'src/astromix/components/Price';

export const AdvisoryPlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Label = styled.label`
  border-radius: 20px;
  padding: 4px 0 4px 4px;
  display: flex;
  width: 100%;
  max-width: 668px;
  margin: 0 auto 30px;
  background: linear-gradient(#141333, #202261, #543C97, #6939A2);
  transition: all .3s;

  :hover {
    transform: scale(1.05, 1.1);
    cursor: pointer;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  padding: 15px 0;
  border-radius: 15px 0 0 15px;
  background: #fff;
  border-right: 2px solid ${(props) => props.theme.colors.secondary};
`;

export const Title = styled.span`
  display: inline-block;
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 10px;
  }
`;

export const TotalPrice = styled.span`
  font-size: 12px;
  line-height: 140%;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 18px;
    line-height: 140%;
  }
`;

export const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 12.5px;
  max-width: 68px;
  text-align: center;
  overflow: hidden;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background: ${(props) => props.theme.gradients.primary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 16px 29px;
    max-width: 120px;
  }

  ::before {
    content: '';
    width: 1px;
    height: 100%;
  }
`;

export const PricePeriod = styled.span`
  display: inline-block;
  font-size: 12px;
  line-height: 140%;
  margin-top: 3px;
  color: ${(props) => props.theme.colors.secondary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    line-height: 140%;
    font-size: 16px;
  }
`;

export const StyledPrice = styled(Price)`
  font-size: 16px;
  line-height: 140%;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 10px;
  }
`;

export const Input = styled.input`
  display: none;

  &:checked + ${Label} {
    transform: scale(1.05, 1.1);

    & ${Description} {
      border-right: 1px solid ${(props) => props.theme.colors.secondary};
      background: transparent;
      padding: 5px 0;
      margin: 10px 0;
    }

    & ${Title} {
      color: ${(props) => props.theme.colors.secondary};
    }

    & ${TotalPrice} {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
`;

export const OldPrice = styled.s``;
