/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Emoji as EmojiFromLib, EmojiProps as EmojiFromLibProps } from 'emoji-mart';

export type EmojiProps = Omit<EmojiFromLibProps, 'set' | 'sheetSize' | 'size'> & {
  size?: number;
};

const Emoji = ({ size = 40, ...otherProps }: EmojiProps) => (
  <EmojiFromLib size={size} sheetSize={64} set="apple" {...otherProps} />
);

export default Emoji;
