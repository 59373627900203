import styled from 'styled-components';
import GuaranteeInscription from 'src/astromix/components/GuaranteeInscription';

export const InscriptionWrapper = styled.div<{
  aspectRatio?: number;
  tabletAspectRatio?: number;
  desktopAspectRatio?: number;
}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  top: calc(${(props) => props.aspectRatio} * 100%);

  @media ${(props) => props.theme.breakpoints.tablet} {
    top: calc(${(props) => props.tabletAspectRatio} * 100%);
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    top: calc(${(props) => props.desktopAspectRatio} * 100%);
  }
`;

export const StyledInscription = styled(GuaranteeInscription)`
  margin-left: 10px;
`;
