import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { BASE_ROUTES } from 'src/astromix/constants/routes';
import { ColorVariants } from 'src/common/theme/types';
import {
  BurgerIcon, CloseIcon, Container, NavLink,
} from './styled';

const links = [
  { link: BASE_ROUTES.PRIVACY_POLICY, text: 'Privacy policy', id: 1 },
  { link: BASE_ROUTES.TERMS, text: 'Terms of use', id: 2 },
  { link: BASE_ROUTES.PAYMENT_TERMS, text: 'Payment terms', id: 3 },
  { link: BASE_ROUTES.COOKIE_POLICY, text: 'Cookie policy', id: 4 },
  { link: BASE_ROUTES.FAQ, text: 'FAQ', id: 5 },
  { link: BASE_ROUTES.CONTACT_US, text: 'Contact us', id: 6 },
];

interface HeaderMenuProps {
  variant?: ColorVariants;
}

export const HeaderMenu = ({ variant = 'primary' }: HeaderMenuProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const onControlClick = () => {
    setIsOpened((prev) => !prev);
  };

  const onClose = () => setIsOpened(false);

  return (
    <>
      <BurgerIcon onClick={onControlClick} variant={variant} />
      <Drawer open={isOpened} onClose={onClose}>
        <Container>
          <CloseIcon onClick={onClose} />
          <nav>
            {links.map(({ link, text, id }) => (
              <NavLink to={link} key={id}>
                {text}
              </NavLink>
            ))}
          </nav>
        </Container>
      </Drawer>
    </>
  );
};

export default HeaderMenu;
