import { RootInitialState } from '../rootReducer';

export const getUserInformation = (state: RootInitialState) => state.userProfile;

export const getGender = (state: RootInitialState) => state.userProfile.gender;
export const getBirthdate = (state: RootInitialState) => state.userProfile.date;
export const getZodiac = (state: RootInitialState) => state.userProfile.zodiac;
export const getLongitude = (state: RootInitialState) => state.userProfile.place[0];
export const getLatitude = (state: RootInitialState) => state.userProfile.place[1];
export const getLocation = (state: RootInitialState) => state.userProfile.location;
export const getEmail = (state: RootInitialState) => state.userProfile.email;

export const getPartnerBirthdate = (state: RootInitialState) => state.userProfile.partnerDate;
export const getPartnerZodiac = (state: RootInitialState) => state.userProfile.partnerZodiac;
export const getPartnerGender = (state: RootInitialState) => state.userProfile.partnerGender;
export const getPartnerLongitude = (state: RootInitialState) => state.userProfile.partnerPlace[0];
export const getPartnerLatitude = (state: RootInitialState) => state.userProfile.partnerPlace[1];
export const getPartnerLocation = (state: RootInitialState) => state.userProfile.partnerLocation;
