import styled from 'styled-components';
import ImagedPageSection, { ImagedPageSectionHolder } from 'src/common/components/ImagedPageSection';
import PriceResolvedTimer, { PriceTimerTime } from 'src/astromix/components/PriceResolvedTimer';

export const sectionWidth = 360;
export const sectionHeight = 1251;
export const sectionTabletWidth = 768;
export const sectionTabletHeight = 1640;
export const sectionDesktopWidth = 1440;
export const sectionDesktopHeight = 1640;

export const sectionTimerTopOffset = 430;
export const sectionTimerTopOffsetTablet = 680;
export const sectionTimerTopOffsetDesktop = 680;

export const buttonTopOffset = 980;
export const buttonTopOffsetTablet = 1400;
export const buttonTopOffsetDesktop = 1400;

export const aspectRatio = buttonTopOffset / sectionHeight;
export const tabletAspectRatio = buttonTopOffsetTablet / sectionTabletHeight;
export const desktopAspectRatio = buttonTopOffsetDesktop / sectionDesktopHeight;

export const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionTabletHeight} / ${sectionTabletWidth} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionDesktopHeight} / ${sectionDesktopWidth} * 100%);
    }
  }
`;

export const TimerBlock = styled.div`
  border-radius: 20px;
  border: 3px solid ${(props) => props.theme.colors.info};
  width: 91%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(${sectionTimerTopOffset} / ${sectionHeight} * 100%);
  @media ${(props) => props.theme.breakpoints.tablet} {
    top: calc(${sectionTimerTopOffsetTablet} / ${sectionTabletHeight} * 100%);
  }
  @media ${(props) => props.theme.breakpoints.desktop} {
    top: calc(${sectionTimerTopOffsetDesktop} / ${sectionDesktopHeight} * 100%);
    width: 51%;
  }
`;

export const TimerTitle = styled.p`
  color: ${(props) => props.theme.colors.info};
  line-height: 24px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
  }
`;

export const TimerText = styled.p`
  font-size: 14px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 20px;
  }
`;

export const TextBlock = styled.div`
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const PriceTimer = styled(PriceResolvedTimer)`
  height: 60px;
  
  @media ${(props) => props.theme.breakpoints.tablet}  {
    height: 82px;
  }
  
  ${PriceTimerTime} {
    background: #6A3AA2;
    border-radius: 0 16px 16px 0;
    color: white;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-size: 30px;
  }
`;
