import { useMemo } from 'react';
import { PurchaseProductName } from 'src/common/types';
import useServices from 'src/common/hooks/useServices';

export function useCreatePayment(productName: PurchaseProductName) {
  const { purchaseService } = useServices();

  const purchaseDescription = useMemo(() => {
    switch (productName) {
      case PurchaseProductName.ASTROMIX_1W_999_1M_2856:
        return {
          price: '9.99$',
          getPaymentUrls: () => purchaseService.createPayment(
            [productName, PurchaseProductName.PERSONALIZED_COMPATIBILITY_REPORT],
          ),
        };
      case PurchaseProductName.ASTROMIX_1M_2856:
        return {
          price: '25.56$',
          getPaymentUrls: () => purchaseService.createPayment(
            [productName, PurchaseProductName.PERSONALIZED_COMPATIBILITY_REPORT],
          ),
        };
      case PurchaseProductName.ASTROMIX_1M_1999_1M_2856:
        return {
          price: '19.99$',
          getPaymentUrls: () => purchaseService.createPayment(
            [productName, PurchaseProductName.PERSONALIZED_COMPATIBILITY_REPORT],
          ),
        };
      case PurchaseProductName.ASTROMIX_1M_1499_1M_2856:
        return {
          price: '14.99$',
          getPaymentUrls: () => purchaseService.createPayment(
            [productName, PurchaseProductName.PERSONALIZED_COMPATIBILITY_REPORT],
          ),
        };
      case PurchaseProductName.ASTROMIX_WEEKLY_499:
        return {
          price: '4.99$',
          getPaymentUrls: () => purchaseService.createPayment(
            [productName, PurchaseProductName.PERSONALIZED_COMPATIBILITY_REPORT],
          ),
        };
      case PurchaseProductName.ASTROMIX_WEEKLY_999:
        return {
          // TODO make dynamic
          price: '9.99$',
          getPaymentUrls: () => purchaseService.createPayment(
            [productName, PurchaseProductName.PERSONALIZED_COMPATIBILITY_REPORT],
          ),
        };
      case PurchaseProductName.ASTROMIX_7D_TRIAL_3M_9999:
        return {
          // TODO make dynamic
          price: '9.99$',
          getPaymentUrls: () => purchaseService.createPayment(
            [productName, PurchaseProductName.PERSONALIZED_COMPATIBILITY_REPORT],
          ),
        };
      case PurchaseProductName.ASTROMIX_7D_TRIAL_1M_4999:
        return {
          // TODO make dynamic
          price: '9.99$',
          getPaymentUrls: () => purchaseService.createPayment(
            [productName, PurchaseProductName.PERSONALIZED_COMPATIBILITY_REPORT],
          ),
        };
      case PurchaseProductName.ASTROMIX_7D_TRIAL_1W_2000:
        return {
          // TODO make dynamic
          price: '9.99$',
          getPaymentUrls: () => purchaseService.createPayment(
            [productName, PurchaseProductName.PERSONALIZED_COMPATIBILITY_REPORT],
          ),
        };
      case PurchaseProductName.ASTROMIX_DISCOUNT_7D_TRIAL_3_MONTH_9999:
        return {
          // TODO make dynamic
          price: '4.99$',
          getPaymentUrls: () => purchaseService.createPayment(
            [productName, PurchaseProductName.PERSONALIZED_COMPATIBILITY_REPORT],
          ),
        };
      case PurchaseProductName.ASTROMIX_FREE_TRIAL_WEEKLY_999:
        return {
          price: 'FREE TRIAL',
          getPaymentUrls: () => purchaseService.createPayment(
            [productName, PurchaseProductName.PERSONALIZED_COMPATIBILITY_REPORT],
          ),
        };
      case PurchaseProductName.ARCHANGEL_RAPHAEL:
        return {
          price: '19.99$',
          getPaymentUrls: () => purchaseService.createPayment([productName]),
        };
      case PurchaseProductName.ARCHANGEL_RAPHAEL_799:
        return {
          price: '7.99$',
          getPaymentUrls: () => purchaseService.createPayment([productName]),
        };
      case PurchaseProductName.ARCHANGEL_RAPHAEL_DISCOUNT:
        return {
          price: '9.99$',
          getPaymentUrls: () => purchaseService.createPayment([productName]),
        };
      case PurchaseProductName.ARCHANGEL_RAPHAEL_DISCOUNT_499:
        return {
          price: '4.99$',
          getPaymentUrls: () => purchaseService.createPayment([productName]),
        };
      case PurchaseProductName.ANGELOLOGY_WITH_SUBSCRIPTION_1_MONTH_999:
        return {
          price: '9.99$',
          getPaymentUrls: () => purchaseService.createPayment([productName]),
        };
      case PurchaseProductName.ANGELOLOGY_WITH_SUBSCRIPTION_3_MONTHS_1999:
        return {
          price: '19.99$',
          getPaymentUrls: () => purchaseService.createPayment([productName]),
        };
      case PurchaseProductName.ANGELOLOGY_WITH_SUBSCRIPTION_6_MONTHS_2999:
        return {
          price: '29.99$',
          getPaymentUrls: () => purchaseService.createPayment([productName]),
        };
      case PurchaseProductName.ANGELOLOGY_WITH_SUBSCRIPTION_1_MONTH_DISCOUNT_799:
        return {
          price: '7.99$',
          getPaymentUrls: () => purchaseService.createPayment([productName]),
        };
      default:
        throw TypeError(`Unknown product type with subscription ${productName}`);
    }
  }, [productName, purchaseService]);

  return {
    price: purchaseDescription.price,
    getPaymentUrls: purchaseDescription.getPaymentUrls,
    productName,
  };
}
