import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BASE_ROUTES, LANDINGS } from 'src/astromix/constants/routes';
import ExtendedLanding from 'src/astromix/pages/ExtendedLanding/1week-vs-1month';
import Billing from 'src/astromix/pages/Billing/1week-vs-1month';
import AdditionalDiscount from 'src/astromix/pages/AdditionalDiscount/1week-vs-1month';
import Numerology from 'src/astromix/pages/Numerology/1week-vs-1month';
import Tarot from 'src/astromix/pages/Tarot/1week-vs-1month';
import { joinUrls } from 'src/common/utils/joinUrls';
import EmailMarketing1 from 'src/astromix/pages/EmailMarketing1';
import EmailMarketing2 from 'src/astromix/pages/EmailMarketing2';
import EmailMarketing3 from 'src/astromix/pages/EmailMarketing3';
import { QuizProvider } from 'src/common/contexts/QuizContext';
import ExperimentAnalytic from 'src/astromix/components/ExperimentAnalytic';
import { Experiment, Variant } from 'src/splitSystem';
import { BaseQuizStepData } from 'src/common/types';
import { QuizStepTypes } from 'src/astromix/types';
import Gender from 'src/astromix/pages/Quiz/Gender';
import How from 'src/astromix/pages/Quiz/How';
import Date from 'src/astromix/pages/Quiz/Date';
import Place from 'src/astromix/pages/Quiz/Place';
import Time from 'src/astromix/pages/Quiz/Time';
import ColorPicker from 'src/astromix/pages/Quiz/ColorPicker';
import Information from 'src/astromix/pages/Quiz/Information';
import Answer from 'src/astromix/pages/Quiz/Answer';
import EmailForm from 'src/astromix/pages/Quiz/EmailForm';
import Loader from 'src/astromix/pages/Quiz/Loader';
import { Experiments, Variants } from 'src/astromix/experiments';
import { astromixScreen } from './screen';
import { astromixScreenWithoutTimeAndPlace } from './screen_withoutTimeAndPlace';

const Astromix_1weekVS1month = () => {
  const makeRoutePath = (subPath: string) => joinUrls(BASE_ROUTES.ASTROMIX_1WEEK_1MONTH, subPath);

  const stepComponentFactory = ({ id, screenType }: BaseQuizStepData) => {
    switch (screenType) {
      case QuizStepTypes.GENDER:
        return <Gender id={id} />;
      case QuizStepTypes.HOW:
        return <How id={id} />;
      case QuizStepTypes.DATE:
        return <Date id={id} />;
      case QuizStepTypes.PLACE:
        return <Place id={id} />;
      case QuizStepTypes.TIME:
        return <Time id={id} />;
      case QuizStepTypes.COLOR_PICKER:
        return <ColorPicker id={id} />;
      case QuizStepTypes.INFORMATIONAL:
        return <Information id={id} />;
      case QuizStepTypes.ANSWER:
        return <Answer id={id} />;
      case QuizStepTypes.EMAIL:
        return <EmailForm id={id} />;
      case QuizStepTypes.PROCESSING:
        return <Loader id={id} />;
      default: {
        // TODO add fallback
        return null;
      }
    }
  };

  return (
    <Switch>
      <Route path={makeRoutePath(LANDINGS.EXTENDED_LANDING)}>
        <ExtendedLanding />
      </Route>

      <Route path={makeRoutePath(LANDINGS.BILLING)}>
        <Billing />
      </Route>

      <Route path={makeRoutePath(LANDINGS.ADDITIONAL_DISCOUNT)}>
        <AdditionalDiscount />
      </Route>

      <Route path={makeRoutePath(LANDINGS.NUMEROLOGY)}>
        <Numerology />
      </Route>

      <Route path={makeRoutePath(LANDINGS.TAROT)}>
        <Tarot />
      </Route>

      <Route path={makeRoutePath(LANDINGS.EMAIL_MARKETING_1)}>
        <EmailMarketing1 />
      </Route>

      <Route path={makeRoutePath(LANDINGS.EMAIL_MARKETING_2)}>
        <EmailMarketing2 />
      </Route>

      <Route path={makeRoutePath(LANDINGS.EMAIL_MARKETING_3)}>
        <EmailMarketing3 />
      </Route>

      <Experiment name={Experiments.NO_PLACE_TIME}>
        <Variant name={Variants.WITH_PLACE_TIME}>
          <ExperimentAnalytic name={astromixScreen.analyticName}>
            <QuizProvider data={astromixScreen} stepComponentFactory={stepComponentFactory} />
          </ExperimentAnalytic>
        </Variant>
        <Variant name={Variants.NO_PLACE_TIME}>
          <ExperimentAnalytic name={astromixScreenWithoutTimeAndPlace.analyticName}>
            <QuizProvider
              data={astromixScreenWithoutTimeAndPlace}
              stepComponentFactory={stepComponentFactory}
            />
          </ExperimentAnalytic>
        </Variant>
      </Experiment>
    </Switch>
  );
};

export default Astromix_1weekVS1month;
