import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GeoLocationService from 'src/common/api/GeoLocation';
import { config } from 'src/astromix/config';
import { getUserInformation, setUserInformation } from 'src/astromix/store/userProfile';
import { debounce } from 'src/common/utils/debounce';
import { CitiesList } from 'src/astromix/components/Quiz/Place/PlaceContainer';
import { QUIZ_ANALYTIC_EVENTS } from 'src/astromix/services/analytics';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';

export default function usePlace({
  back,
  next,
  locationKey,
  placeKey,
  analytic,
}: {
  placeKey: string;
  locationKey: string;
  back: () => Promise<void>;
  next: () => Promise<void>;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS;
  };
}) {
  const dispatch = useDispatch();

  const { analyticsService } = useAstromixServices();

  useEffect(() => {
    analyticsService.trackQuizEvent(analytic.baseEvent);
  }, [analyticsService, analytic]);

  const [hasVisibleContent, setVisibleContent] = useState(true);
  const handleInputClick = () => setVisibleContent(false);
  const handleCancelClick = () => {
    setCitiesList([]);
    setVisibleContent(true);
  };

  const userInformation: Record<string, string[] | string> = useSelector(getUserInformation);

  const initialPlace = Array.isArray(userInformation[placeKey])
    ? (userInformation[placeKey] as string[])
    : [];

  const initialLocation = typeof userInformation[locationKey] === 'string'
    ? (userInformation[locationKey] as string)
    : '';

  const [place, setPlace] = useState(initialPlace);
  const [location, setLocation] = useState(initialLocation);
  const [citiesList, setCitiesList] = useState<Array<CitiesList>>([]);

  const handleButtonClick = () => {
    dispatch(setUserInformation(placeKey, place));
    dispatch(setUserInformation(locationKey, location));
    next();
  };

  const onBack = () => back();

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLocation(value);
    await debouncedGeolocation(value);
  };

  const debouncedGeolocation = useCallback(
    debounce(async (value) => {
      if (value) {
        const { getResource } = new GeoLocationService(config.geocodeUrl, config.geocodeApiKey);
        const { features } = await getResource(value);
        return setCitiesList(features);
      }

      return setCitiesList([]);
    }, 500),
    [],
  );

  const handleSelectedItem = useCallback((cityPlace: string[], cityLocation: string) => {
    setPlace(cityPlace);
    setLocation(cityLocation);
    setCitiesList([]);
    handleCancelClick();
  }, []);

  return {
    handleButtonClick,
    onBack,
    citiesList,
    handleInputChange,
    handleSelectedItem,
    location,
    place,
    hasVisibleContent,
    handleInputClick,
    handleCancelClick,
  };
}
