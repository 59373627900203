import styled from 'styled-components';
import { TextMain } from '../styled';

export const Text = styled(TextMain)`
  margin-bottom: 20px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 30px;
  }
`;

export const AutocompleteContainer = styled.div`
  position: relative;
  max-width: 400px;
  margin: 0 auto;
`;

export const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
`;

export const InputBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Button = styled.button`
margin-left: 20px;`;
