import React from 'react';
import {
  DPIScales,
} from 'src/common/components/ImagedPageSection';
import webpXl from 'src/astromix/assets/images/extended-landing/intro/intro-xl.webp';
import webpXl2x
  from 'src/astromix/assets/images/extended-landing/intro/intro-xl-2x.webp';
import pngXl from 'src/astromix/assets/images/extended-landing/intro/intro-xl.png';
import pngXl2x from 'src/astromix/assets/images/extended-landing/intro/intro-xl-2x.png';
import webpMd from 'src/astromix/assets/images/extended-landing/intro/intro-md.webp';
import webpMd2x
  from 'src/astromix/assets/images/extended-landing/intro/intro-md-2x.webp';
import pngMd from 'src/astromix/assets/images/extended-landing/intro/intro-md.png';
import pngMd2x from 'src/astromix/assets/images/extended-landing/intro/intro-md-2x.png';
import webpXs from 'src/astromix/assets/images/extended-landing/intro/intro-xs.webp';
import webpXs2x
  from 'src/astromix/assets/images/extended-landing/intro/intro-xs-2x.webp';
import pngXs from 'src/astromix/assets/images/extended-landing/intro/intro-xs.png';
import pngXs2x from 'src/astromix/assets/images/extended-landing/intro/intro-xs-2x.png';
import {
  ImagedSection, ImagedZodiac, ImagedPartnerZodiac, ImagedZodiacBlock,
} from './styled';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

interface SectionIntroProps {
  userZodiac: string;
  partnerZodiac: string;
}

const SectionIntro = ({ userZodiac, partnerZodiac }: SectionIntroProps) => (
  <ImagedSection
    originalUrl={sectionData.originalUrl}
    sources={sectionData.sources}
  >
    <ImagedZodiacBlock>
      <ImagedZodiac originalUrl={userZodiac} />
      <ImagedPartnerZodiac originalUrl={partnerZodiac} />
    </ImagedZodiacBlock>
  </ImagedSection>
);

export default SectionIntro;
