import { Breakpoints, Indents } from './types';

export const angelologyTheme = {
  colors: {
    primary: '#333333',
    primaryLight: '#828282',
    secondary: '#FBFBFF',
    secondaryLight: '#FFD2D2',
    secondaryDark: '#E0E0E0',
    success: '#27AE60',
    error: '#EB5757',
    warning: '#F2C94C',
    warningDark: '#F2994A',
    mainLink: '#0000EF',
    info: 'linear-gradient(90.6deg, #B57CE2 0.47%, #F0AEA8 137.94%)',
    infoLight: '#9974F6',
    inactive: '',
    active: '',
    primaryLink: '',
    dark: '',
  },
  gradients: {
    primary: 'linear-gradient(90.6deg, #B57CE2 0.47%, #F0AEA8 137.94%);',
    primaryHover: 'linear-gradient(167.62deg, #141333 -32.12%, #3639A2 4.19%, #6D4BCD 49.79%, #9D5BE9 81.72%);',
    lightActive: 'linear-gradient(90.6deg, #ecd4ff 0.47%, #ffd8d5 137.94%)',
  },
  indents: {
    xs: Indents.XS,
    sm: Indents.SM,
    md: Indents.MD,
    lg: Indents.LG,
    xl: Indents.XL,
  },
  breakpoints: {
    mobile: `(max-width: ${Breakpoints.MOBILE})`,
    tablet: `(min-width: ${Breakpoints.TABLET})`,
    desktop: `(min-width: ${Breakpoints.DESKTOP})`,
  },
};
