import React from 'react';
import styled from 'styled-components';
import ImagedPageSection, {
  DPIScales,
  ImagedPageSectionHolder,
} from 'src/common/components/ImagedPageSection';
import pngXl from 'src/astromix/assets/images/tarot/header/header-xl.png';
import pngXl2x from 'src/astromix/assets/images/tarot/header/header-xl-2x.png';
import webpXl from 'src/astromix/assets/images/tarot/header/header-xl.webp';
import webpXl2x from 'src/astromix/assets/images/tarot/header/header-xl-2x.webp';
import pngMd from 'src/astromix/assets/images/tarot/header/header-md.png';
import pngMd2x from 'src/astromix/assets/images/tarot/header/header-md-2x.png';
import webpMd from 'src/astromix/assets/images/tarot/header/header-md.webp';
import webpMd2x from 'src/astromix/assets/images/tarot/header/header-md-2x.webp';
import pngXs from 'src/astromix/assets/images/tarot/header/header-xs.png';
import pngXs2x from 'src/astromix/assets/images/tarot/header/header-xs-2x.png';
import webpXs from 'src/astromix/assets/images/tarot/header/header-xs.webp';
import webpXs2x from 'src/astromix/assets/images/tarot/header/header-xs-2x.webp';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

const sectionWidth = 1366;
const sectionWidthTablet = 768;
const sectionWidthMobile = 360;
const sectionHeight = 1196;
const sectionHeightTablet = 1196;
const sectionHeightMobile = 897;

const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeightMobile} / ${sectionWidthMobile} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionHeightTablet} / ${sectionWidthTablet} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);
    }
  }
`;

const SectionHeader = () => (
  <ImagedSection
    originalUrl={sectionData.originalUrl}
    sources={sectionData.sources}
  />
);

export default SectionHeader;
