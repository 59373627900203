import { BASE_ROUTES, LANDINGS } from 'src/astromix/constants/routes';
import { ANALYTICS_NAME, QUIZ_NAMES } from 'src/common/types';
import InfluenceImage from 'src/astromix/assets/images/quiz/influence.png';
import WowImage from 'src/astromix/assets/images/quiz/wow.png';
import DidYouKnowImage from 'src/astromix/assets/images/quiz/did-you-know.png';
import { AstromixQuizData, QuizStepTypes } from 'src/astromix/types';
import { QUIZ_ANALYTIC_EVENTS } from 'src/astromix/services/analytics';

export const astromixScreen: AstromixQuizData = {
  name: QUIZ_NAMES.ASTROMIX,
  baseUrl: BASE_ROUTES.ASTROMIX_QUIZ,
  nextStage: LANDINGS.EXTENDED_LANDING,
  analyticName: ANALYTICS_NAME.ASTROMIX_STANDARD,
  steps: [
    {
      id: 'gender',
      screenType: QuizStepTypes.GENDER,
      dataKey: 'gender',
      hasPolicy: true,
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_GENDER_OPEN,
        baseEventProperties: {
          options: ['M', 'F'],
        },
      },
      content: {
        title: 'Personalized astrology-powered compatibility report',
        text:
          'Take a 1-minute quiz to get the compatibility analysis by professional astrologers based on'
          + ' your unique time & place of birth',
        subtitle: 'Your gender',
        subtext: 'Gender is important for personalizing your readings.',
      },
    },
    {
      id: 'how',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_HOW_OPEN,
      },
      content: {
        title: 'How is compatibility determined?',
        subtitle:
          'Your unique astrology readings are based on an astronomical snapshot of the stars at the'
          + ' very moment when you were born.',
        text:
          'In order to make your compatibility report as accurate as possible, '
          + 'it’s important for our astrologers to know your <b>date</b>, <b>place</b> and <b>time of birth</b>.'
          + '</br>'
          + '</br>'
          + 'This information allows to provide you with an accurate reading that will show you:',
        list: [
          'Compatibility with your partner & other people',
          'Behavioral tendencies & personality type',
          'True strength & weaknesses',
          'Hidden talents',
          'Unexpected directions your life might take',
        ],
      },
      screenType: QuizStepTypes.HOW,
    },
    {
      id: 'date',
      dataKey: 'date',
      zodiacDataKey: 'zodiac',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_BIRTH_DATE_OPEN,
      },
      content: {
        title: 'Your date of birth',
        text:
          'Your date of birth helps to determine your sun sign, numerology, and compatibility with others.',
      },
      screenType: QuizStepTypes.DATE,
    },
    {
      id: 'place',
      dataKey: 'place',
      locationDataKey: 'location',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_CITY_OPEN,
      },
      content: {
        title: 'Your place of birth',
        text: 'Place is important for determining your sun sign, numerology and compatibility.',
      },
      screenType: QuizStepTypes.PLACE,
    },
    {
      id: 'time',
      dataKey: 'time',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_BIRTH_TIME_OPEN,
      },
      content: {
        title: 'Your time of birth',
        text:
          'Time of birth is important for determining your ascendant, houses, and moon position for your natal chart.',
        label: 'I don’t know the exact time of my birth',
      },
      screenType: QuizStepTypes.TIME,
    },
    {
      id: 'loading-1',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PROCESSING_PAGE_OPEN,
        baseEventProperties: {
          page_num: 1,
        },
      },
      content: {
        list: [
          'Connecting to data base',
          'Designing your natal chart',
          'Analyzing your horoscope',
          'Checking for possible impact on your zodiac sign',
        ],
      },
      canNotBackToScreen: true,
      screenType: QuizStepTypes.PROCESSING,
    },
    {
      id: 'influence',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_INFLUENCE_OPEN,
        baseEventProperties: {
          page_num: 1,
        },
      },
      content: {
        text:
          'Hm… Based on the positions of planets in houses of your natal chart, very soon '
          + 'you are expected to get strongly influenced by the <b>Planet of Love</b> that will take you through '
          + 'turbulent times in your relationship.'
          + '</br>'
          + '</br>'
          + 'No worries. The report will tell you how to get prepared.',
        image: InfluenceImage,
      },
      screenType: QuizStepTypes.INFORMATIONAL,
    },
    {
      id: 'trust',
      dataKey: 'trust',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_TEST_TRUST_OPEN,
      },
      content: {
        title: 'I only share my inner workings to people I trust greatly',
        answers: [
          { text: 'Strongly agree', emoji: 'slightly_smiling_face' },
          { text: 'Somewhat disagree', emoji: 'thinking_face' },
          { text: 'Disagree', emoji: 'face_with_raised_eyebrow' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      hasVerticalOrientation: true,
      id: 'mad',
      dataKey: 'mad',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_TEST_MAD_OPEN,
      },
      content: {
        title: 'What makes you mad the most?',
        answers: [
          { text: 'Boredom', emoji: 'yawning_face' },
          { text: 'Liars', emoji: 'lying_face' },
          { text: 'Know-it-alls', emoji: 'sunglasses' },
          { text: 'Rude people', emoji: 'angry' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      hasVerticalOrientation: true,
      id: 'write',
      dataKey: 'write',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_TEST_WRITE_OPEN,
      },
      content: {
        title: 'How do you write?',
        answers: [
          { text: 'Fast and sloppy', emoji: 'fast_forward' },
          { text: 'Fancy cursive', emoji: 'ribbon' },
          { text: 'Child like', emoji: 'teddy_bear' },
          { text: 'Tiny and slanted', emoji: 'pinching_hand' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      hasVerticalOrientation: true,
      id: 'describe',
      dataKey: 'describe',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_TEST_DESCRIBE_OPEN,
      },
      content: {
        title: 'How would your friends describe you?',
        answers: [
          { text: 'Passionate', emoji: 'fire' },
          { text: 'Smart', emoji: 'face_with_monocle' },
          { text: 'Cool', emoji: 'sunglasses' },
          { text: 'Careless', emoji: 'partying_face' },
          { text: 'Talanted', emoji: 'artist' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      id: 'color',
      dataKey: 'coloPick',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_TEST_COLOR_OPEN,
      },
      content: {
        title: 'Pick a color',
      },
      screenType: QuizStepTypes.COLOR_PICKER,
    },
    {
      id: 'overthink',
      dataKey: 'overthink',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_TEST_OVERTHINK_OPEN,
      },
      content: {
        title: 'I tend to overthink things often',
        answers: [
          { text: 'Yes, that’s true', emoji: 'pensive' },
          { text: 'Maybe, I’m not sure...', emoji: 'confused' },
          { text: 'Definitely no', emoji: 'relieved' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      id: 'remember',
      dataKey: 'remember',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_REMEMBER_OPEN,
      },
      content: {
        title: 'I remember tiny details about the people I care about',
        answers: [
          { text: 'Yes, that’s true', emoji: 'slightly_smiling_face' },
          { text: 'Maybe, I’m not sure...', emoji: 'thinking_face' },
          { text: 'Definitely no', emoji: 'face_with_hand_over_mouth' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      hasVerticalOrientation: true,
      id: 'element',
      dataKey: 'element',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_TEST_ELEMENT_OPEN,
      },
      content: {
        title: 'Which of these natural elements are you actually most drawn to?',
        answers: [
          { text: 'Earth', emoji: 'mountain' },
          { text: 'Water', emoji: 'droplet' },
          { text: 'Fire', emoji: 'fire' },
          { text: 'Air', emoji: 'dash' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      hasVerticalOrientation: true,
      id: 'whichDate',
      dataKey: 'whichDate',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_TEST_DATING_OPEN,
      },
      content: {
        title: 'Which of these dates would you prefer the most?',
        answers: [
          { text: 'Fancy restaurant', emoji: 'knife_fork_plate' },
          { text: 'Going for a hike', emoji: 'hiking_boot' },
          { text: 'Talking under the stars', emoji: 'sparkles' },
          { text: 'Something unique', emoji: 'parachute' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      hasVerticalOrientation: true,
      id: 'important',
      dataKey: 'important',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_TEST_IMPORTANT_OPEN,
      },
      content: {
        title: 'Which of these is most important to you?',
        answers: [
          { text: 'Success', emoji: 'money_with_wings' },
          { text: 'Romance', emoji: 'sparkling_heart' },
          { text: 'Stability', emoji: 'house' },
          { text: 'Freedom', emoji: 'airplane' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      id: 'didYouKnow',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_COUPLE_STAT_PAGE_OPEN,
        baseEventProperties: {
          page_num: 2,
        },
      },
      content: {
        text:
          '<b>Only a tiny share of couples have natural 100% compatibility.</b>'
          + '</br>'
          + '</br>'
          + 'Whereas it doesn’t mean others can’t achieve it.'
          + '</br>'
          + '</br>'
          + 'Our astrology-powered relationship guide is built to help you learn '
          + '<b>the hidden personality traits</b> of each other and uncover '
          + '<b>the way to take your relationship to the entirely new level.</b>',
        image: DidYouKnowImage,
      },
      screenType: QuizStepTypes.INFORMATIONAL,
    },
    {
      id: 'partnergender',
      dataKey: 'partnerGender',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PARTNER_GENDER_OPEN,
        baseEventProperties: {
          options: ['M', 'F'],
        },
      },
      content: {
        title: 'Your partner’s gender',
        text: 'Gender is important for personalizing your partner’s readings.',
      },
      screenType: QuizStepTypes.GENDER,
    },
    {
      id: 'partnerdate',
      dataKey: 'partnerDate',
      zodiacDataKey: 'partnerZodiac',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PARTNER_BIRTH_DATE_OPEN,
      },
      content: {
        title: 'Partner’s date of birth',
        text:
          'Date of birth helps to determine your partner’s sun sign, numerology and compatibility with you.',
      },
      screenType: QuizStepTypes.DATE,
    },
    {
      id: 'partnerplace',
      dataKey: 'partnerPlace',
      locationDataKey: 'partnerLocation',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PARTNER_CITY_OPEN,
      },
      content: {
        title: 'Partner’s place of birth',
        text:
          'Place is important for determining your partner’s sun sign, numerology and compatibility.',
      },
      screenType: QuizStepTypes.PLACE,
    },
    {
      id: 'partnertime',
      dataKey: 'partnerTime',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PARTNER_BIRTH_TIME_OPEN,
      },
      content: {
        title: 'Partner’s time of birth',
        text:
          'Time of birth is important for determining your partner’s ascendant, houses, and moon position'
          + ' for the natal chart.',
        label: 'I don’t know the exact time of my partner’s birth',
      },
      screenType: QuizStepTypes.TIME,
    },
    {
      id: 'loading-2',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PROCESSING_PAGE_OPEN,
        baseEventProperties: {
          page_num: 2,
        },
      },
      content: {
        list: [
          'Connecting to data base',
          'Designing your partner’s natal chart',
          'Comparing your natal charts',
          'Looking for intersections',
          'Analyzing the overlaps',
        ],
      },
      canNotBackToScreen: true,
      screenType: QuizStepTypes.PROCESSING,
    },
    {
      id: 'wow',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_WOW_OPEN,
        baseEventProperties: {
          page_num: 3,
        },
      },
      content: {
        text:
          'That’s a <b>truly unique combination</b> of houses, rising signs, ascendants, and moon positions.'
          + '<br/>'
          + '<br/>'
          + 'Looks like you can definitely have an incredibly happy relationship with this person... '
          + 'if only you know how to avoid the mistakes while dealing with their unique traits '
          + 'formed under the strong influence of Mercury and Mars the day when they were born.',
        image: WowImage,
      },
      screenType: QuizStepTypes.INFORMATIONAL,
    },
    {
      id: 'meFirst',
      dataKey: 'meFirst',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PARTNER_MEFIRST_OPEN,
      },
      content: {
        title: 'My partner sometimes embrace a "me first" attitude',
        answers: [
          { text: 'Yes, that’s true', emoji: 'unamused' },
          { text: 'Maybe, I’m not sure', emoji: 'thinking_face' },
          { text: 'Definitely no', emoji: 'relieved' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      id: 'straightforward',
      dataKey: 'straightforward',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PARTNER_STRAIGHTFORWARD_OPEN,
      },
      content: {
        title: 'My partner is always straightforward and sincere with their intentions',
        answers: [
          { text: 'Yes, that’s true', emoji: 'slightly_smiling_face' },
          { text: 'Maybe, I’m not sure', emoji: 'thinking_face' },
          { text: 'Definitely no', emoji: 'confused' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      id: 'final',
      dataKey: 'final',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PARTNER_FINAL_DECISION_OPEN,
      },
      content: {
        title: 'My partner tend to waver too much when trying to make final decisions',
        answers: [
          { text: 'Yes, that’s true', emoji: 'pensive' },
          { text: 'Maybe, I’m not sure', emoji: 'confused' },
          { text: 'Definitely no', emoji: 'relieved' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      id: 'detail',
      dataKey: 'detail',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PARTNER_BIGPICTURE_OPEN,
      },
      content: {
        title: 'Is your partner actually more of a detail-oriented or a big picture person?',
        answers: [
          { text: 'Detail-oriented', emoji: 'jigsaw' },
          { text: 'Big picture', emoji: 'frame_with_picture' },
          { text: 'Something in between', emoji: 'scales' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      id: 'describesBest',
      dataKey: 'describesBest',
      hasVerticalOrientation: true,
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PARTNER_DESCRIBESBEST_OPEN,
      },
      content: {
        title: 'Which of these words actually describe your partner best?',
        answers: [
          { text: 'Adventurous', emoji: 'face_with_cowboy_hat' },
          { text: 'Reliable', emoji: 'relieved' },
          { text: 'Outgoing', emoji: 'speech_balloon' },
          { text: 'Compassionate', emoji: 'pleading_face' },
          { text: 'Idealistic', emoji: '100' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      id: 'introvert',
      dataKey: 'introvert',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PARTNER_INTROVERT_OPEN,
      },
      content: {
        title: 'Is your partner an introvert or an extrovert?',
        answers: [
          { text: 'Introvert', emoji: 'upside_down_face' },
          { text: 'Extrovert', emoji: 'smiley' },
          { text: 'Something in between', emoji: 'scales' },
        ],
      },
      screenType: QuizStepTypes.ANSWER,
    },
    {
      id: 'loading-3',
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_PROCESSING_PAGE_OPEN,
        baseEventProperties: {
          page_num: 3,
        },
      },
      content: {
        list: [
          'Designing your unique astronomical snapshots',
          'Analyzing your natal charts',
          'Comparing your astrology readings',
          'Picking the best insights',
          'Preparing your compatibility report',
        ],
      },
      canNotBackToScreen: true,
      screenType: QuizStepTypes.PROCESSING,
    },
    {
      id: 'email',
      dataKey: 'email',
      quizName: QUIZ_NAMES.ASTROMIX,
      analytic: {
        baseEvent: QUIZ_ANALYTIC_EVENTS.FLOW_EMAILFORM_OPEN,
        name: ANALYTICS_NAME.ASTROMIX_STANDARD,
      },
      content: {
        title:
          '<b>Enter your email to find out how compatible you are with your partner<b>',
        text:
          'We respect your privacy and are committed to'
          + ' protecting your personal data',
        label: 'I would like to receive my report and personalized offers'
          + ' & updates from Astromix via this email.',
      },
      screenType: QuizStepTypes.EMAIL,
    },
  ],
};
