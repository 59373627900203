import { PurchaseProductName } from 'src/common/types';
import { useProductDescription } from './useProductDescription';
import { centsToDollars } from '../utils/money';

export function useProductPrice(productName: PurchaseProductName) {
  const { product, error, loadingStatus } = useProductDescription(productName);

  return {
    price: product ? centsToDollars(product.price) : 0,
    error,
    loadingStatus,
  };
}
