import React from 'react';
import EmailFormContainer from 'src/astromix/components/Quiz/EmailForm';
import Header from 'src/astromix/components/Header';
import { Background } from 'src/astromix/components/Quiz/styled';
import { useQuizStep } from 'src/common/contexts/QuizContext';
import { EmailStepData } from 'src/astromix/types';
import useEmailForm from './useEmailForm';

export type EmailQuizStepProps = { id: string };

const EmailForm = ({ id }: EmailQuizStepProps) => {
  const { stepData, back, next } = useQuizStep(id);

  const { content, quizName, analytic } = stepData as EmailStepData;

  const { onBack, signUpUser, initialValue } = useEmailForm({
    quizName, userInformationKey: id, back, next, analytic,
  });

  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <Background />
      <EmailFormContainer
        title={content.title}
        text={content.text}
        label={content.label}
        onSubmit={signUpUser}
        initialValue={initialValue}
      />
    </>
  );
};

export default EmailForm;
