import React from 'react';
import Header from 'src/astromix/components/Header';
import ProgressBar from 'src/common/components/ProgressBar';
import AnswersContainer from 'src/astromix/components/Quiz/Answers';
import { useQuizStep } from 'src/common/contexts/QuizContext';
import { AnswerStepData } from 'src/astromix/types';
import useAnswer from './useAnswer';

export type AnswerPageProps = { id: string };

const Answer = ({ id }: AnswerPageProps) => {
  const { back, stepData, next } = useQuizStep(id);

  const {
    hasVerticalOrientation,
    content,
    stepOrder,
    totalSteps,
    dataKey,
    analytic,
  } = stepData as AnswerStepData;

  const { onBack, onAnswerClick } = useAnswer({
    dataKey, back, next, analytic,
  });

  return (
    <>
      <Header onBack={onBack} />
      <ProgressBar step={stepOrder} totalSteps={totalSteps} />
      <AnswersContainer
        answers={content.answers}
        title={content.title}
        onAnswerClick={onAnswerClick}
        hasVerticalOrientation={hasVerticalOrientation}
      />
    </>
  );
};

export default Answer;
