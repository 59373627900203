import { useCallback } from 'react';
import { parseQueryParams } from 'src/common/utils/query';
import { useDispatch } from 'react-redux';
import { doSignUpByToken } from 'src/astromix/store/userProfile';

export function useSignInByToken() {
  const dispatch = useDispatch();

  const signInByToken = useCallback(async () => {
    const parsedQuery = parseQueryParams();

    if (parsedQuery.token && typeof parsedQuery.token === 'string') {
      dispatch(doSignUpByToken(parsedQuery.token));
    }
  }, [dispatch]);

  return {
    signInByToken,
  };
}
