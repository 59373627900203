import React, { useState } from 'react';
import Select from 'src/astromix/components/Select/Select';
import Title from 'src/astromix/components/Title';
import { generateDates } from 'src/common/utils/generateDates';
import { transformToMinutesFormat } from 'src/common/utils/transformToMinutesFormat';
import { Content, SelectContainer, Text } from './styled';
import { ContainerMain, Image } from '../styled';
import Checkbox from '../../Checkbox';

export interface DateProps {
  title: string;
  text: string;
  image: string;
  label: string;
  value: {
    hours: string,
    minutes: string,
    part: string,
  };
  onChange: (value: {
    hours: string,
    minutes: string,
    part: string,
  }) => void
}

const hoursList = generateDates(1, 12, 'increment');
const minutesList = transformToMinutesFormat(generateDates(0, 59, 'increment'));
const partOfDay = ['AM', 'PM'];

const TimeContainer: React.FC<DateProps> = ({
  text,
  title,
  image,
  label,
  value,
  onChange,
}) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <ContainerMain>
      <Content>
        <Image src={image} alt="image" width={203} height={203} />
        <Title>{title}</Title>
        <Text>{text}</Text>
        <SelectContainer>
          <Select options={hoursList} name="hours" value={value.hours} onChange={handleTimeChange} />
          <Select
            options={minutesList}
            name="minutes"
            value={value.minutes}
            onChange={handleTimeChange}
          />
          <Select options={partOfDay} name="part" value={value.part} onChange={handleTimeChange} />
        </SelectContainer>
        <Checkbox name="checkbox" label={label} checked={checked} onChange={handleCheckboxChange} />
      </Content>
    </ContainerMain>
  );
};

export default TimeContainer;
