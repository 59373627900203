import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LANDINGS } from 'src/astromix/constants';
import { joinUrls } from 'src/common/utils/joinUrls';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';

export function useExtendedLanding(baseUrl: string) {
  const history = useHistory();
  const { analyticsService } = useAstromixServices();

  const getReport = () => {
    analyticsService.extendedLpGetReport();
    history.push(joinUrls(baseUrl, LANDINGS.BILLING));
  };

  useEffect(() => {
    analyticsService.extendedLpOpened();
  }, [analyticsService]);

  return {
    getReport,
  };
}
