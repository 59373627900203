import React, { FC } from 'react';
import {
  Wrapper, StyledTitle, Text, Link, GuaranteeImage,
} from './styled';

interface MoneyBackProps {
  className?: string;
}

const MoneyBack: FC<MoneyBackProps> = ({ className }) => (
  <Wrapper className={className}>
    <GuaranteeImage />
    <StyledTitle>Money-back guarantee</StyledTitle>
    <Text>
      We are convinced that this report will help you get a deeper understanding of your partner and
      how you can improve your relationship. After all of our stellar customer reviews, we are ready
      to return your money if you feel that this report doesn’t provide any value. Find more about
      applicable limitations in our
      {/* TODO: add path */}
      <Link to="/"> Money-back policy</Link>
      .
    </Text>
  </Wrapper>
);

export default MoneyBack;
