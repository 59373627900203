import React, { useEffect, useState } from 'react';
import ProcessingLoaderCircle from './ProcessingLoaderCircle';
import { Block, Container, Value } from './styled';

export type ProcessingLoaderProps = {
  onFinished: () => void;
};

const ProcessingLoader = ({ onFinished }: ProcessingLoaderProps) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    const setTimeoutValue = (time: number) => setTimeout(() => {
      setValue((prev) => prev + 1);
    }, time);

    if (value !== 100) {
      timer = value === 60 || value === 90 ? setTimeoutValue(1000) : setTimeoutValue(60);
    } else {
      setTimeout(() => onFinished(), 1500);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [onFinished, value]);

  return (
    <Container>
      <ProcessingLoaderCircle value={value} />
      <Block>
        <Value>
          {value}
          %
        </Value>
      </Block>
    </Container>
  );
};

export default ProcessingLoader;
