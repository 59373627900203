import React from 'react';
import { PurchaseProductName } from 'src/common/types';
import { PriceTypes } from 'src/astromix/components/Price';
import {
  AdvisoryPlansWrapper,
  Label,
  Input,
  Description,
  Title,
  TotalPrice,
  StyledPrice,
  PriceSection,
  PricePeriod,
  OldPrice,
} from './styled';

interface AdvisoryPlansProps {
  value: Partial<PurchaseProductName>;
  handleChange: (value: Partial<PurchaseProductName>) => void;
}

const AdvisoryPlans = ({ value, handleChange }: AdvisoryPlansProps) => (
  <AdvisoryPlansWrapper>
    <Input
      type="radio"
      name="advisory-plan"
      id="3-month-plan"
      value={PurchaseProductName.ASTROMIX_7D_TRIAL_3M_9999}
      checked={value === PurchaseProductName.ASTROMIX_7D_TRIAL_3M_9999}
      onChange={(e) => handleChange(e.target.value as Partial<PurchaseProductName>)}
    />
    <Label htmlFor="3-month-plan">
      <Description>
        <Title>3-MONTH ADVISORY PLAN</Title>
        <TotalPrice>
          <OldPrice>$260</OldPrice>
          {' '}
          $99.99 per 3 months
        </TotalPrice>
      </Description>
      <PriceSection>
        <StyledPrice type={PriceTypes.SELECTED}>$1.08</StyledPrice>
        <PricePeriod>per day</PricePeriod>
      </PriceSection>
    </Label>
    <Input
      type="radio"
      name="advisory-plan"
      id="1-month-plan"
      value={PurchaseProductName.ASTROMIX_7D_TRIAL_1M_4999}
      checked={value === PurchaseProductName.ASTROMIX_7D_TRIAL_1M_4999}
      onChange={(e) => handleChange(e.target.value as Partial<PurchaseProductName>)}
    />
    <Label htmlFor="1-month-plan">
      <Description>
        <Title>1-MONTH ADVISORY PLAN</Title>
        <TotalPrice>
          <OldPrice>$80</OldPrice>
          {' '}
          $49.99 per month
        </TotalPrice>
      </Description>
      <PriceSection>
        <StyledPrice type={PriceTypes.SELECTED}>$1.67</StyledPrice>
        <PricePeriod>per day</PricePeriod>
      </PriceSection>
    </Label>
    <Input
      type="radio"
      name="advisory-plan"
      id="1-week-plan"
      value={PurchaseProductName.ASTROMIX_7D_TRIAL_1W_2000}
      checked={value === PurchaseProductName.ASTROMIX_7D_TRIAL_1W_2000}
      onChange={(e) => handleChange(e.target.value as Partial<PurchaseProductName>)}
    />
    <Label htmlFor="1-week-plan">
      <Description>
        <Title>1-WEEK ADVISORY PLAN</Title>
        <TotalPrice>$20 per week</TotalPrice>
      </Description>
      <PriceSection>
        <StyledPrice type={PriceTypes.SELECTED}>$2.85</StyledPrice>
        <PricePeriod>per day</PricePeriod>
      </PriceSection>
    </Label>
  </AdvisoryPlansWrapper>
);

export default AdvisoryPlans;
