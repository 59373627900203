import React, {
  useCallback, useContext, useMemo,
} from 'react';
import { QuizData, BaseQuizStepData } from 'src/common/types';
import {
  Redirect, Route, Switch, useHistory,
} from 'react-router-dom';
import { delay } from 'src/common/utils/delay';
import { joinUrls } from 'src/common/utils/joinUrls';

export type QuizContextData = {
  getStepData: (id: string) => BaseQuizStepData | undefined;
  // userPreferences: Record<string, unknown>,
  // updateUserPreferences: (preference: string, preferenceData: unknown) => void;
  // signUpUser: (email:string) => Promise<void>;
  next: (id: string) => Promise<void>;
  back: (id: string) => Promise<void>;
}

const QuizContext = React.createContext<QuizContextData>(undefined!);

export type QuizProviderProps = {
  children?: React.ReactNode;
  data: QuizData;
  stepComponentFactory: (stepData: BaseQuizStepData) => React.ReactNode;
  // userPreferences: Record<string, unknown>,
  // updateUserPreferences: (preference: string, preferenceData: unknown) => void;
  // signUpUser: (email:string) => Promise<void>;
};

export const QuizProvider = ({
  data,
  children,
  stepComponentFactory,
  // userPreferences,
  // updateUserPreferences,
  // signUpUser,
}: QuizProviderProps) => {
  const history = useHistory();

  const { baseUrl, steps, nextStage } = data;

  const makeRoutePath = (subPath: string) => joinUrls(baseUrl, subPath);

  const quizStepsMap = useMemo(() => steps.reduce((acc: { [id: string]: BaseQuizStepData }, step, index, arr) => {
    acc[step.id] = {
      ...step,
      stepOrder: index + 1,
      totalSteps: arr.length,
    };
    return acc;
  }, {}), [data]);

  const getStepData = (id: string) => quizStepsMap[id];

  const getNextStep = (id: string) => {
    const foundIndex = data.steps.findIndex((step) => step.id === id);
    return data.steps[foundIndex + 1];
  };

  const getStepForMoveBack = (id: string) => {
    const foundIndex = data.steps.findIndex((step) => step.id === id);
    const splicedSteps = data.steps.slice(0, foundIndex);
    const reversedSteps = splicedSteps.reverse();
    return reversedSteps.find((step) => !step.canNotBackToScreen);
  };

  const next = async (id: string) => {
    await delay(500);
    const nextStep = getNextStep(id);

    if (!nextStep) {
      history.push(nextStage);
      return;
    }

    history.push(makeRoutePath(nextStep.id));
  };

  const back = async (id: string) => {
    await delay(300);
    const prevStep = getStepForMoveBack(id);

    if (!prevStep) return;

    history.push(makeRoutePath(prevStep.id));
  };

  return (
    <QuizContext.Provider
      value={{
        // signUpUser,
        // userPreferences,
        // updateUserPreferences,
        getStepData,
        next,
        back,
      }}
    >
      <Switch>
        {steps.map((stepData: BaseQuizStepData) => {
          const Component = stepComponentFactory(stepData);

          return (
            <Route key={stepData.id} exact path={makeRoutePath(stepData.id)}>
              {Component}
            </Route>
          );
        })}
        <Redirect to={makeRoutePath(steps[0].id)} />
      </Switch>
      {children}
    </QuizContext.Provider>
  );
};

export const useQuizStep = (id: string) => {
  const {
    back,
    next,
    getStepData,
    // updateUserPreferences,
    // userPreferences,
    // signUpUser,
  } = useContext(QuizContext);

  const stepData = useMemo(() => getStepData(id), [id]);

  const backStep = useCallback(() => back(id), [id]);

  const nextStep = useCallback(() => next(id), [id]);

  return {
    back: backStep,
    next: nextStep,
    stepData,
    // updateUserPreferences,
    // userPreferences,
    // signUpUser,
  };
};
