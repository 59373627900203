import { useEffect } from 'react';
import { useLastLocation } from 'react-router-last-location';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { BASE_ROUTES } from 'src/astromix/constants';

export function useFaq() {
  const { analyticsService } = useAstromixServices();

  const lastLocation = useLastLocation();

  useEffect(() => {
    analyticsService.faqPageOpened(BASE_ROUTES.FAQ, lastLocation?.pathname || '');
  }, [analyticsService]);
}
