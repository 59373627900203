import { PurchaseProductName } from 'src/common/types';
import { useCallback, useEffect } from 'react';
import { useCreatePayment } from './useCreatePayment';
import { useRedirectToMainApp } from './useRedirectToMainApp';
import { useSignInByToken } from './useSignInByToken';
// import useServices from './useServices';

export function useEmailMarketingLanding(
  productName: PurchaseProductName,
  // pageOrder: number,
) {
  const { price, getPaymentUrls } = useCreatePayment(productName);

  const { redirect } = useRedirectToMainApp();
  // const { analyticsService } = useServices();

  const { signInByToken } = useSignInByToken();

  // const getReport = () => {
  //   analyticsService.emailMarketingLpGetProduct(productName, pageOrder);
  // };

  useEffect(() => {
    signInByToken();
  }, [signInByToken]);

  // useEffect(() => {
  //   analyticsService.emailMarketingLpOpened(pageOrder);
  // }, [pageOrder, analyticsService]);

  const paymentSucceed = useCallback(async () => {
    await redirect();
  }, [redirect]);

  return {
    price,
    getPaymentUrls,
    paymentSucceed,
    // getReport,
  };
}
