import React from 'react';
import { ButtonProps as ButtonMain } from 'src/astromix/components/Button/types';
import { Button } from './styled';

interface ButtonProps extends ButtonMain {
  aspectRatio: number;
  tabletAspectRatio: number;
  desktopAspectRatio: number;
}

const ImagedPageSectionButton = ({
  children,
  onClick,
  variant = 'success',
  className,
  aspectRatio,
  tabletAspectRatio,
  desktopAspectRatio,
  loading,
}: ButtonProps) => (
  <Button
    loading={loading}
    aspectRatio={aspectRatio}
    tabletAspectRatio={tabletAspectRatio}
    desktopAspectRatio={desktopAspectRatio}
    className={className}
    type="button"
    onClick={onClick}
    variant={variant}
  >
    {children}
  </Button>
);

export default ImagedPageSectionButton;
