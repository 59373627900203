import React from 'react';
import { config } from 'src/astromix/config';
import {
  PeopleAskWrapper,
  AccordionTitle,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  AccordionWrapper,
  DropdownIcon,
  StyledQuestionMark,
  Link,
  AccordionDetailsWrapper,
} from './styled';

export type SectionFeedbackProps = {
  onGetReport: () => void;
};

const SectionFeedback = () => (
  <PeopleAskWrapper>
    <AccordionTitle>People often ask</AccordionTitle>
    <AccordionWrapper>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<DropdownIcon />}>
          <StyledQuestionMark />
          What happens after I place an order?
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsWrapper>
            After you place your order our astrologers will get to work! Based on the answers you
            provided, our experts will craft your compatibility report tailored to your unique natal
            chart. All the reports are verified by our chief astrologer before they are sent to you
            so you can be certain about the relationship guide and all the tips inside.
          </AccordionDetailsWrapper>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<DropdownIcon />}>
          <StyledQuestionMark />
          How will I receive my personalised horoscope & compatibility report?
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsWrapper>
            Your report will arrive in your inbox within 3 hours. In case you can&apos;t find your plan
            3 hours after you placed an order, please, check the Spam folder or contact us via
            {' '}
            <Link href={config.supportCenter}>Support center</Link>
            .
          </AccordionDetailsWrapper>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion>
        <StyledAccordionSummary expandIcon={<DropdownIcon />}>
          <StyledQuestionMark />
          For how long will I have access to my report?
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <AccordionDetailsWrapper>
            There are no deadlines in terms of your access. All the insights stay with you forever!
          </AccordionDetailsWrapper>
        </StyledAccordionDetails>
      </StyledAccordion>
    </AccordionWrapper>
  </PeopleAskWrapper>
);

export default SectionFeedback;
