import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { useDispatch } from 'react-redux';
import moment from 'moment';
import { setUserInformation } from 'src/astromix/store/userProfile';
import { generateMonthDays } from 'src/common/utils/generateDates';
import { ZodiacService } from 'src/common/services';
import { QUIZ_ANALYTIC_EVENTS } from 'src/astromix/services/analytics';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';

export default function useDate({
  dataKey,
  next,
  back,
  zodiacDataKey,
  analytic,
}: {
  dataKey: string;
  zodiacDataKey: string;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
  }
  back: () => Promise<void>;
  next: () => Promise<void>;
}) {
  const dispatch = useDispatch();

  const [date, setDate] = useState({
    month: 'Jan',
    day: '01',
    year: '1990',
  });

  const { analyticsService } = useAstromixServices();

  useEffect(() => {
    analyticsService.trackQuizEvent(analytic.baseEvent);
  }, [analyticsService, analytic]);

  const daysList = useMemo(() => generateMonthDays(date.month, date.year), [date.month, date.year]);

  const handleButtonClick = () => {
    const dateOfBirth = moment(`${date.month} ${date.day} ${date.year}`, 'MMM D YYYY').format(
      'YYYY-MM-DD',
    );

    if (dateOfBirth === 'Invalid date') {
      const newDateOfBirth = moment(`${date.month} 01 ${date.year}`, 'MMM D YYYY').format(
        'YYYY-MM-DD',
      );
      const zodiacSign = ZodiacService.getZodiacSignByDate(newDateOfBirth);
      dispatch(setUserInformation(dataKey, newDateOfBirth));
      dispatch(setUserInformation(zodiacDataKey, zodiacSign));
      return next();
    }

    const zodiacSign = ZodiacService.getZodiacSignByDate(dateOfBirth);
    dispatch(setUserInformation(dataKey, dateOfBirth));
    dispatch(setUserInformation(zodiacDataKey, zodiacSign));

    return next();
  };

  const handleDateChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setDate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);

  const onBack = () => back();

  return {
    handleButtonClick,
    date,
    handleDateChange,
    daysList,
    onBack,
  };
}
