import React from 'react';
import { GuaranteeInscriptionProps as GuaranteeInscriptionMain } from 'src/astromix/components/GuaranteeInscription';
import { ColorVariants } from 'src/common/theme/types';
import { StyledInscription, InscriptionWrapper } from './styled';

interface ImagedPageSectionProps extends GuaranteeInscriptionMain {
  aspectRatio?: number;
  tabletAspectRatio?: number;
  desktopAspectRatio?: number;
  icon?: React.ReactNode;
  linkClassName?: string;
  children: string;
  to?: string;
  className?: string;
  variant?: ColorVariants;
}

const ImagedPageSectionInscription = ({
  children,
  variant = 'primary',
  to,
  icon,
  className,
  linkClassName,
  aspectRatio,
  tabletAspectRatio,
  desktopAspectRatio,
}: ImagedPageSectionProps) => (
  <InscriptionWrapper
    aspectRatio={aspectRatio}
    tabletAspectRatio={tabletAspectRatio}
    desktopAspectRatio={desktopAspectRatio}
    className={className}
  >
    {!!icon && icon}
    <StyledInscription to={to} variant={variant} className={linkClassName}>
      {children}
    </StyledInscription>
  </InscriptionWrapper>
);

export default ImagedPageSectionInscription;
