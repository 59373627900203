// @ts-ignore
import ABTesting from 'ab-testing';
import { UserDetectionStrategy } from './UserDetectionStrategy';
import { TestObjectType, ConfigType } from './types';

export class SplitSystemExperiment {
  private testObject: TestObjectType;

  private strategy: UserDetectionStrategy;

  private id: string;

  constructor(config: ConfigType, identificationStrategy: UserDetectionStrategy) {
    this.testObject = ABTesting.createTest(config.name, config.tests);
    this.strategy = identificationStrategy;
    this.id = this.strategy.getIdentifier();
  }

  get name() {
    return this.testObject.getName();
  }

  get group() {
    return this.testObject.getGroup(this.id);
  }

  test(functions: Array<Function>, that?: TestObjectType) {
    const testGroupName = this.group;
    this.testObject.test(testGroupName, functions, that);
  }
}
