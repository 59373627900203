/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ColorVariants } from 'src/common/theme/types';
import { ErrorContainer, ErrorText, InputContainer } from './styled';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean;
  errorText?: string;
  variant?: ColorVariants
};

const Input: React.FC<InputProps> = ({
  error, errorText, variant = 'primary', ...rest
}) => (
  <>
    <InputContainer {...rest} error={error} variant={variant} />
    <ErrorContainer>{error && <ErrorText>{errorText}</ErrorText>}</ErrorContainer>
  </>
);

export default React.memo(Input);
