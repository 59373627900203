import { ZodiacTypes } from 'src/common/types';
import aries from 'src/astromix/assets/images/extended-landing/zodiacs/aries.svg';
import partnerAries from 'src/astromix/assets/images/extended-landing/zodiacs/aries-partner.svg';
import aquarius from 'src/astromix/assets/images/extended-landing/zodiacs/aquarius.svg';
import partnerAquarius from 'src/astromix/assets/images/extended-landing/zodiacs/aquarius-partner.svg';
import cancer from 'src/astromix/assets/images/extended-landing/zodiacs/cancer.svg';
import partnerCancer from 'src/astromix/assets/images/extended-landing/zodiacs/cancer-partner.svg';
import capricorn from 'src/astromix/assets/images/extended-landing/zodiacs/capricorn.svg';
import partnerCapricorn from 'src/astromix/assets/images/extended-landing/zodiacs/capricorn-partner.svg';
import gemini from 'src/astromix/assets/images/extended-landing/zodiacs/gemini.svg';
import partnerGemini from 'src/astromix/assets/images/extended-landing/zodiacs/gemini-partner.svg';
import leo from 'src/astromix/assets/images/extended-landing/zodiacs/leo.svg';
import leoPartner from 'src/astromix/assets/images/extended-landing/zodiacs/leo-partner.svg';
import libra from 'src/astromix/assets/images/extended-landing/zodiacs/libra.svg';
import libraPartner from 'src/astromix/assets/images/extended-landing/zodiacs/libra-partner.svg';
import pisces from 'src/astromix/assets/images/extended-landing/zodiacs/pisces.svg';
import piscesPartner from 'src/astromix/assets/images/extended-landing/zodiacs/pisces-partner.svg';
import sagittarius from 'src/astromix/assets/images/extended-landing/zodiacs/sagittarius.svg';
import sagittariusPartner from 'src/astromix/assets/images/extended-landing/zodiacs/sagittarius-partner.svg';
import scorpio from 'src/astromix/assets/images/extended-landing/zodiacs/scorpio.svg';
import scorpioPartner from 'src/astromix/assets/images/extended-landing/zodiacs/scorpio-partner.svg';
import taurus from 'src/astromix/assets/images/extended-landing/zodiacs/taurus.svg';
import taurusPartner from 'src/astromix/assets/images/extended-landing/zodiacs/taurus-partner.svg';
import virgo from 'src/astromix/assets/images/extended-landing/zodiacs/virgo.svg';
import virgoPartner from 'src/astromix/assets/images/extended-landing/zodiacs/virgo-partner.svg';

export const useGetZodiacImage = (zodiacSign: ZodiacTypes, partnerZodiacSign: ZodiacTypes) => {
  const ZODIAC_IMG: { [key: string]: { user: string; partner: string } } = {
    [ZodiacTypes.ARIES]: { user: aries, partner: partnerAries },
    [ZodiacTypes.AQUARIUS]: { user: aquarius, partner: partnerAquarius },
    [ZodiacTypes.CANCER]: { user: cancer, partner: partnerCancer },
    [ZodiacTypes.CAPRICORN]: { user: capricorn, partner: partnerCapricorn },
    [ZodiacTypes.GEMINI]: { user: gemini, partner: partnerGemini },
    [ZodiacTypes.LEO]: { user: leo, partner: leoPartner },
    [ZodiacTypes.LIBRA]: { user: libra, partner: libraPartner },
    [ZodiacTypes.PISCES]: { user: pisces, partner: piscesPartner },
    [ZodiacTypes.SAGITTARIUS]: { user: sagittarius, partner: sagittariusPartner },
    [ZodiacTypes.SCORPIO]: { user: scorpio, partner: scorpioPartner },
    [ZodiacTypes.TAURUS]: { user: taurus, partner: taurusPartner },
    [ZodiacTypes.VIRGO]: { user: virgo, partner: virgoPartner },
  };

  return {
    zodiacImage: ZODIAC_IMG[zodiacSign] ? ZODIAC_IMG[zodiacSign].user : '',
    zodiacPartnerImage: ZODIAC_IMG[partnerZodiacSign] ? ZODIAC_IMG[partnerZodiacSign].partner : '',
  };
};
