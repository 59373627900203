import React, { useCallback, useEffect, useState } from 'react';
import { LoadingStatuses } from 'src/common/types';
import { PaymentFailedDialog } from 'src/common/components/PaymentDialog';
import Header from 'src/astromix/components/Header';
import { BASE_ROUTES } from 'src/astromix/constants';
import SectionFooter from './SectionFooter';
import SectionHeader from './SectionHeader';
import SectionInfo from './SectionInfo';
import { Content, Root } from '../styled';
import { useNumerology } from '../useNumerology';

const Numerology = () => {
  const [isFailedDialogVisible, setFailedDialogVisible] = useState(false);

  const {
    skip,
    getReport,
    loadingStatus,
    error,
    price,
    priceLoadingStatus,
  } = useNumerology(BASE_ROUTES.ASTROMIX);

  useEffect(() => {
    setFailedDialogVisible(!!error);
  }, [error]);

  const onCloseFailedPayment = useCallback(() => {
    setFailedDialogVisible(false);
  }, []);

  const tryAgain = useCallback(() => {
    setFailedDialogVisible(false);
    getReport();
  }, [getReport]);

  const isLoading = loadingStatus === LoadingStatuses.PENDING || priceLoadingStatus === LoadingStatuses.PENDING;

  return (
    <Root>
      <Content>
        <Header buttonBack={false} variant="secondary" />
        <SectionHeader />
        <SectionInfo
          isLoading={isLoading}
          onGetReport={getReport}
          onHandleSkipClick={skip}
          price={price}
        />
        <SectionFooter
          isLoading={isLoading}
          onGetReport={getReport}
          onHandleSkipClick={skip}
          price={price}
        />
        <PaymentFailedDialog
          onTryAgain={tryAgain}
          onClose={onCloseFailedPayment}
          isOpen={isFailedDialogVisible}
        />
      </Content>
    </Root>
  );
};

export default Numerology;
