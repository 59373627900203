/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Input from 'src/astromix/components/Input';
import guarantee from 'src/astromix/assets/images/guarantee.svg';
import { useFormik, FormikHelpers } from 'formik';
import Checkbox from 'src/astromix/components/Checkbox';
import {
  Content, Form, Text, Title, Wrapper, CheckboxWrapper,
} from './styled';
import { ContainerMain, QuizButton } from '../styled';
import { validationSchema } from './validationSchema';
import EmailSuggestionsList from './EmailSuggestionsList';

export type EmailFormHelpers = FormikHelpers<{
  email: string;
  emailConsent: boolean;
}>;

interface EmailFormSubmit {
  email: string;
  emailConsent: boolean;
}

export interface EmailFormContainerProps {
  title: string;
  text: string;
  label: string;
  onSubmit: (data: EmailFormSubmit, helpers: EmailFormHelpers) => Promise<void>;
  initialValue: string;
}

const EmailFormContainer: React.FC<EmailFormContainerProps> = ({
  text,
  title,
  initialValue,
  onSubmit,
  label,
}) => {
  const {
    getFieldProps, errors, touched, isSubmitting, handleSubmit, values, setFieldValue,
  } = useFormik({
    initialValues: {
      email: initialValue,
      emailConsent: true,
    },
    validationSchema,
    onSubmit: async (submitValues, helpers) => {
      await onSubmit(submitValues, helpers);
    },
  });

  return (
    <ContainerMain>
      <Title variant="secondary" dangerouslySetInnerHTML={{ __html: title }} />
      <Form onSubmit={handleSubmit}>
        <Content>
          <Input
            {...getFieldProps('email')}
            error={touched.email && !!errors.email}
            errorText={errors.email}
            placeholder="example@email.com"
            variant="secondary"
          />
          <EmailSuggestionsList
            input={values.email}
            onSelect={(value) => setFieldValue('email', value)}
          />
          <Wrapper>
            <img src={guarantee} alt="guarantee" />
            <Text>{text}</Text>
          </Wrapper>
          <CheckboxWrapper>
            <Checkbox
              {...getFieldProps('emailConsent')}
              checked={values.emailConsent}
              label={label}
            />
          </CheckboxWrapper>
        </Content>
        <QuizButton variant="secondary" type="submit" disabled={isSubmitting}>
          Continue
        </QuizButton>
      </Form>
    </ContainerMain>
  );
};

export default EmailFormContainer;
