import React from 'react';
import Emoji from 'src/common/components/Emoji';
import {
  EmojiBorder, Item, List, Text,
} from './styled';

interface AnswersVerticalOrientationProps {
  answers: Array<{ text: string; emoji: string }>;
  onAnswerClick: (choice: string) => void;
}

const AnswersVerticalOrientation = ({
  answers,
  onAnswerClick,
}: AnswersVerticalOrientationProps) => {
  const handleClick = (choice: string) => () => onAnswerClick(choice);
  return (
    <List>
      {answers.map(({ text, emoji }) => (
        <Item key={text} onClick={handleClick(text)}>
          <EmojiBorder>
            <Text key={text}>{text}</Text>
            <Emoji emoji={emoji} />
          </EmojiBorder>
        </Item>
      ))}
    </List>
  );
};

export default AnswersVerticalOrientation;
