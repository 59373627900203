import React from 'react';
import Emoji from 'src/common/components/Emoji';
import {
  EmojiBorder, Item, List, Text,
} from './styled';

interface AnswersHorizontalOrientationProps {
  answers: Array<{ text: string, emoji: string}>;
  onAnswerClick: (choice: string) => void;
}

const AnswersHorizontalOrientation = ({ answers, onAnswerClick }: AnswersHorizontalOrientationProps) => {
  const handleClick = (choice: string) => () => onAnswerClick(choice);

  return (
    <List>
      {answers.map(({ text, emoji }) => (
        <Item key={text} onClick={handleClick(text)}>
          <EmojiBorder>
            <Emoji emoji={emoji} />
          </EmojiBorder>
          <Text key={text}>
            {text}
          </Text>
        </Item>
      ))}
    </List>
  );
};

export default AnswersHorizontalOrientation;
