import styled from 'styled-components';
import { ColorVariants } from 'src/common/theme/types';
import CircularProgress from 'src/common/components/CircularProgress';

export const ButtonLoadingIndicator = styled(CircularProgress)``;

export const ButtonStyled = styled.button<{ variant?: ColorVariants }>`
  background: ${(props) => (props.variant === 'secondary' && props.theme.colors.secondary)
          || (props.variant === 'success' && props.theme.colors.success)
          || props.theme.gradients.primary};
  color: ${(props) => (props.variant === 'secondary' && props.theme.colors.info)
          || props.theme.colors.secondary};
  width: 330px;
  max-width: 100%;
  height: 40px;
  z-index: 2;
  border-radius: 8px;
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  padding: 5px 10px;
  align-self: center;
  outline: none;
  border: none;
  cursor: ${(props) => props.disabled && 'not-allowed'};
  
  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 50px;
  }
  
  ${ButtonLoadingIndicator} {
    color: ${(props) => (props.variant === 'secondary' && props.theme.colors.info)
            || props.theme.colors.secondary} !important;
  }
`;

export const ButtonLabel = styled.span`
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
`;

export const ButtonIcon = styled.span`
  display: inherit;
  margin-left: -6px;
  margin-right: 12px;
`;
