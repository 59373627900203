import { applyMiddleware, createStore as createReduxStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { Services } from 'src/common/services';
import { AstromixServices } from 'src/astromix/services';
import { SplitSystem } from 'src/splitSystem';
import rootReducer from './rootReducer';
import { saveProgressToStorage, loadProgressFromStorage } from './storage';

export default function createStore(services: Services, astromixServices: AstromixServices, splitSystem: SplitSystem) {
  const thunkWithContext = thunk.withExtraArgument({ services: { ...services, ...astromixServices }, splitSystem });
  const persistedProgress = loadProgressFromStorage();

  const store = createReduxStore(
    rootReducer,
    persistedProgress,
    composeWithDevTools(applyMiddleware(thunkWithContext)),
  );

  store.subscribe(() => {
    const {
      ...progressState
    } = store.getState();

    saveProgressToStorage(progressState);
  });

  return store;
}
