import React from 'react';
import ImagedPageSection, {
  DPIScales,
  ImagedPageSectionHolder,
} from 'src/common/components/ImagedPageSection';
import ImagedPageSectionInscription from 'src/common/components/ImagedPageSectionInscription';
import styled from 'styled-components';

import webpXl from 'src/astromix/assets/images/billing/default/main/main-xl.webp';
import webpXl2x from 'src/astromix/assets/images/billing/default/main/main-xl-2x.webp';
import pngXl from 'src/astromix/assets/images/billing/default/main/main-xl.png';
import pngXl2x from 'src/astromix/assets/images/billing/default/main/main-xl-2x.png';
import webpMd from 'src/astromix/assets/images/billing/default/main/main-md.webp';
import webpMd2x from 'src/astromix/assets/images/billing/default/main/main-md-2x.webp';
import pngMd from 'src/astromix/assets/images/billing/default/main/main-md.png';
import pngMd2x from 'src/astromix/assets/images/billing/default/main/main-md-2x.png';
import webpXs from 'src/astromix/assets/images/billing/default/main/main-xs.webp';
import webpXs2x from 'src/astromix/assets/images/billing/default/main/main-xs-2x.webp';
import pngXs from 'src/astromix/assets/images/billing/default/main/main-xs.png';
import pngXs2x from 'src/astromix/assets/images/billing/default/main/main-xs-2x.png';
import { BASE_ROUTES } from 'src/astromix/constants';
import { ReactComponent as MoneyBack } from 'src/astromix/assets/images/money-back-guarantee.svg';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

const sectionWidth = 360;
const sectionHeight = 1173;
const sectionTabletWidth = 768;
const sectionTabletHeight = 1489;
const sectionDesktopWidth = 1440;
const sectionDesktopHeight = 1489;

const linkTopOffset = 1115;
const linkTopOffsetTablet = 1421;
const linkTopOffsetDesktop = 1421;

const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionTabletHeight} / ${sectionTabletWidth} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionDesktopHeight} / ${sectionDesktopWidth} * 100%);
    }
  }
`;

const SectionMain = () => (
  <ImagedSection originalUrl={sectionData.originalUrl} sources={sectionData.sources}>
    <ImagedPageSectionInscription
      to={BASE_ROUTES.PAYMENT_TERMS}
      icon={<MoneyBack />}
      variant="primaryLink"
      aspectRatio={linkTopOffset / sectionHeight}
      tabletAspectRatio={linkTopOffsetTablet / sectionTabletHeight}
      desktopAspectRatio={linkTopOffsetDesktop / sectionDesktopHeight}
    >
      Money-back guarantee
    </ImagedPageSectionInscription>
  </ImagedSection>
);

export default SectionMain;
