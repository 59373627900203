import styled from 'styled-components';
import { TitleText } from 'src/astromix/components/Title/styled';

export const Title = styled(TitleText)`
  margin-bottom: 50px;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  justify-content: flex-start;
  max-width: 250px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    max-width: 460px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  @media ${(props) => props.theme.breakpoints.tablet} {
    justify-content: space-between;
  }
`;

export const Item = styled.li`
  cursor: pointer;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  margin: 8px 10px;

  &:active {
    transform: scale(1.1);
    transition: all 100ms ease-in-out;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 75px;
    width: 75px;
  }
`;
