import {
  useEffect, useState, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useCreatePayment } from 'src/common/hooks/useCreatePayment';
import { PaymentOrderDescription, PaymentType, PurchaseProductName } from 'src/common/types';
import { BASE_ROUTES, LANDINGS } from 'src/astromix/constants';
import { joinUrls } from 'src/common/utils/joinUrls';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { useProductPrice } from 'src/common/hooks/useProductPrice';
import { usePaymentStatus } from 'src/astromix/hooks/usePaymentStatus';

const getTimerState = () => {
  try {
    const timerState = localStorage.getItem('isTimeUp');

    if (!timerState) {
      localStorage.setItem('isTimeUp', 'no');
      return false;
    }

    return timerState === 'yes';
  } catch (e) {
    console.error(e);
    return false;
  }
};

export function useBilling() {
  const [isTimeUp, setIsTimeUp] = useState(getTimerState());
  const [isAgree, setIsAgree] = useState(true);

  const monthPlan = isTimeUp ? PurchaseProductName.ASTROMIX_1M_2856 : PurchaseProductName.ASTROMIX_1M_1999_1M_2856;
  const [advisoryPlan, setAdvisoryPlan] = useState<PurchaseProductName>(monthPlan);

  const {
    cardPaymentProcessing,
    paymentSucceed: reportPaymentSucceed,
    paypalPaymentProcessing,
    paymentRetried,
    paymentFailed,
  } = usePaymentStatus(advisoryPlan);

  const history = useHistory();

  const { getPaymentUrls } = useCreatePayment(advisoryPlan);

  const { analyticsService } = useAstromixServices();

  const {
    price,
    loadingStatus: priceLoadingStatus,
  } = useProductPrice(advisoryPlan);

  useEffect(() => {
    analyticsService.billingLpOpened();
  }, [analyticsService]);

  useEffect(() => {
    if (isTimeUp) localStorage.setItem('isTimeUp', 'yes');
    if (advisoryPlan === PurchaseProductName.ASTROMIX_1M_1999_1M_2856) setAdvisoryPlan(monthPlan);
  }, [isTimeUp]);

  const paymentSucceed = useCallback(
    (orderData: PaymentOrderDescription, paymentType: PaymentType) => {
      reportPaymentSucceed(orderData, paymentType);
      history.push(joinUrls(BASE_ROUTES.ASTROMIX_1WEEK_1MONTH, LANDINGS.NUMEROLOGY));
    },
    [history, reportPaymentSucceed, analyticsService],
  );

  const getDiscount = useCallback(() => {
    analyticsService.billingLpGetAdditionalDiscount();
    history.push(joinUrls(BASE_ROUTES.ASTROMIX_1WEEK_1MONTH, LANDINGS.ADDITIONAL_DISCOUNT));
  }, [history, analyticsService]);

  const onAdditionalDiscountModalOpen = () => {
    analyticsService.billingLpAdditionalDiscountPopup();
  };

  return {
    price,
    priceLoadingStatus,
    getPaymentUrls,
    paymentSucceed,
    cardPaymentProcessing,
    paypalPaymentProcessing,
    paymentRetried,
    paymentFailed,
    getDiscount,
    onAdditionalDiscountModalOpen,
    advisoryPlan,
    setAdvisoryPlan,
    monthPlan,
    isTimeUp,
    setIsTimeUp,
    isAgree,
    setIsAgree,
  };
}
