import React from 'react';
import { ReactComponent as Guarantee } from 'src/astromix/assets/images/guarantee.svg';
import Address from 'src/common/components/Address';

import FAQ from './FAQ';
import {
  FooterWrapper,
  StyledButton,
  Skip,
  StyledPriceBlock,
  StyledTitle,
  SectionInscription,
} from './styled';

export type SectionInfoProps = {
  onGetReport: () => void;
  onHandleSkipClick: () => void;
  isLoading?: boolean;
  price: number;
}

const SectionFooter = ({
  onHandleSkipClick,
  isLoading,
  onGetReport,
  price,
}: SectionInfoProps) => (
  <FooterWrapper>
    <FAQ />
    <StyledTitle variant="secondary">Numerology guide</StyledTitle>
    <StyledPriceBlock
      titleContent="ONE TIME PAYMENT"
      price={`$${price}`}
    />
    <StyledButton
      loading={isLoading}
      onClick={onGetReport}
      variant="success"
    >
      { isLoading ? 'Processing' : 'Get my numerology guide' }
    </StyledButton>
    <Skip role="button" onClick={onHandleSkipClick}>
      Skip my special offer and proceed further
    </Skip>
    <SectionInscription icon={<Guarantee />} variant="secondary">
      Guaranteed security payments
    </SectionInscription>
    <Address variant="light" />
  </FooterWrapper>
);

export default SectionFooter;
