import React from 'react';
import PlaceListItem from '../PlaceListItem';
import { CitiesList } from '../PlaceContainer';
import { List } from './styled';

export type PlaceListProps = {
  onSelected: (coordinates: string[], location: string) => void;
  citiesList: Array<CitiesList>;
};

const PlaceList = ({ onSelected, citiesList = [] }: PlaceListProps) => {
  const handleClick = (coordinates: string[], value: string) => {
    onSelected(coordinates, value);
  };

  if (!citiesList) {
    return null;
  }

  return (
    <List>
      {citiesList.map((item) => (
        <PlaceListItem
          key={item.properties.id}
          coordinates={item.geometry.coordinates}
          country={item.properties.country}
          region={item.properties.region}
          city={item.properties.name}
          onClick={handleClick}
        />
      ))}
    </List>
  );
};

export default PlaceList;
