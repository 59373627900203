import styled from 'styled-components';
import { PriceTypes } from './PriceType';
import { PriceProps } from './Price';

export const Price = styled.span<PriceProps>`
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: ${({ theme, type = PriceTypes.CURRENT }) => (type === PriceTypes.OLD && theme.colors.inactive)
    || (type === PriceTypes.TOTAL && theme.colors.success)
    || (type === PriceTypes.SELECTED && theme.colors.secondary)
    || (type === PriceTypes.DISCOUNT && theme.colors.secondaryLight)
    || theme.colors.primary};

  background: ${({ theme, type }) => (type === PriceTypes.NEW && theme.gradients.primary)};
  -webkit-background-clip: ${({ type }) => (type === PriceTypes.NEW && 'text')};
  -webkit-text-fill-color: ${({ type }) => (type === PriceTypes.NEW && 'transparent')};
  text-decoration-line: ${(props) => (props.type === PriceTypes.OLD && 'line-through') || 'none'};
`;
