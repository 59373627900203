import styled from 'styled-components';

export const List = styled.div`
  position: absolute;
  width: 100%;
  background: ${(props) => props.theme.colors.secondary};
  border-radius: 10px;
  max-height: 265px;
  overflow: auto;

  @media ${(props) => props.theme.breakpoints.tablet} {
    max-height: 285px;
  }
`;
