import React from 'react';
import Title from 'src/astromix/components/Title';
import { PriceTypes } from 'src/astromix/components/Price';
import { BASE_ROUTES } from 'src/astromix/constants';
import PriceBlock from 'src/astromix/components/PriceBlock';
import {
  SectionWrapper,
  StyledGift,
  StyledPriceBlock,
  PriceWrapper,
  ShortDescription,
  StyledButton,
  ReportDescription,
  Link,
} from './styled';

export type SectionMainProps = {
  onGetReport: () => void;
  price: number;
  isLoading: boolean;
};

const SectionMain = ({ onGetReport, price, isLoading }: SectionMainProps) => (
  <SectionWrapper>
    <Title>Additional discount applied for you</Title>
    <StyledGift />
    <PriceWrapper>
      <PriceBlock titleContent="OLD PRICE" price="$20" />
      <StyledPriceBlock
        titleContent="NEW PRICE"
        price={`$${price}`}
        type={PriceTypes.NEW}
        discount="-75%"
      />
    </PriceWrapper>
    <ShortDescription>Instant access | No hidden fees | 7-day advisory trial</ShortDescription>
    <StyledButton onClick={onGetReport} variant="success" loading={isLoading}>Get my report</StyledButton>
    <ReportDescription>
      By ordering this report, you also get free unlimited access to chat
      with an expert psychic at AskNebula. Unless cancelled, it will automatically
      convert to our subscription at the full price of $99.99 per 3 months
      and will function according to our
      {/* TODO: Check correct path */}
      <Link to={BASE_ROUTES.TERMS}> Subscription terms</Link>
      .
    </ReportDescription>
  </SectionWrapper>
);

export default SectionMain;
