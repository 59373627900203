import React from 'react';
import address from 'src/common/assets/images/address/address-darker.png';
import { AddressWrapper, Image } from './styled';

export type AddressProps = {
  variant?: 'default' | 'light';
  className?: string;
};

const Address = ({ className }: AddressProps) => (
  <AddressWrapper className={className}>
    <Image src={address} />
  </AddressWrapper>
);

export default Address;
