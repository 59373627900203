import React from 'react';
import Modal from 'src/common/components/Modal';
import Button from 'src/astromix/components/Button';
import image from 'src/astromix/assets/images/heart-with-hands.svg';
import styles from './AdditionalDiscountModal.module.scss';

export type AdditionalDiscountModalProps = {
  isOpen?: boolean;
  onGetDiscount: () => void;
};

const AdditionalDiscountModal = ({ isOpen = false, onGetDiscount }: AdditionalDiscountModalProps) => (
  <Modal open={isOpen} onClose={() => {}}>
    <div className={styles.root}>
      <h3 className={styles.title}>
        Hold on!
        {' '}
        <br />
        Want an extra discount?
      </h3>
      <p className={styles.description}>
        86% of users with similar profiles report significant improvement of relationship
        within the first month of using our report.
      </p>
      <div className={styles.imageWrapper}>
        <img src={image} alt="discount" width={169} height={169} />
      </div>
      <p className={styles.tip}>
        We want you to be happy too.
        {' '}
        <br />
        So we’re offering an extra discount on your personalized report and guide.
      </p>
      <div className={styles.buttonWrapper}>
        <Button
          className={styles.button}
          onClick={onGetDiscount}
        >
          Get extra discount
        </Button>
      </div>
    </div>
  </Modal>
);

export default AdditionalDiscountModal;
