import {
  BaseQuizStepData, QUIZ_NAMES, QuizData, ANALYTICS_NAME,
} from 'src/common/types';
import { QUIZ_ANALYTIC_EVENTS } from '../services/analytics';

export enum QuizStepTypes {
  GENDER = 'GENDER',
  HOW = 'HOW',
  DATE = 'DATE',
  PLACE = 'PLACE',
  TIME = 'TIME',
  COLOR_PICKER = 'COLOR_PICKER',
  INFORMATIONAL = 'INFORMATIONAL',
  ANSWER = 'ANSWER',
  EMAIL = 'EMAIL',
  PROCESSING = 'PROCESSING',
}

export type QuizStepData = GenderStepData
  | HowStepData
  | DateStepData
  | PlaceStepData
  | TimeStepData
  | ColorPickerStepData
  | InformationalStepData
  | AnswerStepData
  | EmailStepData
  | ProcessingStepData;

export interface AstromixQuizData extends QuizData {
  analyticName: ANALYTICS_NAME;
  steps: Array<QuizStepData>;
}

export interface GenderStepData extends BaseQuizStepData {
  screenType: QuizStepTypes.GENDER;
  hasPolicy?: boolean;
  dataKey: string;
  analytic: {
    name?: ANALYTICS_NAME,
    baseEvent: QUIZ_ANALYTIC_EVENTS,
    baseEventProperties: Record<string, any>,
  };
  content: {
    title: string;
    text: string;
    subtitle?: string;
    subtext?: string;
    hasProgressBar?: string;
  };
}

export interface HowStepData extends BaseQuizStepData {
  screenType: QuizStepTypes.HOW;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
  }
  content: {
    title: string;
    subtitle: string;
    text: string;
    list: string[];
  }
}

export interface DateStepData extends BaseQuizStepData {
  dataKey: string;
  zodiacDataKey: string;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
  }
  content: {
    title: string;
    text: string;
  };
}

export interface PlaceStepData extends BaseQuizStepData {
  dataKey: string;
  locationDataKey: string;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
  }
  content: {
    title: string;
    text: string;
  };
}

export interface TimeStepData extends BaseQuizStepData {
  screenType: QuizStepTypes.TIME;
  dataKey: string;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
  }
  content: {
    title: string;
    text: string;
    label: string;
  };
}

export interface ColorPickerStepData extends BaseQuizStepData {
  screenType: QuizStepTypes.COLOR_PICKER;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
  }
  dataKey: string;
  content: {
    title: string;
  }
}

export interface InformationalStepData extends BaseQuizStepData {
  screenType: QuizStepTypes.INFORMATIONAL;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
    baseEventProperties: Record<string, any>,
  };
  content: {
    text: string;
    image: string;
  }
}

export interface AnswerStepData extends BaseQuizStepData {
  screenType: QuizStepTypes.ANSWER;
  dataKey: string;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
  }
  hasVerticalOrientation?: boolean;
  content: {
    title: string;
    answers: Array<{ text: string; emoji: string }>;
  };
}

export interface EmailStepData extends BaseQuizStepData {
  // TODO remove
  quizName: QUIZ_NAMES,
  screenType: QuizStepTypes.EMAIL;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
    name: ANALYTICS_NAME
  }
  dataKey: string;
  content: {
    title: string;
    text: string;
    label: string;
  };
}

export interface ProcessingStepData extends BaseQuizStepData {
  screenType: QuizStepTypes.PROCESSING;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
    baseEventProperties: Record<string, any>,
  };
  content: {
    list: string[];
  }
}
