import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useCreatePayment } from 'src/common/hooks/useCreatePayment';
import { PaymentOrderDescription, PaymentType, PurchaseProductName } from 'src/common/types';
import { BASE_ROUTES, LANDINGS } from 'src/astromix/constants';
import { joinUrls } from 'src/common/utils/joinUrls';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { useProductPrice } from 'src/common/hooks/useProductPrice';
import { usePaymentStatus } from 'src/astromix/hooks/usePaymentStatus';

export function useAdditionalDiscount() {
  const [advisoryPlan, setAdvisoryPlan] = useState(PurchaseProductName.ASTROMIX_1M_1499_1M_2856);
  const [isAgree, setIsAgree] = useState(true);
  const { analyticsService } = useAstromixServices();

  const {
    cardPaymentProcessing,
    paymentSucceed: reportPaymentSucceed,
    paypalPaymentProcessing,
    paymentRetried,
    paymentFailed,
  } = usePaymentStatus(advisoryPlan);

  const history = useHistory();
  const { getPaymentUrls } = useCreatePayment(advisoryPlan);

  const paymentSucceed = useCallback(
    (orderData: PaymentOrderDescription, paymentType: PaymentType) => {
      reportPaymentSucceed(orderData, paymentType);
      history.push(joinUrls(BASE_ROUTES.ASTROMIX_1WEEK_1MONTH, LANDINGS.NUMEROLOGY));
    },
    [history, reportPaymentSucceed, analyticsService],
  );

  const { price, loadingStatus } = useProductPrice(advisoryPlan);

  const getReport = () => {
    analyticsService.additionalDiscountLpGetReport(advisoryPlan);
  };

  useEffect(() => {
    analyticsService.additionalDiscountLpOpened();
  }, [analyticsService]);

  return {
    price,
    advisoryPlan,
    setAdvisoryPlan,
    loadingStatus,
    paymentSucceed,
    cardPaymentProcessing,
    paypalPaymentProcessing,
    paymentRetried,
    paymentFailed,
    getPaymentUrls,
    getReport,
    isAgree,
    setIsAgree,
  };
}
