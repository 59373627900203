import React from 'react';
import Select from 'src/astromix/components/Select/Select';
import Title from 'src/astromix/components/Title';
import { generateDates } from 'src/common/utils/generateDates';
import { Content, SelectContainer, Text } from './styled';
import { ContainerMain, Image } from '../styled';

export interface DateProps {
  title: string;
  text: string;
  image: string;
  daysList: string[];
  handleDateChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  date: {
    month: string;
    day: string;
    year: string;
  };
}

const monthsList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const yearsList = generateDates(1940, 2004, 'decrement');

const DateContainer: React.FC<DateProps> = ({
  text, title, image, daysList, handleDateChange, date,
}) => (
  <ContainerMain>
    <Content>
      <Image src={image} alt="image" width={203} height={203} />
      <Title>{title}</Title>
      <Text>{text}</Text>
      <SelectContainer>
        <Select options={monthsList} name="month" value={date.month} onChange={handleDateChange} />
        <Select options={daysList} name="day" value={date.day} onChange={handleDateChange} />
        <Select options={yearsList} name="year" value={date.year} onChange={handleDateChange} />
      </SelectContainer>
    </Content>
  </ContainerMain>
);

export default DateContainer;
