import styled from 'styled-components';
import { TitleText } from 'src/astromix/components/Title';
import { Input as CheckboxInput, Label } from 'src/astromix/components/Checkbox/styled';
import checkbox from 'src/astromix/assets/images/email_checked.svg';
import { TextMain } from '../styled';

export const Title = styled(TitleText)`
  font-weight: 400;
  max-width: 708px;
  margin-bottom: 15px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 30px;
  }
`;

export const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled.div`
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
`;

export const Text = styled(TextMain)`
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.secondary};
  text-align: left;
  margin-left: 10px;
`;

export const CheckboxWrapper = styled.div`
  padding-top: 20px;

  ${Label} {
    color: ${(props) => props.theme.colors.secondary};
    text-align: start;
    align-items: flex-start;

    ::before {
      margin-top: 2px;
      border-color: ${(props) => props.theme.colors.secondary};
    }
  }

  ${CheckboxInput} {
    &:checked + ${Label}::before {
      background-color: transparent;
      background-image: url(${checkbox});
    }
  }
`;
