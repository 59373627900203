import { useCallback } from 'react';
import { PaymentOrderDescription, PaymentType, PurchaseProductName } from 'src/common/types';
import useServices from 'src/common/hooks/useServices';
import useAstromixServices from './useAstromixServices';

export function usePaymentStatus(productName: PurchaseProductName) {
  const { analyticsService } = useAstromixServices();
  const { loggingService: logger } = useServices();

  const paymentSucceed = useCallback(
    (paymentData: PaymentOrderDescription, paymentType: PaymentType) => {
      if (paymentType === PaymentType.CARD) {
        analyticsService.paymentCardChosen();
      } else {
        analyticsService.paymentPaypalChosen();
      }
      analyticsService.paymentSuccess({
        orderId: paymentData.order_id,
        productId: productName,
        price: paymentData.amount / 100,
      });
    },
    [productName, analyticsService],
  );

  const paymentFailed = useCallback(
    (declineData: unknown) => {
      analyticsService.paymentError();
      logger.error(new Error('Payment failed'), {
        data: declineData,
        tags: { payment: true },
      });
    },
    [logger, analyticsService],
  );

  const cardPaymentProcessing = useCallback(() => {
    analyticsService.paymentCardChosen();
  }, [analyticsService]);

  const paypalPaymentProcessing = useCallback(() => {
    analyticsService.paymentPaypalChosen();
  }, [analyticsService]);

  const paymentRetried = useCallback(() => {
    analyticsService.paymentRetried();
  }, [analyticsService]);

  return {
    paymentSucceed,
    paymentFailed,
    cardPaymentProcessing,
    paypalPaymentProcessing,
    paymentRetried,
  };
}
