import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import Modal from 'src/common/components/Modal';
import { ReactComponent as CrossIcon } from 'src/astromix/assets/images/cross.svg';
import PaypalPayment from 'src/common/components/PaypalPayment';
import CardPayment from 'src/common/components/CardPayment';
import CircularProgress from 'src/common/components/CircularProgress';
import { PaymentOrderDescription, PaymentType } from 'src/common/types';
import { PaymentDescriptionDTO } from 'src/common/services';
import styles from './PaymentDialog.module.scss';
import PaymentFailedDialogContent from './PaymentFailedDialogContent';

export type PaymentDialogProps = {
  className?: string;
  isOpen?: boolean;
  onClose?: () => void;
  price: number | string;
  getPaymentUrls: () => Promise<PaymentDescriptionDTO>;
  onCardPaymentProcessing?: () => void;
  onPaypalPaymentProcessing?: () => void;
  onPaymentSuccess?: (order: PaymentOrderDescription, paymentType: PaymentType) => void;
  onPaymentFailed?: (declineData: unknown) => void;
  onPaymentRetried?: () => void;
};

enum PaymentProcessSteps {
  INITIAL,
  IN_PROCESS,
  FAILED,
  SUCCESS
}

const PaymentDialog = (props: PaymentDialogProps) => {
  const {
    className,
    getPaymentUrls,
    isOpen = false,
    onClose,
    onPaymentFailed,
    onPaymentSuccess,
    onCardPaymentProcessing,
    onPaypalPaymentProcessing,
    onPaymentRetried,
    price,
  } = props;

  const [paymentProcessStep, setPaymentProcessStep] = useState(PaymentProcessSteps.INITIAL);

  const [formMerchantData, setFormMerchantData] = useState<unknown>();
  const [paypalPaymentUrl, setPaypalPaymentUrl] = useState('');

  const onModalClose = () => {
    if (paymentProcessStep === PaymentProcessSteps.IN_PROCESS) return;

    if (onClose) {
      onClose();
    }
  };

  const clearState = useCallback(() => {
    setPaymentProcessStep(PaymentProcessSteps.INITIAL);
    setFormMerchantData(undefined);
    setPaypalPaymentUrl('');
  }, []);

  const onOrderDeclined = useCallback((declineData: unknown) => {
    setPaymentProcessStep(PaymentProcessSteps.FAILED);
    if (onPaymentFailed) {
      onPaymentFailed(declineData);
    }
  }, [onPaymentFailed]);

  const onOrderCardStartProcessing = useCallback(() => {
    setPaymentProcessStep(PaymentProcessSteps.IN_PROCESS);
    if (onCardPaymentProcessing) {
      onCardPaymentProcessing();
    }
  }, [onCardPaymentProcessing]);

  const onOrderPaypalStartProcessing = useCallback(() => {
    setPaymentProcessStep(PaymentProcessSteps.IN_PROCESS);
    if (onPaypalPaymentProcessing) {
      onPaypalPaymentProcessing();
    }
  }, [onPaypalPaymentProcessing]);

  const onOrderPaypalApproved = useCallback((order: PaymentOrderDescription) => {
    setPaymentProcessStep(PaymentProcessSteps.SUCCESS);
    if (onPaymentSuccess) {
      onPaymentSuccess(order, PaymentType.PAYPAL);
    }
  }, [onPaymentSuccess]);

  const onOrderCardApproved = useCallback((order: PaymentOrderDescription) => {
    setPaymentProcessStep(PaymentProcessSteps.SUCCESS);
    if (onPaymentSuccess) {
      onPaymentSuccess(order, PaymentType.CARD);
    }
  }, [onPaymentSuccess]);

  const getUrls = useCallback(async () => {
    try {
      setPaymentProcessStep(PaymentProcessSteps.IN_PROCESS);
      const urls = await getPaymentUrls();
      setPaypalPaymentUrl(urls.paypalPaymentUrl);
      setFormMerchantData(urls.formMerchantData);
      setPaymentProcessStep(PaymentProcessSteps.INITIAL);
    } catch (err) {
      console.error(err);
      if (onPaymentFailed) {
        onPaymentFailed(err);
      }
      setPaymentProcessStep(PaymentProcessSteps.FAILED);
    }
  }, [onPaymentFailed, getPaymentUrls]);

  const tryAgain = () => {
    if (paymentProcessStep !== PaymentProcessSteps.FAILED) return;

    if (onPaymentRetried) {
      onPaymentRetried();
    }

    getUrls();
  };

  useEffect(() => {
    if (!isOpen) return () => {};

    getUrls();

    return () => clearState();
  }, [clearState, getUrls, isOpen]);

  const renderLoader = () => (
    <div className={styles.loaderWrapper}>
      <CircularProgress />
    </div>
  );

  const bodyClasses = cn({
    [styles.body]: true,
    [styles.bodyInProgress]: paymentProcessStep === PaymentProcessSteps.IN_PROCESS,
  });

  return (
    <Modal open={isOpen} onClose={onModalClose}>
      <div className={cn(styles.wrapper, className)}>
        {/* TODO(IconButton) */}
        <div role="button" onClick={onModalClose} className={styles.controlWrapper}>
          <CrossIcon className={styles.control} />
        </div>
        { paymentProcessStep !== PaymentProcessSteps.FAILED ? (
          <>
            <div className={styles.header}>
              <div className={styles.priceWrapper}>
                <span className={styles.priceLabel}>
                  Total:
                </span>
                {' '}
                <span className={styles.price}>
                  {price}
                </span>
              </div>
            </div>
            <div className={styles.bodyWrapper}>
              { paymentProcessStep === PaymentProcessSteps.IN_PROCESS && renderLoader() }
              <div className={bodyClasses}>
                <PaypalPayment
                  url={paypalPaymentUrl}
                  onOrderDeclined={onOrderDeclined}
                  onOrderStartProcessing={onOrderPaypalStartProcessing}
                  onOrderApproved={onOrderPaypalApproved}
                />
                <div className={styles.divider}>
                  <span className={styles.dividerLabel}>or</span>
                </div>
                <CardPayment
                  formMerchantData={formMerchantData}
                  onOrderDeclined={onOrderDeclined}
                  onOrderStartProcessing={onOrderCardStartProcessing}
                  onOrderApproved={onOrderCardApproved}
                />
              </div>
            </div>
          </>
        )
          : <PaymentFailedDialogContent onTryAgain={tryAgain} />}
      </div>
    </Modal>
  );
};

export default PaymentDialog;
