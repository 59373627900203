import React from 'react';
import { Container, Option, SelectBlock } from './styled';

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: string[];
}

const Select: React.FC<SelectProps> = ({
  name, value, onChange, options,
}) => (
  <Container>
    <SelectBlock name={name} id={name} value={value} onChange={onChange}>
      {options.map((item) => (
        <Option value={item} key={item}>
          {item}
        </Option>
      ))}
    </SelectBlock>
  </Container>
);

export default React.memo(Select);
