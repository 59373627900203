import React from 'react';
import { BASE_ROUTES } from 'src/astromix/constants/routes';
import { Container, Link } from './styled';

const Policy = () => (
  <Container>
    By continuing I agree with
    {' '}
    <Link to={BASE_ROUTES.PRIVACY_POLICY}>Privacy policy,</Link>
    {' '}
    <Link to={BASE_ROUTES.TERMS}>Terms of use</Link>
    {' '}
    and
    {' '}
    <Link to={BASE_ROUTES.COOKIE_POLICY}>Cookie policy</Link>
    .
  </Container>
);

export default Policy;
