import React from 'react';
import cn from 'classnames';

import paymentFailed from 'src/astromix/assets/images/payment_failed.svg';
import Button from 'src/astromix/components/Button';
import styles from './PaymentFailedDialogContent.module.scss';

export type PaymentFailedDialogContentProps = {
    className?: string;
    onTryAgain?: () => void;
}

const PaymentFailedDialogContent = ({ className, onTryAgain = () => {} }: PaymentFailedDialogContentProps) => (
  <div className={cn(styles.root, className)}>
    <div className={styles.title}>
      Payment failed
    </div>
    <div className={styles.text_container}>
      <div className={styles.text}>
        Your transaction has failed due to some technical error.
        Please, try again.
      </div>
    </div>
    <div className={styles.iconWrapper}>
      <img
        alt="payment failed"
        src={paymentFailed}
        className={styles.icon}
      />
    </div>
    <div className={styles.buttonWrapper}>
      <Button
        className={styles.button}
        onClick={onTryAgain}
      >
        Try again
      </Button>
    </div>
  </div>
);

export default PaymentFailedDialogContent;
