import { useEffect, useState } from 'react';
import { Store } from 'redux';
import { useLocation } from 'react-router-dom';
import { initServices, Services } from 'src/common/services';
import { splitSystem } from 'src/astromix/experiments';
import { RootInitialState } from 'src/astromix/store/rootReducer';
import createStore from 'src/astromix/store/store';
import { config } from 'src/astromix/config';
import { AstromixServices, initAstromixServices } from './services';

export const useApp = () => {
  const location = useLocation();
  const [appStore, setAppStore] = useState<Store<RootInitialState>>();
  const [appServices, setAppServices] = useState<Services>();
  const [astromixServices, setAstromixServices] = useState<AstromixServices>();
  const [isAppLoaded, setIsAppLoaded] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    async function fetchServices() {
      const [analytics, services] = await Promise.all([
        initAstromixServices(),
        initServices(config.apiUrl, config.apiPrefix),
      ]);
      setAstromixServices(analytics);
      const store = createStore(services, analytics, splitSystem);
      setAppServices(services);
      setAppStore(store);
      setIsAppLoaded(true);
    }

    fetchServices();
  }, []);

  useEffect(() => {
    astromixServices?.analyticsService.launch();
  }, [astromixServices]);

  return {
    store: appStore,
    services: appServices,
    isAppLoaded,
    astromixServices,
  };
};
