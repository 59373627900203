import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.secondary};
  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 18px;
  }
`;

export const Subtitle = styled(Text)`
  max-width: 708px;
  margin-bottom: 15px;
  text-align: center;
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 60px;
  }
`;

export const Content = styled.div`
  max-width: 461px;
  margin: 0 auto;
`;

export const List = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Circle = styled.div`
  margin-right: 17px;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 50%;
  height: 6px;
  width: 6px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 10px;
    height: 10px;
    margin-right: 12px;
  }
`;

export const Item = styled(Text)`
  margin-bottom: 0;
`;
