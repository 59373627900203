import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 15px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 0 34px
  }
  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 0 50px
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  align-self: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
