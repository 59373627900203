import { useDispatch, useSelector } from 'react-redux';
import { doSignUpViaQuiz, getEmail } from 'src/astromix/store/userProfile';
import { ApiError, UnknownError } from 'src/common/errors';
import { useLogger } from 'src/common/hooks/useLogger';
import { ANALYTICS_NAME, QUIZ_NAMES } from 'src/common/types';
import { EmailFormHelpers } from 'src/astromix/components/Quiz/EmailForm/EmailFormContainer';
import { QUIZ_ANALYTIC_EVENTS } from 'src/astromix/services/analytics';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { useEffect } from 'react';

export default function useEmailForm({
  quizName,
  next,
  back,
  analytic,
}: {
  quizName: QUIZ_NAMES;
  // TODO add
  userInformationKey: string;
  back: () => Promise<void>;
  next: () => Promise<void>;
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS,
    name: ANALYTICS_NAME,
  }
}) {
  const dispatch = useDispatch();
  const logger = useLogger();
  const initialValue = useSelector(getEmail);

  const onBack = () => back();

  const { analyticsService } = useAstromixServices();

  useEffect(() => {
    analyticsService.trackQuizEvent(analytic.baseEvent);
  }, [analyticsService, analytic]);

  const signUpUser = async (data: { email: string, emailConsent: boolean }, { setErrors }: EmailFormHelpers) => {
    try {
      await dispatch(
        doSignUpViaQuiz({
          ...data,
          quizAnaliticName: analytic.name,
          quizName,
        }),
      );
    } catch (err) {
      if (err instanceof ApiError) {
        setErrors({ email: err.originalError.message });
        logger.error(err);
      } else {
        setErrors({ email: 'Something went wrong. Try again later' });
        logger.error(new UnknownError(err));
      }
      return;
    }
    analyticsService.extendedLpRedirect();
    next();
  };

  return {
    onBack,
    signUpUser,
    initialValue,
  };
}
