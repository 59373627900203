import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';
import ImagedPageSectionInscription from 'src/common/components/ImagedPageSectionInscription';
import ImagedPageSection, { ImagedPageSectionHolder } from 'src/common/components/ImagedPageSection';
import Button from 'src/common/components/Button';

const sectionWidth = 360;
const sectionHeight = 140;
const sectionTabletWidth = 708;
const sectionTabletHeight = 77;
const sectionDesktopWidth = 708;
const sectionDesktopHeight = 77;

export const ImagedSection = styled(ImagedPageSection)`
  margin: 30px auto 0;
  max-width: 708px;

  @media ${(props) => props.theme.breakpoints.tablet} {
      margin-bottom: 50px;
    }

  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionTabletHeight} / ${sectionTabletWidth} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionDesktopHeight} / ${sectionDesktopWidth} * 100%);
    }
  }
`;

export const PaymentWrapper = styled.div`
  width: 100%;
`;

export const ReportDescription = styled.p`
  margin: 30px auto;
  max-width: 526px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.active};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 10px;
    line-height: 140%;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Link = styled(RLink)`
  text-decoration: underline;
  font-weight: bold;
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SectionInscription = styled(ImagedPageSectionInscription)`
  position: relative;
`;

export const MobilePaymentWrapper = styled.div`
  border: ${(props) => `2px solid ${props.theme.colors.info}`};
  padding: 15px;
  border-radius: 16px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    border: none;
    padding: 0;
  }
`;
