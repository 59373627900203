import { usePurchaseReport } from 'src/common/hooks/usePurchaseReport';
import { PurchaseProductName, LoadingStatuses } from 'src/common/types';
import { useEffect } from 'react';

import { useRedirectToMainApp } from 'src/common/hooks/useRedirectToMainApp';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { useProductPrice } from 'src/common/hooks/useProductPrice';

export function useTarot() {
  const upsellPageOrder = 2;

  const { analyticsService } = useAstromixServices();

  const { purchase, loadingStatus, error } = usePurchaseReport(
    PurchaseProductName.TAROT_SPREAD_FOR_2022,
  );

  const { redirect } = useRedirectToMainApp();

  const getReport = async () => {
    await purchase();
    analyticsService.tarotLpGetReport(upsellPageOrder);
  };

  const {
    price,
    loadingStatus: priceLoadingStatus,
  } = useProductPrice(PurchaseProductName.TAROT_SPREAD_FOR_2022);

  const skip = async () => {
    analyticsService.tarotLpSkip(upsellPageOrder);

    try {
      await redirect();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    analyticsService.tarotLpOpened(upsellPageOrder);
  }, [analyticsService]);

  useEffect(() => {
    if (loadingStatus === LoadingStatuses.FULFILLED) {
      redirect();
    }
  }, [redirect, loadingStatus]);

  return {
    getReport,
    skip,
    loadingStatus,
    error,
    price,
    priceLoadingStatus,
  };
}
