import React from 'react';
import { DPIScales } from 'src/common/components/ImagedPageSection';
import { ReactComponent as Guarantee } from 'src/astromix/assets/images/guarantee.svg';
import Address from 'src/common/components/Address';

import webpXl from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-xl.webp';
import webpXl2x from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-xl-2x.webp';
import pngXl from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-xl.png';
import pngXl2x from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-xl-2x.png';
import webpMd from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-md.webp';
import webpMd2x from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-md-2x.webp';
import pngMd from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-md.png';
import pngMd2x from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-md-2x.png';
import webpXs from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-xs.webp';
import webpXs2x from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-xs-2x.webp';
import pngXs from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-xs.png';
import pngXs2x from 'src/astromix/assets/images/additional-discount/default/feedback/feedback-xs-2x.png';

import {
  ImagedSection, SectionInscription, MoneyBackGuarantee, StyledButton,
} from './styled';
import PeopleAsk from './PeopleAsk';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

export type SectionFeedbackProps = {
  onGetReport: () => void;
}

const SectionFeedback = ({ onGetReport }: SectionFeedbackProps) => (
  <>
    <ImagedSection originalUrl={sectionData.originalUrl} sources={sectionData.sources} />
    <PeopleAsk />
    <MoneyBackGuarantee />
    <StyledButton onClick={onGetReport} variant="success">
      Get my report
    </StyledButton>
    <SectionInscription icon={<Guarantee />}>
      Guaranteed security payments
    </SectionInscription>
    <Address />
  </>
);

export default SectionFeedback;
