import React from 'react';
import { ColorVariants } from 'src/common/theme/types';
import { Back } from './styled';

export type ButtonBackProps = {
  variant?: ColorVariants;
  onClick?: () => void;
};

const ButtonBack = ({ variant = 'primary', onClick }: ButtonBackProps) => (
  <Back onClick={onClick} variant={variant} />
);

export default ButtonBack;
