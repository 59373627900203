import { useCallback } from 'react';
import useServices from 'src/common/hooks/useServices';
import { getMainAppThankYouPageUrl } from 'src/astromix/constants';

export function useRedirectToMainApp() {
  const { authService } = useServices();

  const redirect = useCallback(async () => {
    const token = await authService.createAuthenticationToken();
    window.location.assign(getMainAppThankYouPageUrl(token));
  }, [authService]);

  return {
    redirect,
  };
}
