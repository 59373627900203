import React from 'react';
import { DPIScales } from 'src/common/components/ImagedPageSection';
import { TEN_MINUTES_IN_MS } from 'src/astromix/constants';

import webpXl from 'src/astromix/assets/images/billing/1weekVS1month/main/main-xl.webp';
import webpXl2x from 'src/astromix/assets/images/billing/1weekVS1month/main/main-xl-2x.webp';
import pngXl from 'src/astromix/assets/images/billing/1weekVS1month/main/main-xl.png';
import pngXl2x from 'src/astromix/assets/images/billing/1weekVS1month/main/main-xl-2x.png';
import webpMd from 'src/astromix/assets/images/billing/1weekVS1month/main/main-md.webp';
import webpMd2x from 'src/astromix/assets/images/billing/1weekVS1month/main/main-md-2x.webp';
import pngMd from 'src/astromix/assets/images/billing/1weekVS1month/main/main-md.png';
import pngMd2x from 'src/astromix/assets/images/billing/1weekVS1month/main/main-md-2x.png';
import webpXs from 'src/astromix/assets/images/billing/1weekVS1month/main/main-xs.webp';
import webpXs2x from 'src/astromix/assets/images/billing/1weekVS1month/main/main-xs-2x.webp';
import pngXs from 'src/astromix/assets/images/billing/1weekVS1month/main/main-xs.png';
import pngXs2x from 'src/astromix/assets/images/billing/1weekVS1month/main/main-xs-2x.png';
import {
  ImagedSection,
  PriceTimer,
  PriceTimerText,
  PriceTimerTextWrapper,
  PriceTimerWrapper,
  StyledAnimatedButton,
} from './styled';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

export type SectionMainProps = {
  onGetReport: () => void;
  isLoading: boolean;
  setIsTimeUp: (state: boolean) => void;
  isTimeUp: boolean;
};

const SectionMain = ({
  onGetReport, isLoading, isTimeUp, setIsTimeUp,
}: SectionMainProps) => (
  <ImagedSection originalUrl={sectionData.originalUrl} sources={sectionData.sources}>
    <PriceTimerWrapper>
      <PriceTimer
        startTime={isTimeUp ? 0 : TEN_MINUTES_IN_MS}
        setIsTimeUp={setIsTimeUp}
        element={() => (
          <StyledAnimatedButton onClick={onGetReport} loading={isLoading} variant="success">
            {isLoading ? 'Processing' : 'GET FULL REPORT'}
          </StyledAnimatedButton>
        )}
      >
        <PriceTimerTextWrapper>
          <PriceTimerText>Reserved price for:</PriceTimerText>
        </PriceTimerTextWrapper>
      </PriceTimer>
    </PriceTimerWrapper>
  </ImagedSection>
);

export default SectionMain;
