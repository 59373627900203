import styled from 'styled-components';
import { ReactComponent as LogoImage } from 'src/astromix/assets/images/logo.svg';
import { ColorVariants } from 'src/common/theme/types';

export const Container = styled.div`
  padding-top: 20px;
  position: relative;
  text-align: center;
  margin-bottom: 13px;
  z-index: 2;
`;

export const Logo = styled(LogoImage)<{ variant: ColorVariants }>`
  color: ${(props) => (props.variant === 'secondary' && props.theme.colors.secondary)
    || props.theme.colors.primary};
`;
