import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  @media ${(props) => props.theme.breakpoints.desktop} {
    justify-content: center;
  };
`;

export const List = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.secondary};
  margin-top: 10px;
  border-radius: 10px;
  max-height: 265px;
  overflow: scroll;

  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {

  max-height: 285px;
  max-width: 380px;
  margin: 10px auto;
}
`;

export const Item = styled.div`
  text-align: left;
  padding: 10px 15px;
  cursor: pointer;
  // TODO added to colors theme #BDBDBD
  border-bottom: 1px solid #BDBDBD;
  // TODO added to colors theme #E2E4E7
  color: #2F2E37;
  &:hover {
    // TODO added to colors theme #E2E4E7
    background: #E2E4E7;
  }
`;
