import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';

export const Container = styled.p`
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  max-width: 214px;
  margin: 0 auto 20px auto;
  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 284px;
  }
`;

export const Link = styled(RLink)`
  text-decoration: underline;
  font-weight: bold;
`;
