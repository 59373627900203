import React from 'react';
import {
  Input, Label, StyledArrowIcon, DropdownSection as DropdownBlock,
} from './styled';

interface DropdownProps {
  id: string;
  children: React.ReactNode;
}
export const DropdownSection = ({ id, children }: DropdownProps) => (
  <>
    <Input type="checkbox" id={id} />
    <Label htmlFor={id}>
      <StyledArrowIcon />
    </Label>
    <DropdownBlock>{children}</DropdownBlock>
  </>
);
