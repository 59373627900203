/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Input, Label } from './styled';

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string | React.ReactNode;
  className?: string;
};

const Checkbox = ({
  label,
  className = '',
  children,
  ...inputAttributes
}: CheckboxProps) => {
  const { name } = inputAttributes;
  return (
    <>
      <Input
        type="checkbox"
        className={className}
        id={name}
        name={name}
        {...inputAttributes}
      />
      <Label htmlFor={name}>{label || children}</Label>
    </>
  );
};

export default React.memo(Checkbox);
