export enum QUIZ_ANALYTIC_EVENTS {
  FLOW_START_OPEN = 'flow_start_open',
  FLOW_INTRO_ACCEPT_COOKIES = 'flow_intro_accept_cookies',
  ASTROMIX_FAQ_PAGE_OPEN = 'astromix_faq_page_open',
  ASTROMIX_CONTACT_PAGE_OPEN = 'astromix_contact_page_open',
  ASTROMIX_POLICIES_PAGE_OPEN = 'astromix_policies_page_open',
  FLOW_GENDER_OPEN = 'flow_gender_open',
  FLOW_HOW_OPEN = 'flow_how_open',
  FLOW_BIRTH_DATE_OPEN = 'flow_birth_date_open',
  FLOW_CITY_OPEN = 'flow_city_open',
  FLOW_BIRTH_TIME_OPEN = 'flow_birth_time_open',
  FLOW_PROCESSING_PAGE_OPEN = 'flow_processing_page_open',
  FLOW_INFLUENCE_OPEN = 'flow_influence_open',
  FLOW_WOW_OPEN = 'flow_wow_open',
  FLOW_TEST_TRUST_OPEN = 'flow_test_trust_open',
  FLOW_TEST_MAD_OPEN = 'flow_test_mad_open',
  FLOW_TEST_WRITE_OPEN = 'flow_test_write_open',
  FLOW_TEST_DESCRIBE_OPEN = 'flow_test_describe_open',
  FLOW_TEST_COLOR_OPEN = 'flow_test_color_open',
  FLOW_TEST_OVERTHINK_OPEN = 'flow_test_overthink_open',
  FLOW_REMEMBER_OPEN = 'flow_test_remember',
  FLOW_TEST_ELEMENT_OPEN = 'flow_test_element_open',
  FLOW_TEST_DATING_OPEN = 'flow_test_dating_open',
  FLOW_TEST_IMPORTANT_OPEN = 'flow_test_important_open',
  FLOW_COUPLE_STAT_PAGE_OPEN = 'flow_couple_stat_page_open',
  FLOW_PARTNER_GENDER_OPEN = 'flow_partner_gender_open',
  FLOW_PARTNER_BIRTH_DATE_OPEN = 'flow_partner_birth_date_open',
  FLOW_PARTNER_CITY_OPEN = 'flow_partner_city_open',
  FLOW_PARTNER_BIRTH_TIME_OPEN = 'flow_partner_birth_time_open',
  FLOW_PARTNER_MEFIRST_OPEN = 'flow_partner_mefirst_open',
  FLOW_PARTNER_STRAIGHTFORWARD_OPEN = 'flow_partner_straightforward_open',
  FLOW_PARTNER_FINAL_DECISION_OPEN = 'flow_partner_final_decision_open',
  FLOW_PARTNER_BIGPICTURE_OPEN = 'flow_partner_bigpicture_open',
  FLOW_PARTNER_DESCRIBESBEST_OPEN = 'flow_partner_describesbest_open',
  FLOW_PARTNER_INTROVERT_OPEN = 'flow_partner_introvert_open',
  FLOW_EMAILFORM_OPEN = 'flow_emailform_open',
  FLOW_LANDING_PAGE_REDIRECT = 'flow_landing_page_redirect',
}
