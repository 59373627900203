import styled from 'styled-components';
import Price from 'src/astromix/components/Price';

export const AdvisoryPlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Label = styled.label`
  position: relative;
  border-radius: 20px;
  padding: 4px 0 4px 4px;
  display: flex;
  width: 100%;
  max-width: 668px;
  margin: 0 auto 30px;
  background: linear-gradient(#141333, #202261, #543C97, #6939A2);
  transition: all .3s;

  :hover {
    transform: scale(1.05, 1.1);
    cursor: pointer;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 60px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  overflow: hidden;
  padding: 15px 0 15px 15px;
  border-radius: 15px 0 0 15px;
  background: #fff;
  border-right: 2px solid ${(props) => props.theme.colors.secondary};
`;

export const Title = styled.span`
  display: inline-block;
  margin-bottom: 3px;
  margin-right: 2px;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  background: ${(props) => props.theme.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 10px;
  }
`;

export const TotalPrice = styled.span`
  font-size: 12px;
  line-height: 140%;
  background: ${(props) => props.theme.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 18px;
    line-height: 140%;
  }
`;

export const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 27px 12.5px;
  margin: -4px;
  max-width: 68px;
  text-align: center;
  overflow: hidden;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background: linear-gradient(270deg, #141333 -33.39%, #202261 15.89%, #543C97 55.84%, #6939A2 74.96%);

  @media ${(props) => props.theme.breakpoints.tablet} {
    padding: 16px 29px;
    max-width: 120px;
  }

  ::before {
    content: '';
    width: 1px;
    height: 100%;
  }
`;

export const PricePeriod = styled.span`
  display: inline-block;
  font-size: 12px;
  line-height: 140%;
  margin-top: 3px;
  color: ${(props) => props.theme.colors.secondary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    line-height: 140%;
    font-size: 16px;
  }
`;

export const StyledPrice = styled(Price)`
  font-size: 16px;
  line-height: 140%;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 10px;
  }
`;

export const Input = styled.input`
  display: none;

  &:checked + ${Label} {
    transform: scale(1.05, 1.1);

    & ${Description} {
      border-right: 1px solid ${(props) => props.theme.colors.secondary};
      background: transparent;
      padding: 5px 0 5px 15px;
      margin: 10px 0;
    }

    & ${Title} {
      background: transparent;
      -webkit-background-clip: border-box;
      -webkit-text-fill-color: ${(props) => props.theme.colors.secondary};
    }

    & ${TotalPrice} {
      background: transparent;
      -webkit-background-clip: border-box;
      -webkit-text-fill-color: ${(props) => props.theme.colors.secondary};
    }

    & ${PriceSection} {
      background: transparent;
      margin: 0;
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }
`;

export const OldPrice = styled.s`
  background: -webkit-linear-gradient(
    bottom, transparent calc(6px - 1px), #362D77 calc(6px - 1px), #362D77 6px, transparent 6px);
  background-size: 12px 14px;
  margin-left: 4px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    background-size: 12px 17px;
  }
`;

export const BoldText = styled.b``;

export const Offer = styled.div`
  display: flex;
  position: absolute;
  top: -1em;
  height: 30px;
  width: 100%;
`;

export const Benefit = styled.div`
  background: #E07CE2;
  width: 120px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Discount = styled.div`
  background: #9974F6;
  width: 101px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

export const OfferText = styled.span`
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-left: 3px;
`;
