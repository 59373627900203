import React, { useCallback, useState } from 'react';
import Header from 'src/astromix/components/Header';
import { PurchaseProductName } from 'src/common/types';
import PaymentDialog from 'src/common/components/PaymentDialog';
import { useEmailMarketingLanding } from 'src/common/hooks/useEmailMarketingLanding';
import SectionMain from './SectionMain';
import SectionInfo from './SectionInfo';
import SectionFeedback from './SectionFeedback';

const EmailMarketing3 = () => {
  // const pageOrder = 3;

  const [isPaymentDialogVisible, setIsPaymentDialogVisible] = useState(false);
  const {
    price,
    getPaymentUrls,
    paymentSucceed,
    // getReport,
    // TODO added pageOrder to the second argument
  } = useEmailMarketingLanding(PurchaseProductName.ASTROMIX_FREE_TRIAL_WEEKLY_999);

  const onPaymentDialogClose = () => {
    setIsPaymentDialogVisible(false);
  };

  const onGetReport = () => {
    // getReport();
    setIsPaymentDialogVisible(true);
  };

  const onPaymentSuccess = useCallback(() => {
    setIsPaymentDialogVisible(false);
    paymentSucceed();
  }, [paymentSucceed]);

  return (
    <>
      <Header buttonBack={false} />
      <SectionMain onGetReport={onGetReport} />
      <SectionInfo />
      <SectionFeedback onGetReport={onGetReport} />

      <PaymentDialog
        isOpen={isPaymentDialogVisible}
        onClose={onPaymentDialogClose}
        onPaymentSuccess={onPaymentSuccess}
        price={price}
        getPaymentUrls={getPaymentUrls}
      />
    </>
  );
};

export default EmailMarketing3;
