import { useHistory } from 'react-router-dom';
import { usePurchaseReport } from 'src/common/hooks/usePurchaseReport';
import { PurchaseProductName, LoadingStatuses } from 'src/common/types';
import { LANDINGS } from 'src/astromix/constants';
import { useEffect } from 'react';
import { joinUrls } from 'src/common/utils/joinUrls';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { useProductPrice } from 'src/common/hooks/useProductPrice';

export function useNumerology(baseUrl: string) {
  const upsellPageOrder = 1;
  const { analyticsService } = useAstromixServices();

  const history = useHistory();
  const { purchase, loadingStatus, error } = usePurchaseReport(
    PurchaseProductName.NUMEROLOGY_CALCULATOR,
  );

  const skip = () => {
    analyticsService.numerologyLpSkip(upsellPageOrder);
    history.push(joinUrls(baseUrl, LANDINGS.TAROT));
  };

  const getReport = async () => {
    await purchase();
    analyticsService.numerologyLpGetReport(upsellPageOrder);
  };

  useEffect(() => {
    analyticsService.numerologyLpOpened(upsellPageOrder);
  }, [analyticsService]);

  const {
    price,
    loadingStatus: priceLoadingStatus,
  } = useProductPrice(PurchaseProductName.NUMEROLOGY_CALCULATOR);

  useEffect(() => {
    if (loadingStatus === LoadingStatuses.FULFILLED) {
      history.push(joinUrls(baseUrl, LANDINGS.TAROT));
    }
  }, [history, loadingStatus]);

  return {
    loadingStatus,
    error,
    getReport,
    skip,
    price,
    priceLoadingStatus,
  };
}
