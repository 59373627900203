export default class GeoLocationService {
  private readonly url: string;

  private readonly apiKey: string;

  constructor(url: string, apiKey: string) {
    this.url = url;
    this.apiKey = apiKey;
  }

  getResource = async (text: string): Promise<any> => {
    const res = await fetch(`${this.url}?${new URLSearchParams({
      api_key: this.apiKey,
      text,
    })}`);

    const data = await res.json();
    return data;
  }
}
