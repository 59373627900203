import React from 'react';
import { DPIScales } from 'src/common/components/ImagedPageSection';
import { ReactComponent as Guarantee } from 'src/astromix/assets/images/guarantee.svg';

import pngXl from 'src/astromix/assets/images/numerology/info/info-xl.png';
import pngXl2x from 'src/astromix/assets/images/numerology/info/info-xl-2x.png';
import webpXl from 'src/astromix/assets/images/numerology/info/info-xl.webp';
import webpXl2x from 'src/astromix/assets/images/numerology/info/info-xl-2x.webp';
import pngMd from 'src/astromix/assets/images/numerology/info/info-md.png';
import pngMd2x from 'src/astromix/assets/images/numerology/info/info-md-2x.png';
import webpMd from 'src/astromix/assets/images/numerology/info/info-md.webp';
import webpMd2x from 'src/astromix/assets/images/numerology/info/info-md-2x.webp';
import pngXs from 'src/astromix/assets/images/numerology/info/info-xs.png';
import pngXs2x from 'src/astromix/assets/images/numerology/info/info-xs-2x.png';
import webpXs from 'src/astromix/assets/images/numerology/info/info-xs.webp';
import webpXs2x from 'src/astromix/assets/images/numerology/info/info-xs-2x.webp';

import {
  Skip,
  StyledButton,
  StyledPriceBlock,
  ImagedSection,
  InfoWrapper,
  StyledTitle,
  SectionInscription,
  StyledPaymentCards,
} from './styled';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

export type SectionInfoProps = {
  onGetReport: () => void;
  onHandleSkipClick: () => void;
  isLoading?: boolean;
  price: number;
}

const SectionInfo = ({
  onGetReport, isLoading, onHandleSkipClick, price,
}: SectionInfoProps) => (
  <InfoWrapper>
    <ImagedSection
      originalUrl={sectionData.originalUrl}
      sources={sectionData.sources}
    />
    <StyledTitle variant="secondary">Special price for you:</StyledTitle>
    <StyledPriceBlock
      titleContent="ONE TIME PAYMENT"
      price={`$${price}`}
    />
    <StyledButton
      loading={isLoading}
      onClick={onGetReport}
      variant="success"
    >
      {isLoading ? 'Processing' : ' Get my numerology guide'}
    </StyledButton>
    <Skip role="button" onClick={onHandleSkipClick}>
      Skip my special offer and proceed further
    </Skip>
    <SectionInscription icon={<Guarantee />} variant="secondary">
      Guaranteed security payments
    </SectionInscription>
    <StyledPaymentCards />
  </InfoWrapper>
);

export default SectionInfo;
