import React from 'react';
import Title from 'src/astromix/components/Title';
import {
  SectionWrapper,
  StyledGift,
} from './styled';

const SectionHeader = () => (
  <SectionWrapper>
    <Title>Additional discount applied for you</Title>
    <StyledGift title="additional discount as a gift" />
  </SectionWrapper>
);

export default SectionHeader;
