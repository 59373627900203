import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BASE_ROUTES, LANDINGS } from 'src/astromix/constants/routes';
import ExtendedLanding from 'src/astromix/pages/ExtendedLanding/default';
import Billing from 'src/astromix/pages/Billing/default';
import AdditionalDiscount from 'src/astromix/pages/AdditionalDiscount/default';
import Numerology from 'src/astromix/pages/Numerology/default';
import Tarot from 'src/astromix/pages/Tarot/default';
import { joinUrls } from 'src/common/utils/joinUrls';
import EmailMarketing1 from 'src/astromix/pages/EmailMarketing1';
import EmailMarketing2 from 'src/astromix/pages/EmailMarketing2';
import EmailMarketing3 from 'src/astromix/pages/EmailMarketing3';
import { QuizProvider } from 'src/common/contexts/QuizContext';
import { BaseQuizStepData } from 'src/common/types';
import { QuizStepTypes } from 'src/astromix/types';
import Gender from 'src/astromix/pages/Quiz/Gender';
import How from 'src/astromix/pages/Quiz/How';
import Date from 'src/astromix/pages/Quiz/Date';
import Place from 'src/astromix/pages/Quiz/Place';
import Time from 'src/astromix/pages/Quiz/Time';
import ColorPicker from 'src/astromix/pages/Quiz/ColorPicker';
import Information from 'src/astromix/pages/Quiz/Information';
import Answer from 'src/astromix/pages/Quiz/Answer';
import EmailForm from 'src/astromix/pages/Quiz/EmailForm';
import Loader from 'src/astromix/pages/Quiz/Loader';
import { astromixScreen } from './screen';

const Astromix = () => {
  const makeRoutePath = (subPath: string) => joinUrls(BASE_ROUTES.ASTROMIX, subPath);

  const stepComponentFactory = ({ id, screenType }: BaseQuizStepData) => {
    switch (screenType) {
      case QuizStepTypes.GENDER:
        return <Gender id={id} />;
      case QuizStepTypes.HOW:
        return <How id={id} />;
      case QuizStepTypes.DATE:
        return <Date id={id} />;
      case QuizStepTypes.PLACE:
        return <Place id={id} />;
      case QuizStepTypes.TIME:
        return <Time id={id} />;
      case QuizStepTypes.COLOR_PICKER:
        return <ColorPicker id={id} />;
      case QuizStepTypes.INFORMATIONAL:
        return <Information id={id} />;
      case QuizStepTypes.ANSWER:
        return <Answer id={id} />;
      case QuizStepTypes.EMAIL:
        return <EmailForm id={id} />;
      case QuizStepTypes.PROCESSING:
        return <Loader id={id} />;
      default: {
        // TODO add fallback
        return null;
      }
    }
  };

  return (
    <Switch>
      <Route path={makeRoutePath(LANDINGS.EXTENDED_LANDING)}>
        <ExtendedLanding />
      </Route>

      <Route path={makeRoutePath(LANDINGS.BILLING)}>
        <Billing />
      </Route>

      <Route path={makeRoutePath(LANDINGS.ADDITIONAL_DISCOUNT)}>
        <AdditionalDiscount />
      </Route>

      <Route path={makeRoutePath(LANDINGS.NUMEROLOGY)}>
        <Numerology />
      </Route>

      <Route path={makeRoutePath(LANDINGS.TAROT)}>
        <Tarot />
      </Route>

      <Route path={makeRoutePath(LANDINGS.EMAIL_MARKETING_1)}>
        <EmailMarketing1 />
      </Route>

      <Route path={makeRoutePath(LANDINGS.EMAIL_MARKETING_2)}>
        <EmailMarketing2 />
      </Route>

      <Route path={makeRoutePath(LANDINGS.EMAIL_MARKETING_3)}>
        <EmailMarketing3 />
      </Route>

      <QuizProvider data={astromixScreen} stepComponentFactory={stepComponentFactory} />
    </Switch>
  );
};

export default Astromix;
