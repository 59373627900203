import React from 'react';
import { useContactUs } from './useContactUs';
import { Container, MainTitle } from './styled';

const ContactUs = () => {
  useContactUs();

  return (
    <Container>
      <MainTitle>
        ContactUs
      </MainTitle>
    </Container>
  );
};

export default ContactUs;
