import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useCreatePayment } from 'src/common/hooks/useCreatePayment';
import { PaymentOrderDescription, PaymentType, PurchaseProductName } from 'src/common/types';
import { BASE_ROUTES, LANDINGS } from 'src/astromix/constants';
import { joinUrls } from 'src/common/utils/joinUrls';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { useProductPrice } from 'src/common/hooks/useProductPrice';
import { usePaymentStatus } from 'src/astromix/hooks/usePaymentStatus';

export function useAdditionalDiscount() {
  const { analyticsService } = useAstromixServices();

  const {
    cardPaymentProcessing,
    paymentSucceed: reportPaymentSucceed,
    paypalPaymentProcessing,
    paymentRetried,
    paymentFailed,
  } = usePaymentStatus(PurchaseProductName.ASTROMIX_DISCOUNT_7D_TRIAL_3_MONTH_9999);

  const history = useHistory();
  const { getPaymentUrls } = useCreatePayment(PurchaseProductName.ASTROMIX_DISCOUNT_7D_TRIAL_3_MONTH_9999);

  const { price, loadingStatus } = useProductPrice(
    PurchaseProductName.ASTROMIX_DISCOUNT_7D_TRIAL_3_MONTH_9999,
  );

  const paymentSucceed = useCallback(
    (orderData: PaymentOrderDescription, paymentType: PaymentType) => {
      reportPaymentSucceed(orderData, paymentType);
      history.push(joinUrls(BASE_ROUTES.ASTROMIX, LANDINGS.NUMEROLOGY));
    },
    [history, reportPaymentSucceed, analyticsService],
  );

  const getReport = () => {
    analyticsService.additionalDiscountLpGetReport(
      PurchaseProductName.ASTROMIX_DISCOUNT_7D_TRIAL_3_MONTH_9999,
    );
  };

  useEffect(() => {
    analyticsService.additionalDiscountLpOpened();
  }, [analyticsService]);

  return {
    price,
    loadingStatus,
    getPaymentUrls,
    paymentSucceed,
    cardPaymentProcessing,
    paypalPaymentProcessing,
    paymentRetried,
    paymentFailed,
    getReport,
  };
}
