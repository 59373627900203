import styled from 'styled-components';

export const AddressWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
`;

export const Image = styled.img`
  width: 300px;
`;
