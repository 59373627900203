import styled from 'styled-components';
import ImagedPageSection, { ImagedPageSectionHolder } from 'src/common/components/ImagedPageSection';
import Title from 'src/astromix/components/Title';
import Address from 'src/common/components/Address';

export const sectionWidth = 1366;
export const sectionWidthTablet = 768;
export const sectionWidthMobile = 360;
export const sectionHeight = 612;
export const sectionHeightTablet = 612;
export const sectionHeightMobile = 566;

export const buttonTopOffset = 392;
export const buttonTopOffsetTablet = 374;
export const buttonTopOffsetDesktop = 374;

const linkTopOffset = 453;
const linkTabletTopOffset = 462;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

export const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeightMobile} / ${sectionWidthMobile} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionHeightTablet} / ${sectionWidthTablet} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);
    }
  }
`;

export const Skip = styled.p`
  cursor: pointer;
  color: ${(props) => props.theme.colors.secondary};
  text-decoration: underline;
  text-align: center;
  position: absolute;
  z-index: 1;
  width: 310px;
  left: 50%;
  transform: translateX(-50%);
  top: calc(${linkTopOffset} / ${sectionHeightMobile} * 100%);
  font-size: 14px;
  line-height: 19px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    top: calc(${linkTabletTopOffset} / ${sectionHeightTablet} * 100%);
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledTitle = styled(Title)`
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 0;
`;

export const StyledAddress = styled(Address)`
  text-align: center;
`;
