import styled from 'styled-components';
import ImagedPageSection, {
  ImagedPageSectionHolder,
} from 'src/common/components/ImagedPageSection';
import MoneyBack from 'src/astromix/components/MoneyBack';
import Button from 'src/common/components/Button';
import ImagedPageInscription from 'src/common/components/ImagedPageSectionInscription';

const sectionWidth = 360;
const sectionHeight = 1136;
const sectionTabletHeight = 1043;
const sectionTabletWidth = 768;
const sectionDesktopHeight = 1043;
const sectionDesktopWidth = 1440;

export const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionTabletHeight} / ${sectionTabletWidth} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionDesktopHeight} / ${sectionDesktopWidth} * 100%);
    }
  }
`;

export const MoneyBackGuarantee = styled(MoneyBack)`
  margin: 50px auto;
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SectionInscription = styled(ImagedPageInscription)`
  position: relative;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 50px;
    margin-bottom: 60px;
  }
`;

export const Address = styled.span`
  color: ${(props) => props.theme.colors.inactive};
  margin-left: auto;
  margin-right: auto;
  font-size: 10px;
  line-height: 140%;
  width: 212px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 12px;
    width: auto;
  }
`;
