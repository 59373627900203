import React from 'react';
import {
  Content, Item, List, Title,
} from './styled';

import { ContainerMain } from '../styled';

interface ColorPickerContainerProps {
  title: string;
  onAnswerClick: (choice: string) => void;
}

const colorPickerData = [
  '#9B51E0',
  '#56CCF2',
  '#2F80ED',
  '#EB5757',
  '#27AE60',
  '#F2C94C',
  '#F2994A',
  '#151515',
];

const ColorPickerContainer = ({ title, onAnswerClick }: ColorPickerContainerProps) => {
  const handleClick = (choice: string) => () => onAnswerClick(choice);
  return (
    <ContainerMain>
      <Title>{title}</Title>
      <Content>
        <List>
          {colorPickerData.map((item) => (
            <Item key={item} onClick={handleClick(item)} style={{ background: item }} />
          ))}
        </List>
      </Content>
    </ContainerMain>
  );
};

export default ColorPickerContainer;
