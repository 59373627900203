import React from 'react';
import ImagedPageSection, {
  DPIScales,
  ImagedPageSectionHolder,
} from 'src/common/components/ImagedPageSection';
import styled from 'styled-components';

import webpXl from 'src/astromix/assets/images/email-marketing-3/info/info-xl.webp';
import webpXl2x from 'src/astromix/assets/images/email-marketing-3/info/info-xl-2x.webp';
import pngXl from 'src/astromix/assets/images/email-marketing-3/info/info-xl.png';
import pngXl2x from 'src/astromix/assets/images/email-marketing-3/info/info-xl-2x.png';
import webpMd from 'src/astromix/assets/images/email-marketing-3/info/info-md.webp';
import webpMd2x from 'src/astromix/assets/images/email-marketing-3/info/info-md-2x.webp';
import pngMd from 'src/astromix/assets/images/email-marketing-3/info/info-md.png';
import pngMd2x from 'src/astromix/assets/images/email-marketing-3/info/info-md-2x.png';
import webpXs from 'src/astromix/assets/images/email-marketing-3/info/info-xs.webp';
import webpXs2x from 'src/astromix/assets/images/email-marketing-3/info/info-xs-2x.webp';
import pngXs from 'src/astromix/assets/images/email-marketing-3/info/info-xs.png';
import pngXs2x from 'src/astromix/assets/images/email-marketing-3/info/info-xs-2x.png';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

const sectionWidth = 360;
const sectionHeight = 887;
const sectionTabletHeight = 1027;
const sectionTabletWidth = 768;
const sectionDesktopHeight = 1027;
const sectionDesktopWidth = 1440;

const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionTabletHeight} / ${sectionTabletWidth} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionDesktopHeight} / ${sectionDesktopWidth} * 100%);
    }
  }
`;

const SectionInfo = () => (
  <ImagedSection originalUrl={sectionData.originalUrl} sources={sectionData.sources} />
);

export default SectionInfo;
