import React from 'react';
import { ANALYTICS_NAME } from 'src/common/types';
import useExperimentAnalytic from './useExperimentAnalytic';

interface ExperimentAnalyticProps {
  children: React.ReactNode;
  name: ANALYTICS_NAME;
}

const ExperimentAnalytic = ({ children, name }: ExperimentAnalyticProps) => {
  useExperimentAnalytic(name);
  return <>{children}</>;
};

export default ExperimentAnalytic;
