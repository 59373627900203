import React from 'react';
import { Circle } from './styled';

export type ProcessingLoaderCircleProps = {
  value: number;
};

const ProcessingLoaderCircle = ({ value }: ProcessingLoaderCircleProps) => (
  <Circle viewBox="0 0 38 38">
    <circle
      className="donut-hole"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      fill="transparent"
    >
    </circle>
    <circle
      className="donut-ring"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      fill="transparent"
      stroke="#6A3AA2"
      strokeWidth="2"
    >
    </circle>
    <circle
      className="donut-segment"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      fill="transparent"
      stroke="white"
      opacity="1"
      strokeWidth="2"
      strokeDasharray={`${100 - value} ${value}`}
    >
    </circle>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="45"
        y2="-9.5"
        x2="53.1842"
        y1="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6A3AA2" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </Circle>
);

export default ProcessingLoaderCircle;
