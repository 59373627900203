import React from 'react';
import image from 'src/astromix/assets/images/quiz/date.png';
import DateComponent from 'src/astromix/components/Quiz/Date';
import Header from 'src/astromix/components/Header';
import ProgressBar from 'src/common/components/ProgressBar';
import { QuizButton } from 'src/astromix/components/Quiz/styled';
import { useQuizStep } from 'src/common/contexts/QuizContext';
import { DateStepData } from 'src/astromix/types';
import useDate from './useDate';

export type DatePageProps = { id: string };

const Date = ({ id }: DatePageProps) => {
  const { stepData, back, next } = useQuizStep(id);
  // TODO add types
  const {
    dataKey,
    zodiacDataKey,
    content,
    stepOrder,
    totalSteps,
    analytic,
  } = stepData as DateStepData;

  const {
    date, handleButtonClick, handleDateChange, daysList, onBack,
  } = useDate({
    dataKey,
    next,
    back,
    zodiacDataKey,
    analytic,
  });

  // TODO add validation
  if (!stepData) return null;

  return (
    <>
      <Header buttonBack onBack={onBack} />
      <ProgressBar step={stepOrder} totalSteps={totalSteps} />
      <DateComponent
        image={image}
        title={content.title}
        text={content.text}
        date={date}
        handleDateChange={handleDateChange}
        daysList={daysList}
      />
      <QuizButton onClick={handleButtonClick}>Continue</QuizButton>
    </>
  );
};
export default Date;
