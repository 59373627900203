import styled from 'styled-components';
import Header from 'src/astromix/components/Header';
import { Link as ReactLink } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'src/astromix/assets/images/back.svg';
import { ReactComponent as AddressSVG } from 'src/astromix/assets/images/address.svg';

export const StyledHeader = styled(Header)``;

export const MainTitle = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

export const Title = styled.h3`
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Subtitle = styled.h4`
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Text = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.colors.mainLink};
  text-decoration: underline;
`;

export const NumberList = styled.ol`
  counter-reset: item;
`;

export const ListItem = styled.li`
  display: block;
  margin-top: 10px;

  &:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
    font-weight: bold;
  }
`;

export const UnorderedList = styled.ul`
  padding-left: 10px;

  & > ${ListItem}:before {
    content: '• ';
    counter-increment: none;
  }
`;

export const RouteLink = styled(ReactLink)`
  ${(props) => props.theme.colors.mainLink};
  text-decoration: underline;
`;

export const Container = styled.div`
  margin-left: 20px;
`;

export const Wrapper = styled.div`
  margin-left: 50px;
`;

export const Address = styled(AddressSVG)`
  vertical-align: middle;
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  transform: rotate(-90deg);
`;

export const Label = styled.label`
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.infoLight};

&::before {
    content: 'See more';
    font-weight: 600;
  }
`;

export const DropdownSection = styled.div``;

export const Input = styled.input`
  display: none;

  &:checked + ${Label}::before {
    content: 'See less';
    font-weight: 600;
  };

  &:checked + ${Label} > ${StyledArrowIcon} {
    transform: rotate(90deg);
  };

  &:not(checked) + ${Label} + ${DropdownSection} {
    overflow: hidden;
    max-height: 0;
    height: auto;
    transition: all .4s;
  }

  &:checked + ${Label} + ${DropdownSection} {
    max-height: 5000px;
  }
`;

export const BreakLine = styled.div`
  margin-top: 20px;
`;
