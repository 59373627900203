import React from 'react';
import female from 'src/astromix/assets/images/quiz/female.png';
import male from 'src/astromix/assets/images/quiz/male.png';
import Title from 'src/astromix/components/Title';
import {
  Container,
  Content,
  ImagesContainer,
  Img,
  ImgBlock,
  ImgBorder,
  Subtitle,
  Text,
} from './styled';

interface GenderContainerProps {
  title: string;
  text: string;
  subtitle?: string;
  subtext?: string;
  handleChoiceClick: (choice: string) => void;
}

const GenderContainer = ({
  handleChoiceClick, subtitle, subtext, title, text,
}: GenderContainerProps) => {
  const handleClick = (choice: string) => () => {
    handleChoiceClick(choice);
  };

  return (
    <Container>
      <Title>{title}</Title>
      <Content>
        <Text>{text}</Text>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {subtext && <Text>{subtext}</Text>}
        <ImagesContainer>
          <ImgBlock onClick={handleClick('male')}>
            <ImgBorder />
            <Img src={male} alt="male" />
          </ImgBlock>
          <ImgBlock onClick={handleClick('female')}>
            <ImgBorder />
            <Img src={female} alt="female" />
          </ImgBlock>
        </ImagesContainer>
      </Content>
    </Container>
  );
};

export default GenderContainer;
