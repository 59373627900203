import React from 'react';
import { DPIScales } from 'src/common/components/ImagedPageSection';

import webpXl from 'src/astromix/assets/images/email-marketing-3/main/main-xl.webp';
import webpXl2x from 'src/astromix/assets/images/email-marketing-3/main/main-xl-2x.webp';
import pngXl from 'src/astromix/assets/images/email-marketing-3/main/main-xl.png';
import pngXl2x from 'src/astromix/assets/images/email-marketing-3/main/main-xl-2x.png';
import webpMd from 'src/astromix/assets/images/email-marketing-3/main/main-md.webp';
import webpMd2x from 'src/astromix/assets/images/email-marketing-3/main/main-md-2x.webp';
import pngMd from 'src/astromix/assets/images/email-marketing-3/main/main-md.png';
import pngMd2x from 'src/astromix/assets/images/email-marketing-3/main/main-md-2x.png';
import webpXs from 'src/astromix/assets/images/email-marketing-3/main/main-xs.webp';
import webpXs2x from 'src/astromix/assets/images/email-marketing-3/main/main-xs-2x.webp';
import pngXs from 'src/astromix/assets/images/email-marketing-3/main/main-xs.png';
import pngXs2x from 'src/astromix/assets/images/email-marketing-3/main/main-xs-2x.png';
import ImagedPageSectionButton from 'src/common/components/ImagedPageSectionButton';

import { FIVE_MINUTES_IN_MS } from 'src/astromix/constants';
import {
  PriceTimer,
  ImagedSection,
  TimerText,
  TimerTitle,
  desktopAspectRatio,
  aspectRatio,
  tabletAspectRatio,
  TextBlock,
  TimerBlock,
} from './styled';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

export type SectionMainProps = {
  onGetReport: () => void;
};

const SectionMain = ({ onGetReport }: SectionMainProps) => (
  <ImagedSection originalUrl={sectionData.originalUrl} sources={sectionData.sources}>
    <ImagedPageSectionButton
      onClick={onGetReport}
      aspectRatio={aspectRatio}
      tabletAspectRatio={tabletAspectRatio}
      desktopAspectRatio={desktopAspectRatio}
      variant="success"
    >
      Get full report
    </ImagedPageSectionButton>
    <TimerBlock>
      <PriceTimer startTime={FIVE_MINUTES_IN_MS}>
        <TextBlock>
          <TimerTitle>Limited time offer</TimerTitle>
          <TimerText>Special price reserved for:</TimerText>
        </TextBlock>
      </PriceTimer>
    </TimerBlock>
  </ImagedSection>
);

export default SectionMain;
