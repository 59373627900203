import React from 'react';
import ImagedPageSection, {
  DPIScales,
  ImagedPageSectionHolder,
} from 'src/common/components/ImagedPageSection';
import styled from 'styled-components';

import webpXl from 'src/astromix/assets/images/email-marketing-2/main/main-xl.webp';
import webpXl2x from 'src/astromix/assets/images/email-marketing-2/main/main-xl-2x.webp';
import pngXl from 'src/astromix/assets/images/email-marketing-2/main/main-xl.png';
import pngXl2x from 'src/astromix/assets/images/email-marketing-2/main/main-xl-2x.png';
import webpMd from 'src/astromix/assets/images/email-marketing-2/main/main-md.webp';
import webpMd2x from 'src/astromix/assets/images/email-marketing-2/main/main-md-2x.webp';
import pngMd from 'src/astromix/assets/images/email-marketing-2/main/main-md.png';
import pngMd2x from 'src/astromix/assets/images/email-marketing-2/main/main-md-2x.png';
import webpXs from 'src/astromix/assets/images/email-marketing-2/main/main-xs.webp';
import webpXs2x from 'src/astromix/assets/images/email-marketing-2/main/main-xs-2x.webp';
import pngXs from 'src/astromix/assets/images/email-marketing-2/main/main-xs.png';
import pngXs2x from 'src/astromix/assets/images/email-marketing-2/main/main-xs-2x.png';
import ImagedPageSectionButton from 'src/common/components/ImagedPageSectionButton';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

const sectionWidth = 360;
const sectionHeight = 1256;
const sectionTabletWidth = 768;
const sectionTabletHeight = 1303;
const sectionDesktopWidth = 1440;
const sectionDesktopHeight = 1303;
const buttonTopOffset = 380;
const buttonTopOffsetTablet = 550;
const buttonTopOffsetDesktop = 550;
const aspectRatio = buttonTopOffset / sectionHeight;
const tabletAspectRatio = buttonTopOffsetTablet / sectionTabletHeight;
const desktopAspectRatio = buttonTopOffsetDesktop / sectionDesktopHeight;

const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionTabletHeight} / ${sectionTabletWidth} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionDesktopHeight} / ${sectionDesktopWidth} * 100%);
    }
  }
`;

export type SectionMainProps = {
  onGetReport: () => void;
}

const SectionMain = ({ onGetReport }: SectionMainProps) => (
  <ImagedSection originalUrl={sectionData.originalUrl} sources={sectionData.sources}>
    <ImagedPageSectionButton
      onClick={onGetReport}
      aspectRatio={aspectRatio}
      tabletAspectRatio={tabletAspectRatio}
      desktopAspectRatio={desktopAspectRatio}
      variant="success"
    >
      Get full report
    </ImagedPageSectionButton>
  </ImagedSection>
);

export default SectionMain;
