import React, { useContext } from 'react';
import { SplitSystemContext } from '../../contexts';
import { SplitSystemExperiment } from '../../SplitSystemExperiment';

interface ExperimentProps {
  name: string;
  children: React.ReactNode;
}

export const ExperimentContext = React.createContext<SplitSystemExperiment>(null!);

export const Experiment = ({ name, children }: ExperimentProps) => {
  const experiments = useContext(SplitSystemContext);
  const experiment = experiments.getExperiment(name);

  return <ExperimentContext.Provider value={experiment}>{children}</ExperimentContext.Provider>;
};
