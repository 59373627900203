import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';
import { ReactComponent as MoneyBackGuarantee } from 'src/astromix/assets/images/money-back-guarantee-large.svg';
import Title, { TitleText } from 'src/astromix/components/Title';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.gradients.primary};
  border-radius: 20px;
  width: 100%;
  max-width: 708px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  & ${TitleText} {
    max-width: 70%;
    text-align: start;
    margin: 20px 30px;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.secondary};

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: 24px;
    }
  }
`;

export const StyledTitle = styled(Title)``;

export const Text = styled.p`
  margin: 0 15px 20px;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.secondary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin: 0 84px 20px 30px;
  }
`;

export const Link = styled(RLink)`
  text-decoration: underline;
  font-weight: bold;
`;

export const GuaranteeImage = styled(MoneyBackGuarantee)`
  position: absolute;
  top: 0;
  right: 16px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    right: 30px;
  }
`;
