import React, { useEffect, useState } from 'react';
import image from 'src/astromix/assets/images/quiz/time.svg';
import Header from 'src/astromix/components/Header';
import ProgressBar from 'src/common/components/ProgressBar';
import { QuizButton } from 'src/astromix/components/Quiz/styled';
import TimeContainer from 'src/astromix/components/Quiz/Time';
import { useQuizStep } from 'src/common/contexts/QuizContext';
import { TimeStepData } from 'src/astromix/types';
import { setUserInformation } from 'src/astromix/store/userProfile';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';

export type TimePageProps = { id: string };

const Time = ({ id }: TimePageProps) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState({
    hours: '12',
    minutes: '00',
    part: 'AM',
  });

  const { back, next, stepData } = useQuizStep(id);

  const {
    content,
    stepOrder,
    totalSteps,
    dataKey,
    analytic,
  } = stepData as TimeStepData;

  const { analyticsService } = useAstromixServices();

  useEffect(() => {
    analyticsService.trackQuizEvent(analytic.baseEvent);
  }, [analyticsService, analytic]);

  const onContinue = () => {
    const timeOfBirth = moment(`${time.hours}:${time.minutes} ${time.part}`, 'LT').format('HH:mm:ss');
    dispatch(setUserInformation(dataKey, timeOfBirth));
    next();
  };

  return (
    <>
      <Header buttonBack onBack={back} />
      <ProgressBar step={stepOrder} totalSteps={totalSteps} />
      <TimeContainer
        image={image}
        title={content.title}
        text={content.text}
        label={content.label}
        value={time}
        onChange={(value) => setTime(value)}
      />
      {/* TODO add logic for storing time data to preferences */}
      <QuizButton onClick={onContinue}>Continue</QuizButton>
    </>
  );
};
export default Time;
