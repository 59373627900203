import styled, { keyframes } from 'styled-components';
import ImagedPageSection, { ImagedPageSectionHolder } from 'src/common/components/ImagedPageSection';
import PriceResolvedTimer, { PriceTimerTime } from 'src/astromix/components/PriceResolvedTimer';
import Button from 'src/astromix/components/Button';

const timerOffset = 145;
const timerOffsetTablet = 205;
const timerOffsetDesktop = 195;
const sectionHeightMobile = 488;
const sectionHeightTablet = 738;

const sectionWidth = 360;
const sectionHeight = 468;
const sectionTabletWidth = 768;
const sectionTabletHeight = 708;
const sectionDesktopWidth = 1440;
const sectionDesktopHeight = 708;

export const ImagedSection = styled(ImagedPageSection)`
  > ${ImagedPageSectionHolder} {
    padding-top: calc(${sectionHeight} / ${sectionWidth} * 100%);

    @media ${(props) => props.theme.breakpoints.tablet} {
      padding-top: calc(${sectionTabletHeight} / ${sectionTabletWidth} * 100%);
    }

    @media ${(props) => props.theme.breakpoints.desktop} {
      padding-top: calc(${sectionDesktopHeight} / ${sectionDesktopWidth} * 100%);
    }
  }
`;

export const PriceTimerWrapper = styled.div`
  background: linear-gradient(90.6deg, #C1F0FF 0.47%, #CCA1FF 137.94%);;
  width: calc(100% + 30px);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(${timerOffset} / ${sectionHeightMobile} * 100%);

  @media ${(props) => props.theme.breakpoints.tablet} {
    top: calc(${timerOffsetTablet} / ${sectionHeightTablet} * 100%);
    border-width: 3px;
    width: calc(100% + 68px);
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    top: calc(${timerOffsetDesktop} / ${sectionHeightTablet} * 100%);
    width: calc(100% + 100px);
  }
`;

export const PriceTimer = styled(PriceResolvedTimer)`
  height: 64px;
  color: ${(props) => props.theme.colors.secondary};
  justify-content: center;

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 98px;
  }

  ${PriceTimerTime} {
    color: #9974F6;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 1px;

    @media ${(props) => props.theme.breakpoints.tablet} {
      font-size: 36px;
      margin-bottom: 3px;
    }
  }
`;

export const PriceTimerTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  position: relative;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 20px;
    line-height: 140%;
  }
`;

export const PriceTimerText = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primary};

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 24px;
    line-height: 120%;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-right: 79px;
  }
`;

export const PriceTimeSecondaryText = styled.p``;

const impulseBtn = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(39, 174, 96,.3), 0 0 0 0 rgba(39, 174, 96,.2)
  } 18% {
    box-shadow: 0 0 0 0 rgba(39, 174, 96,.3), 0 0 0 0 rgba(39, 174, 96,.2)
  } 100% {
    box-shadow:0 0 0 16px rgba(39, 174, 96,0),0 0 0 27px rgba(39, 174, 96,0)
  }
`;

export const StyledAnimatedButton = styled(Button)`
  box-shadow: 0 12px 23px rgba(39, 174, 96, 0.31);
  border-radius: 26px;
  transition: .2s all ease;
  width: 240px;
  position: relative;
  display: none;
  left: 0;
  transform: translateX(0);

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
    margin-right: 0;
    margin-left: 25px;
  }

  :after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation-name: ${impulseBtn};
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    border-radius: 26px;
  }
`;
