import React from 'react';
import Title from 'src/astromix/components/Title';
import { Content } from './styled';

import { ContainerMain } from '../styled';
import AnswersHorizontalOrientation from './AnswersHorizontalOrientation';
import AnswersVerticalOrientation from './AnswersVerticalOrientation';

interface AnswersContainerProps {
  title: string;
  answers: Array<{ text: string; emoji: string }>;
  onAnswerClick: (choice: string) => void;
  hasVerticalOrientation?: boolean;
}

const AnswersContainer = ({
  answers,
  title,
  onAnswerClick,
  hasVerticalOrientation,
}: AnswersContainerProps) => (
  <ContainerMain>
    <Title>{title}</Title>
    <Content>
      {hasVerticalOrientation ? (
        <AnswersVerticalOrientation answers={answers} onAnswerClick={onAnswerClick} />
      ) : (
        <AnswersHorizontalOrientation answers={answers} onAnswerClick={onAnswerClick} />
      )}
    </Content>
  </ContainerMain>
);

export default AnswersContainer;
