import styled from 'styled-components';

export const PriceTimerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`;

export const PriceTimerTime = styled.div`
  font-size: 36px;
  line-height: 140%;
  padding: 3px 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;
