import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
`;

export const Item = styled.li`
  width: 99px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 160px;
  }
`;

export const EmojiBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.theme.colors.info};
  box-sizing: border-box;
  border-radius: 10px;
  height: 100px;
  margin-bottom: 15px;
  cursor: pointer;

  &:active {
    transition: all 100ms ease-in-out;
    transform: scale(1.1);
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    height: 160px;
    margin-bottom: 20px;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 20px;
    line-height: 28px;
  }
`;
