import urljoin from 'url-join';
import { config } from 'src/astromix/config';
import { stringifyQueryParams } from 'src/common/utils/query';

export enum BASE_ROUTES {
  ASTROMIX = '/',
  ASTROMIX_1WEEK_1MONTH = '/1week-vs-1month',
  ASTROMIX_1WEEK_1MONTH_QUIZ = '/1week-vs-1month/quiz1',
  ASTROMIX_QUIZ = '/quiz1',
  COOKIE_POLICY = '/cookie-policy',
  TERMS = '/terms',
  PRIVACY_POLICY = '/privacy-policy',
  FAQ = '/faq',
  CONTACT_US = '/contact-us',
  PAYMENT_TERMS = '/payment-terms',
}

export enum LANDINGS {
  EXTENDED_LANDING = '/lp/extended-landing',
  BILLING = '/lp/billing',
  ADDITIONAL_DISCOUNT = '/lp/additional-discount',
  EMAIL_MARKETING_1 = '/lp/email-marketing-1',
  EMAIL_MARKETING_2 = '/lp/email-marketing-2',
  EMAIL_MARKETING_3 = '/lp/email-marketing-3',
  NUMEROLOGY = '/lp/numerology',
  TAROT = '/lp/tarot',
}

export enum STEPS {
  GENDER = '/gender',
}

export const MAIN_APP_URL = config.mainAppUrl;

export function getMainAppThankYouPageUrl(authToken: string) {
  const queryParamsObj = {
    token: authToken,
    redirectTo: '/thank-you/report',
  };
  return urljoin(MAIN_APP_URL, '/external', `?${stringifyQueryParams(queryParamsObj)}`);
}
