import React from 'react';
import {
  City, Country, Item, Region,
} from './styled';

export interface PlaceListItemProps {
  country: string;
  region: string;
  city: string;
  coordinates: number[];
  onClick: (coordinates: string[], value: string) => void;
}

const PlaceListItem = ({
  country,
  region,
  city,
  onClick,
  coordinates,
}: PlaceListItemProps) => {
  const handleClick = () => {
    const cityName = city ? `${city}, ` : '';
    const regionName = region ? `${region}, ` : '';
    const countryName = country || '';
    const location = `${cityName}${regionName}${countryName}`;
    const stringCoordinates = coordinates.map((number) => number.toString());
    onClick(stringCoordinates, location);
  };

  return (
    <Item onClick={handleClick}>
      <City>{city}</City>
      <Region>
        {region && `${region}, `}
        <Country>{country}</Country>
      </Region>
    </Item>
  );
};

export default PlaceListItem;
