import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ColorVariants } from 'src/common/theme/types';

export const LinkStyled = styled(Link)<{ variant?: ColorVariants }>`
  color: ${(props) => (props.variant === 'primaryLink' && props.theme.colors.primaryLink)
          || props.theme.colors.secondary};
  line-height: 18px;
  font-size: 18px;
  font-weight: bold;
  z-index: 2;
  text-decoration: underline;
  position: relative;
  top: 4px;
  
  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 14px;
  }
`;

export const GuaranteeInscriptionStyled = styled.span<{ variant?: ColorVariants }>`
  color: ${(props) => (props.variant === 'primaryLink' && props.theme.colors.primaryLink)
    || (props.variant === 'secondary' && props.theme.colors.secondary)
    || props.theme.colors.primary};
  font-size: 12px;
  line-height: 140%;
  z-index: 2;
  
  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;
