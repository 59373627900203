import React, { FC } from 'react';
import { DPIScales } from 'src/common/components/ImagedPageSection';
import { PurchaseProductName } from 'src/common/types';
import { BASE_ROUTES } from 'src/astromix/constants';
import { ReactComponent as Guarantee } from 'src/astromix/assets/images/guarantee.svg';

import webpXl from 'src/astromix/assets/images/payment/payment-xl.webp';
import webpXl2x from 'src/astromix/assets/images/payment/payment-xl-2x.webp';
import pngXl from 'src/astromix/assets/images/payment/payment-xl.png';
import pngXl2x from 'src/astromix/assets/images/payment/payment-xl-2x.png';
import webpMd from 'src/astromix/assets/images/payment/payment-md.webp';
import webpMd2x from 'src/astromix/assets/images/payment/payment-md-2x.webp';
import pngMd from 'src/astromix/assets/images/payment/payment-md.png';
import pngMd2x from 'src/astromix/assets/images/payment/payment-md-2x.png';
import webpXs from 'src/astromix/assets/images/payment/payment-xs.webp';
import webpXs2x from 'src/astromix/assets/images/payment/payment-xs-2x.webp';
import pngXs from 'src/astromix/assets/images/payment/payment-xs.png';
import pngXs2x from 'src/astromix/assets/images/payment/payment-xs-2x.png';

import Bill from './Bill';
import AdvisoryPlans from './AdvisoryPlans';
import { PlansTypes } from './PlansTypes';
import {
  StyledButton,
  MobilePaymentWrapper,
  PaymentWrapper,
  Link,
  ReportDescription,
  ImagedSection,
  SectionInscription,
} from './styled';

const sectionData = {
  originalUrl: pngXl,
  sources: {
    desktop: [
      { url: webpXl },
      { url: webpXl2x, scale: DPIScales.TWICE },
      { url: pngXl },
      { url: pngXl2x, scale: DPIScales.TWICE },
    ],
    tablet: [
      { url: webpMd },
      { url: webpMd2x, scale: DPIScales.TWICE },
      { url: pngMd },
      { url: pngMd2x, scale: DPIScales.TWICE },
    ],
    mobile: [
      { url: webpXs },
      { url: webpXs2x, scale: DPIScales.TWICE },
      { url: pngXs },
      { url: pngXs2x, scale: DPIScales.TWICE },
    ],
  },
};

export type SectionPaymentProps = {
  onGetReport: () => void;
  price: number;
  isLoading: boolean;
  plan: PurchaseProductName;
  setPlan: (value: PurchaseProductName) => void;
};

const SectionPayment: FC<SectionPaymentProps> = ({
  onGetReport,
  price,
  isLoading,
  plan,
  setPlan,
}) => (
  <PaymentWrapper>
    <Bill price={price} />
    <MobilePaymentWrapper>
      <AdvisoryPlans value={plan} handleChange={setPlan} />
      <StyledButton onClick={onGetReport} loading={isLoading} variant="success">
        {isLoading ? 'Processing' : `Get full access for $${price}`}
      </StyledButton>
      <ReportDescription>
        By ordering this report, you also get free unlimited access to chat with an expert psychic
        at AskNebula. Unless cancelled, it will automatically convert to our subscription at the
        full price of
        {` ${PlansTypes[plan]} `}
        and will function according to our
        <Link to={BASE_ROUTES.TERMS}> Subscription terms</Link>
        .
      </ReportDescription>
      {/* TODO: add path */}
      <SectionInscription icon={<Guarantee />}>Guaranteed security payments</SectionInscription>
      <ImagedSection
        originalUrl={sectionData.originalUrl}
        sources={sectionData.sources}
      />
    </MobilePaymentWrapper>
  </PaymentWrapper>
);

export default SectionPayment;
