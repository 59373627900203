import styled from 'styled-components';
import { ColorVariants } from 'src/common/theme/types';

export const InputContainer = styled.input<{ error?: boolean; variant?: ColorVariants }>`
  width: 100%;
  background: transparent;
  color: ${(props) => (props.error && props.theme.colors.error)
          || (props.variant === 'secondary' && props.theme.colors.secondary)
          || props.theme.colors.primary};
  border: 1px solid ${(props) => (props.error && props.theme.colors.error)
          || (props.variant === 'secondary' && props.theme.colors.secondary)
          || props.theme.colors.primary};
  border-radius: 25px;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 24px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(props) => (props.variant === 'secondary' && props.theme.colors.secondary)
            || props.theme.colors.primary};
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
  }
`;

export const ErrorContainer = styled.div`
  position: absolute;
`;

export const ErrorText = styled.p`
  text-align: left;
  color: ${(props) => props.theme.colors.error};
  font-size: 12px;
  margin-top: 5px;
  margin-left: 15px;
`;
