import styled from 'styled-components';

export const ImagedPageSectionImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const ImagedPageSectionHolder = styled.div`
  display: block;
  width: 100%;
`;

export const ImagedPageSectionRoot = styled.div`
  position: relative;
  width: 100%;
`;
