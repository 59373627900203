import styled from 'styled-components';
import Button from 'src/angelology/components/Button';

export const ContainerMain = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  z-index: 1;
`;

export const TextMain = styled.p`
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.primary};
  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 18px;
  }
`;

export const Image = styled.img`
  margin-bottom: 20px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 300px;
    height: 300px;
`;

export const QuizButton = styled(Button)`
  margin: auto auto 30px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin: auto auto 50px;
  } 
`;

export const Background = styled.div`
  background: ${(props) => props.theme.gradients.primary};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
