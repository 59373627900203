import { SplitSystemExperiment } from './SplitSystemExperiment';

export interface Experiments {
  [key: string]: SplitSystemExperiment;
}

export class SplitSystem {
  private experiments: Experiments;

  constructor(experiments: Experiments = {}) {
    this.experiments = experiments;
  }

  addExperiment(experiment: SplitSystemExperiment): void {
    this.experiments[experiment.name] = experiment;
  }

  getExperiment(name: string) {
    return this.experiments[name];
  }
}
