import React from 'react';
import { ColorVariants } from 'src/common/theme/types';
import { TitleText } from './styled';

interface TitleProps {
  children: string;
  className?: string;
  variant?: ColorVariants;
}

const Title: React.FC<TitleProps> = ({ children, className, variant = 'primary' }) => (
  <TitleText className={className} variant={variant}>{children}</TitleText>
);

export default Title;
