import styled from 'styled-components';
import ButtonComponent from 'src/common/components/Button';

export const Button = styled(ButtonComponent)<{
  aspectRatio: number;
  tabletAspectRatio: number;
  desktopAspectRatio: number;
}>`
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  top: calc(${(props) => props.aspectRatio} * 100%);

  @media ${(props) => props.theme.breakpoints.tablet} {
    top: calc(${(props) => props.tabletAspectRatio} * 100%);
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    top: calc(${(props) => props.desktopAspectRatio} * 100%);
  }
`;
