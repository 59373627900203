import styled from 'styled-components';

export const Item = styled.div`
  text-align: left;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryLight};

  &:hover {
    // TODO added to colors theme #E2E4E7
    background: #E2E4E7
  }
`;

export const City = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  // TODO added to colors theme #2F2E37
  color: #2F2E37;
  line-height: 22px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 16px;
  }
`;

// TODO added to colors theme #828282
export const Region = styled.p`
  font-size: 14px;
  color: #828282;
`;

export const Country = styled.span``;
