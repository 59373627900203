import React from 'react';
import Price, { PriceTypes } from 'src/astromix/components/Price';
import {
  BillWrapper, BillPosition, Title, PriceWrapper,
} from './styled';

interface BillProps {
  price: number
}

const Bill = ({ price }: BillProps) => (
  <BillWrapper>
    <BillPosition>
      <Title>PDF COMPATIBILITY REPORT:</Title>
      <Price>$9.99</Price>
    </BillPosition>
    <BillPosition>
      <Title>7-DAY UNLIMITED CONSULTAION TRIAL: </Title>
      <PriceWrapper>
        <Price>0</Price>
        <Price type={PriceTypes.OLD}>$20</Price>
      </PriceWrapper>
    </BillPosition>
    <BillPosition>
      <Title>TOTAL TODAY:</Title>
      <Price type={PriceTypes.TOTAL}>{`$${price}`}</Price>
    </BillPosition>
  </BillWrapper>
);

export default Bill;
