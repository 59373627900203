import React from 'react';
import { Progress } from './styled';

interface ProgressBarProps {
  step?: number;
  totalSteps?: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ step = 1, totalSteps = 24 }) => {
  const progress = (step * 100) / totalSteps;

  return <Progress variant="determinate" value={progress} />;
};

export default ProgressBar;
