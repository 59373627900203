import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';

export const Progress = styled(LinearProgress)`
  & .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.theme.colors.info};
  }

  && {
    border-radius: 4px;
    height: 5px;
    margin-bottom: 20px;
    background-color: ${(props) => props.theme.colors.secondaryLight};
    @media ${(props) => props.theme.breakpoints.tablet} {
      height: 8px;
      margin-bottom: 50px;
    }
  }
`;
