import React, { FC } from 'react';
import { PriceTypes } from './PriceType';
import { Price as PriceComponent } from './styled';

export interface PriceProps {
  className?: string;
  type?: Partial<PriceTypes>;
}

const Price: FC<PriceProps> = ({
  type = PriceTypes.CURRENT,
  className,
  children,
}) => (
  <PriceComponent
    className={className}
    type={type}
  >
    {children}
  </PriceComponent>
);

export default Price;
