import React from 'react';
import Header from 'src/astromix/components/Header';
import Policy from 'src/astromix/components/Policy';
import GenderComponent from 'src/astromix/components/Quiz/Gender';
import ProgressBar from 'src/common/components/ProgressBar';
import { useQuizStep } from 'src/common/contexts/QuizContext';
import { GenderStepData } from 'src/astromix/types';
import useGender from './useGender';

// quizName: string;
// hasPolicy: boolean;
// step: number;
// content: {
//   title: string;
//   text: string;
//   subtitle?: string;
//   subtext?: string;
//   hasProgressBar?: string;
// };

export type GenderPageProps = {
  id: string;
};

const Gender = (props: GenderPageProps) => {
  // TODO add has previous steep boolean
  const { id } = props;
  const prevScreen = false;

  const { back, next, stepData } = useQuizStep(id);

  // // TODO add validation
  // if (!stepData) return null;
  // TODO add types
  const {
    content,
    hasPolicy,
    stepOrder,
    totalSteps,
    dataKey,
    analytic,
  } = stepData as GenderStepData;

  const { handleChoiceClick, onBack } = useGender({
    back,
    next,
    dataKey,
    analytic,
  });

  return (
    <>
      <Header buttonBack={!!prevScreen} onBack={onBack} />
      {prevScreen && <ProgressBar step={stepOrder} totalSteps={totalSteps} />}
      <GenderComponent
        title={content.title}
        text={content.text}
        subtitle={content.subtitle}
        subtext={content.subtext}
        handleChoiceClick={handleChoiceClick}
      />
      {hasPolicy && <Policy />}
    </>
  );
};

export default Gender;
