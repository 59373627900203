import styled from 'styled-components';

export const BillWrapper = styled.div`
  padding: 52px 24px 33px;
  max-width: 660px;
  margin: 0 auto;
`;

export const BillPosition = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 19px;
`;

export const Title = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.primary};
`;

export const PriceWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 60px;
  justify-content: space-between;
`;
