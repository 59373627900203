import styled from 'styled-components';

export const Container = styled.div`
  width: 102px;
  position: relative;
  z-index: 1;

  &:before {
    position: absolute;
    content: "";
    right: 18px;
    top: 16px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${(props) => props.theme.colors.primary};
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 140px
  }
}`;

export const SelectBlock = styled.select`
  appearance: none;
  width: 100%;
  background-color: transparent;
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.secondaryLight};
  border-radius: 20px;
  padding: 0 18px;
  transition: all 0.2s ease;
`;

export const Option = styled.option`
  background-color: ${(props) => props.theme.colors.secondary};
`;
