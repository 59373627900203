import { createAction } from '@reduxjs/toolkit';
import { ANALYTICS_NAME, QUIZ_NAMES } from 'src/common/types';
import { AppThunk } from 'src/astromix/store/types/AppThunk';
import { Experiments } from 'src/astromix/experiments';
import { getUserInformation } from './selectors';

export * from './selectors';

export const setUserInformation = createAction(
  'user/USER_INFORMATION',
  (name: string, value: string | number | string[] | number[]) => ({
    payload: {
      name,
      value,
    },
  }),
);

export const userSignedUp = createAction<{ email: string; id: string }>('user/USER_SIGNED_UP');

export const signedUpByToken = createAction<{ id: string }>('user/SIGNED_UP_BY_TOKEN');

export const doSignUpViaQuiz = ({
  email,
  quizAnaliticName,
  quizName,
}: {
  email: string;
  quizAnaliticName: ANALYTICS_NAME,
  quizName: QUIZ_NAMES;
}): AppThunk => async (dispatch, getState, context) => {
  const { services, splitSystem } = context;
  const { analyticsService, authService } = services;
  const userInformation = getUserInformation(getState());
  const attributionParameters = analyticsService.getAttributionParameters();

  const index = email.indexOf('@');
  const name = email.slice(0, index);
  const experiment = splitSystem.getExperiment(Experiments.NO_PLACE_TIME);

  let userData;

  if (quizAnaliticName === ANALYTICS_NAME.WEEK_MONTH && experiment.group === 'no_time_place') {
    userData = {
      email,
      name,
      gender: userInformation.gender,
      birth_day: userInformation.date,
      birth_time: '12:00:00',
      birth_place: 'Friedrichs Gymnasium Herford, North Rhine-Westphalia, Germany',
      latitude: '52.120618',
      longitude: '8.673399',
      extra_data: {
        ...userInformation,
        partnerPlace: ['106.978931', '47.970116'],
        partnerTime: '06:06:00',
        place: ['106.978931', '47.970116'],
        time: '12:00:00',
        location: 'Friedrichs Gymnasium Herford, North Rhine-Westphalia, Germany',
        partnerLocation: 'Friedrichs Gymnasium Herford, North Rhine-Westphalia, Germany',
      },
      acquisition_source: quizName,
      acquisition_data: {
        gender: userInformation.gender,
        partnerGender: userInformation.partnerGender,
        zodiacSign: userInformation.zodiac,
        partnerZodiacSign: userInformation.partnerZodiac,
      },
      ...attributionParameters,
    };
  } else {
    userData = {
      email,
      name,
      gender: userInformation.gender,
      birth_day: userInformation.date,
      birth_time: userInformation.time,
      birth_place: userInformation.location,
      longitude: userInformation.place[0],
      latitude: userInformation.place[1],
      extra_data: {
        ...userInformation,
      },
      acquisition_source: quizName,
      acquisition_data: {
        gender: userInformation.gender,
        partnerGender: userInformation.partnerGender,
        zodiacSign: userInformation.zodiac,
        partnerZodiacSign: userInformation.partnerZodiac,
      },
      ...attributionParameters,
    };
  }

  const authResult = await authService.signUpViaQuiz(userData);
  dispatch(userSignedUp({ email, id: authResult.user.id }));
};

export const doSignUpByToken = (token: string): AppThunk => async (dispatch, getState, context) => {
  const { services: { authService } } = context;
  try {
    // TODO fill user profile
    const result = await authService.signInByToken(token);
    dispatch(signedUpByToken({ id: result.user.id }));
  } catch (err) {
    // TODO added logger
    console.log(err);
  }
};
