import { useDispatch } from 'react-redux';
import { setUserInformation } from 'src/astromix/store/userProfile';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { useEffect } from 'react';
import { QUIZ_ANALYTIC_EVENTS } from 'src/astromix/services/analytics';
import { ANALYTICS_NAME } from 'src/common/types';

export default function useGender({
  next,
  back,
  dataKey,
  analytic,
}: {
  next: () => Promise<void>;
  back: () => Promise<void>;
  dataKey: string;
  analytic: {
    name?: ANALYTICS_NAME,
    baseEvent: QUIZ_ANALYTIC_EVENTS,
    baseEventProperties: Record<string, any>
  }
}) {
  const dispatch = useDispatch();

  const handleChoiceClick = (choice: string) => {
    dispatch(setUserInformation(dataKey, choice));
    next();
  };

  const { analyticsService } = useAstromixServices();

  useEffect(() => {
    analyticsService.trackQuizEvent(analytic.baseEvent, analytic.baseEventProperties);
  }, [analyticsService, analytic]);

  const onBack = () => back();

  return {
    handleChoiceClick,
    onBack,
  };
}
