import styled from 'styled-components';
import checkbox from 'src/astromix/assets/images/checkbox.svg';

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-size: 14px;

  &::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: transparent;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid ${(props) => props.theme.colors.primary};
    border-radius: 3px;
  }
`;

export const Input = styled.input`
  display: none;

  &:checked + ${Label}::before {
    background-color: ${(props) => props.theme.colors.primary};
    background-image: url(${checkbox});
    border-radius: 3px;
  }
`;
