import styled from 'styled-components';
import { ReactComponent as ButtonBack } from 'src/astromix/assets/images/back.svg';
import { ColorVariants } from 'src/common/theme/types';

export const Back = styled(ButtonBack)<{ variant?: ColorVariants }>`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 20px;
  color: ${(props) => (props.variant === 'secondary' && props.theme.colors.secondary) || props.theme.colors.primary};
`;
