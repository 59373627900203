import React from 'react';
import image from 'src/astromix/assets/images/quiz/place.svg';
import Header from 'src/astromix/components/Header';
import ProgressBar from 'src/common/components/ProgressBar';
import PlaceContainer from 'src/astromix/components/Quiz/Place';
import { useQuizStep } from 'src/common/contexts/QuizContext';
import { PlaceStepData } from 'src/astromix/types';
import usePlace from './usePlace';
import { Button } from './styled';

export type DatePageProps = { id: string };

const Place = ({ id }: DatePageProps) => {
  const { back, next, stepData } = useQuizStep(id);

  const {
    content,
    locationDataKey,
    dataKey,
    stepOrder,
    totalSteps,
    analytic,
  } = stepData as PlaceStepData;

  const {
    handleButtonClick,
    onBack,
    citiesList,
    location,
    handleInputChange,
    handleSelectedItem,
    place,
    handleInputClick,
    hasVisibleContent,
    handleCancelClick,
  } = usePlace({
    placeKey: dataKey, locationKey: locationDataKey, next, back, analytic,
  });

  return (
    <>
      <Header onBack={onBack} />
      <ProgressBar step={stepOrder} totalSteps={totalSteps} />
      <PlaceContainer
        image={image}
        title={content.title}
        text={content.text}
        citiesList={citiesList}
        handleInputChange={handleInputChange}
        onSelected={handleSelectedItem}
        location={location}
        handleInputClick={handleInputClick}
        hasVisibleContent={hasVisibleContent}
        handleCancelClick={handleCancelClick}
      />
      {hasVisibleContent
      && <Button onClick={handleButtonClick} disabled={!place.length}>Continue</Button>}
    </>
  );
};
export default Place;
