import React from 'react';
import HeaderMenu from 'src/astromix/components/Header/HeaderMenu';
import ButtonBack from 'src/astromix/components/ButtonBack';
import { ColorVariants } from 'src/common/theme/types';
import { Container, Logo } from './styled';

interface HeaderProps {
  onBack?: () => void;
  buttonBack?: boolean;
  variant?: ColorVariants;
  className?: string;
}

const Header = ({
  onBack, buttonBack = true, variant = 'primary', className = '',
}: HeaderProps) => (
  <Container className={className}>
    <Logo title="astromix" variant={variant} />
    {buttonBack && <ButtonBack onClick={onBack} variant={variant} />}
    <HeaderMenu variant={variant} />
  </Container>
);

export default Header;
