import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import { PaymentOrderDescription } from 'src/common/types';
import styles from './PaypalPayment.module.scss';

export type PaypalPaymentProps = {
  className?: string;
  url: string;
  onOrderDeclined: (declineData: unknown) => void;
  onOrderStartProcessing: () => void;
  onOrderApproved: (orderData: PaymentOrderDescription) => void;
}

const PaypalPayment = (props: PaypalPaymentProps) => {
  const {
    className,
    onOrderApproved,
    onOrderDeclined,
    onOrderStartProcessing,
    url,
  } = props;

  const buttonId = useRef('paypal-button');
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    script.async = true;
    script.setAttribute('data-btn-id', buttonId.current);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  useEffect(() => {
    const buttonContainer = buttonRef.current;

    if (!buttonContainer) return () => {};

    const eventListenersMap = {
      'order-started-processing': () => {
        onOrderStartProcessing();
      },
      'order-processed': (event: Event) => {
        const customEvent = event as CustomEvent<{ data: { order: PaymentOrderDescription } }>;
        onOrderApproved(customEvent.detail.data.order);
      },
      'button-error': (event: Event) => {
        const customEvent = event as CustomEvent<{ data: { order: PaymentOrderDescription } }>;
        onOrderDeclined(customEvent.detail.data);
      },
    };

    Object
      .entries(eventListenersMap)
      .forEach(([type, listener]) => buttonContainer.addEventListener(type, listener, false));

    return () => Object
      .entries(eventListenersMap)
      .forEach(([type, listener]) => buttonContainer.removeEventListener(type, listener, false));
  }, [
    url,
    onOrderStartProcessing,
    onOrderApproved,
    onOrderDeclined,
  ]);

  return (
    <div className={cn(styles.root, className)}>
      <div id={buttonId.current} ref={buttonRef} />
    </div>
  );
};

export default PaypalPayment;
