import styled from 'styled-components';
import { TextMain } from '../styled';

export const Text = styled(TextMain)`
  margin-bottom: 20px;
`;

export const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

export const SelectContainer = styled.div`
  display: flex;
  max-width: 450px;
  justify-content: space-between;
  margin: 0 auto 10px auto;
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-bottom: 20px;
  }
`;
