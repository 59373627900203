import styled from 'styled-components';
import { ReactComponent as Gift } from 'src/astromix/assets/images/gift.svg';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledGift = styled(Gift)`
  width: 151px;
  height: 151px;
  padding-bottom: 20px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: initial;
    height: initial;
    padding-top: 10px;
    padding-bottom: 0;
  }
`;
