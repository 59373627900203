import React, { useEffect } from 'react';
import Title from 'src/astromix/components/Title';
import Header from 'src/astromix/components/Header';
import { Background, QuizButton } from 'src/astromix/components/Quiz/styled';
import { useQuizStep } from 'src/common/contexts/QuizContext';
import { HowStepData } from 'src/astromix/types';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import {
  Circle, Container, Content, Item, List, Subtitle, Text,
} from './styled';

export type HowPageProps = { id: string };

const How = ({ id }: HowPageProps) => {
  const { back, stepData, next } = useQuizStep(id);
  const handleButtonClick = () => next();
  const onBack = () => back();

  const { content, analytic } = stepData as HowStepData;
  const { analyticsService } = useAstromixServices();

  useEffect(() => {
    analyticsService.trackQuizEvent(analytic.baseEvent);
  }, [analyticsService, analytic]);

  // TODO add validation
  if (!stepData) return null;

  return (
    <>
      <Header onBack={onBack} variant="secondary" />
      <Background />
      <Container>
        <Title variant="secondary">{content.title}</Title>
        <Subtitle>{content.subtitle}</Subtitle>
        <Content>
          <Text dangerouslySetInnerHTML={{ __html: content.text }} />
          {content.list.map((item) => (
            <List key={item}>
              <Circle />
              <Item>{item}</Item>
            </List>
          ))}
        </Content>
      </Container>
      <QuizButton onClick={handleButtonClick} variant="secondary">
        Continue
      </QuizButton>
    </>
  );
};

export default How;
