import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { QUIZ_ANALYTIC_EVENTS } from 'src/astromix/services/analytics';
import useAstromixServices from 'src/astromix/hooks/useAstromixServices';
import { useEffect } from 'react';

export default function useLoader(
  analytic: {
    baseEvent: QUIZ_ANALYTIC_EVENTS;
    baseEventProperties: Record<string, number>;
  },
) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { analyticsService } = useAstromixServices();

  useEffect(() => {
    analyticsService.trackQuizEvent(analytic.baseEvent, analytic.baseEventProperties);
  }, [analyticsService, analytic]);

  return {
    history,
    dispatch,
  };
}
