import HttpClient from 'src/common/api/HttpClient';
import moment from 'moment';
import { ZodiacTypes } from 'src/common/types';
import { BaseService } from './BaseService';

export class ZodiacService extends BaseService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  static getZodiacSignByDate(date: string) {
    const month = +moment(date).format('M');
    const day = +moment(date).format('DD');

    const zodiacSigns = [
      ZodiacTypes.CAPRICORN,
      ZodiacTypes.AQUARIUS,
      ZodiacTypes.PISCES,
      ZodiacTypes.ARIES,
      ZodiacTypes.TAURUS,
      ZodiacTypes.GEMINI,
      ZodiacTypes.CANCER,
      ZodiacTypes.LEO,
      ZodiacTypes.VIRGO,
      ZodiacTypes.LIBRA,
      ZodiacTypes.SCORPIO,
      ZodiacTypes.SAGITTARIUS,
    ];

    const zodiacBoundDates = [19, 18, 20, 19, 20, 20, 22, 22, 22, 22, 21, 21];

    const monthIndex = month - 1;
    const signMonthIndex = day <= zodiacBoundDates[monthIndex] ? monthIndex : (monthIndex + 1) % 12;
    return zodiacSigns[signMonthIndex];
  }
}
