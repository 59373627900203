import React from 'react';
import Header from 'src/astromix/components/Header';
import { BASE_ROUTES } from 'src/astromix/constants';
import { config } from 'src/astromix/config';
import {
  Container,
  Link,
  ListItem,
  MainTitle,
  NumberList,
  RouteLink,
  Text,
  UnorderedList,
} from '../PrivacyPolicy';
import { usePaymentTerms } from './usePaymentTerms';

const PaymentTerms = () => {
  usePaymentTerms();
  return (
    <>
      <Header buttonBack={false} />
      <Container>
        <MainTitle>PAYMENT TERMS</MainTitle>
        <NumberList>
          <ListItem>
            <b>Credits</b>
            <Text>
              To access certain features of the Website, you shall purchase credits (the “Credits”)
              to top-up your credit balance (the “Credit Balance”).
            </Text>
            <Text>
              The Credits will be deducted from your Credit balance when you access paid features of
              the Website on a per-minute basis. Certain number of Credits, as indicated on the
              payment screen during the purchase, will be deducted from your Credit balance for each
              minute at the beginning of such minute.
            </Text>
          </ListItem>
          <ListItem>
            <b>Special prices</b>
            <Text>
              We may offer certain number of free of discounted Credits. Unless you cancel before
              such Credits are used by you in full, you will be automatically charged for topping-up
              your Credit balance with Credits for the price and in the amount as indicated on the
              payment screen.
            </Text>
          </ListItem>
          <ListItem>
            <b>Automatic account topping-up</b>
            <Text>
              You may also use the EasyBuy feature to use the features of the website and your
              readings without interruption. This feature allows the topping-up of the Credit
              balance to automatically renew.
            </Text>
            <Text>
              You expressly authorize us to automatically top-up your Credit balance whenever it
              falls below minimum Credit balance as indicated on the payment screen during the
              purchase, until you cancel.
            </Text>
            <Text>
              You will be notified about the referred automatic top-up in advance and each time it
              occurs, via automatic notifications, You can deactivate this feature under your
              {' '}
              <Link href={config.settings}>account settings</Link>
              .
            </Text>
          </ListItem>
          <ListItem>
            <b>Payment method</b>
            <Text>
              To top-up your Credit balance we will charge the payment method you submitted at the
              time of purchase at confirmation of purchase. You authorize us to charge the
              applicable fees to the payment method that you submit.
            </Text>
          </ListItem>
          <ListItem>
            <b>Cancellation</b>
            <Text>
              If you cancel the automatic topping-up of Credit balance, it will be disabled,
              but you will still be able to use all your credits remaining on your Credits balance.
            </Text>
            <Text>
              You can cancel automatic Credit balance topping-up by turning it off in your account on the account
              {' '}
              <Link href={config.settings}>settings page</Link>
              .
            </Text>
          </ListItem>
          <ListItem>
            <b>Forfeiture of Credits</b>
            <Text>
              All Credits may be forfeited if your account is suspended or terminated for any reason, at our sole
              and absolute discretion without prior notice, including, but not limited to, for the following reasons:
            </Text>
            <UnorderedList>
              <ListItem>
                your account on the Website is inactive (i.e., not used or logged into) for one year;
              </ListItem>
              <ListItem>
                you fail to comply with any document published on the Website
                (Terms and Conditions of Use, Privacy Policy, Live Chat Rules);
              </ListItem>
              <ListItem>
                we suspect fraud or misuse by you of the Credits and the Service;
              </ListItem>
              <ListItem>
                we suspect any other unlawful activity associated with your account;
              </ListItem>
              <ListItem>
                we are acting to protect the Service, any of our users, experts, or our reputation.
              </ListItem>
            </UnorderedList>
            <Text>
              You will not receive money or other compensation for unused Credits
              when your account is closed whether such closure was voluntary or involuntary.
            </Text>
          </ListItem>
          <ListItem>
            <b>Changes</b>
            <Text>
              To the maximum extent permitted by applicable laws, we may change subscription fees at
              any time. We will give you reasonable notice of any such pricing changes by posting
              the new prices on the service and/or by sending you an email notification, or in other
              prominent way. If you do not wish to pay the new fees, you can cancel the applicable
              subscription prior to the change going into effect.
            </Text>
          </ListItem>
          <ListItem>
            <b>Refunds</b>
            <Text>
              Refund rules are described in our
              {' '}
              <RouteLink to={BASE_ROUTES.PAYMENT_TERMS}>Terms and Conditions of Use</RouteLink>
              .
            </Text>
          </ListItem>
          <ListItem>
            <b>Other terms</b>
            <Text>
              You agree that purchased Credits have no monetary value and do not constitute actual currency
              or property of any type. The Credits may never be sold, transferred, traded
              or exchanged through any legally acceptable payment method, goods or other items of monetary value
              from us or anyone else.
            </Text>
            <Text>
              You may not buy or sell any Credits or your account in exchange for legally acceptable money
              or otherwise exchange them for any other kind of value through any means other than that established by us
            </Text>
          </ListItem>
        </NumberList>
        <Text>
          If you have any questions in relation to these terms, please contact us at
          {' '}
          <Link href={`mailto: ${config.mail}`}>Support center</Link>
          .
        </Text>
        <Text>
          Please take a screenshot of this information for your reference.
        </Text>
        <Text>
          Last updated:
          {config.dateOfTerms}
        </Text>
      </Container>
    </>
  );
};

export default PaymentTerms;
