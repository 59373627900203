import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

export const Subtitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.primary};
  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 20px;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.primary};
  @media ${(props) => props.theme.breakpoints.tablet} {
    font-size: 18px;
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImgBlock = styled.div`
  position: relative;
  width: 153px;
  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 229px;
  }
`;

export const ImgBorder = styled.div`
  ${ImgBlock}:active & {
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: 3px;
    background: ${(props) => props.theme.gradients.primary};
    border-radius: 25px;
  }
`;

export const Img = styled.img`
  position: relative;
  border-radius: 22px;
  width: 100%;

  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }
  
  &:active {
    transform: scale(1.1);
    transition: all 100ms ease-in-out;
  }
`;

export const Content = styled.div`
  max-width: 466px;
  margin: 0 auto;
`;
